import React from "react";
import { SkeletonCircleComponent, SkeletonLinesComponent } from "@iventis/components";
import { styled } from "@iventis/styles";
import { ExportPlanNotificationContainer } from "./export-plan-notification";

export const UserNotificationSkeletonComponent = () => (
    <ExportPlanNotificationContainer inProgress={false} read>
        <div className="profile-image-container profile-image-container--skeleton">
            <SkeletonCircleComponent width="36px" />
        </div>
        <NotificationBodySkeleton>
            <div className="skeleton-section-top">
                <SkeletonLinesComponent height="10px" width="125px" />
                <SkeletonLinesComponent height="10px" width="50px" />
            </div>
            <div className="skeleton-section-bottom">
                <SkeletonLinesComponent height="10px" width="100%" />
                <SkeletonLinesComponent height="10px" width="50%" />
            </div>
        </NotificationBodySkeleton>
    </ExportPlanNotificationContainer>
);

const NotificationBodySkeleton = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    .skeleton-section-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
    .skeleton-section-bottom {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }
`;
