/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-constant-condition */
import { Model } from "@iventis/domain-model/model/model";
import { PagedResult } from "@iventis/domain-model/model/pagedResult";
import { OptionalExceptFor } from "@iventis/types/useful.types";
import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { CreateAssetRequest } from "@iventis/domain-model/model/createAssetRequest";
import qs from "qs";

/**
 * Makes multiple network requests as required to get all pages of a paginated response. Returns the inner type, not a paginated type.
 */
export const aggregatePagedResponse = async <InnerType, ResponseType = InnerType, ConfigType = any>(
    axiosInstance: AxiosInstance,
    url: string,
    config: AxiosRequestConfig<ConfigType>,
    aggregate: (responseCum: ResponseType, newInnerValue: InnerType) => Promise<ResponseType>,
    getter: (url: string, config: AxiosRequestConfig<ConfigType>) => Promise<AxiosResponse<PagedResult<InnerType>>> = axiosInstance.get
): Promise<ResponseType> => {
    let pageNumber = 1;

    let aggregatedValue: ResponseType;

    while (true) {
        const configWithPageNumber: typeof config = {
            ...config,
            params: {
                ...config.params,
                pageNumber,
            },
            paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "repeat" }),
        };

        const response = await getter(url, configWithPageNumber);

        aggregatedValue = await aggregate(aggregatedValue, response.data.results);
        pageNumber += 1;
        if (response.data.lastPage) {
            break;
        }
    }

    return aggregatedValue;
};

export const getApiKey = (axiosInstance: AxiosInstance, userId: string) => axiosInstance.post<string>(`users/${userId}/api-keys`).then((response) => response.data);

export const aggregatePagedResponseLists = async <InnerType>(func: (pageNumber: number) => Promise<PagedResult<InnerType[]>>): Promise<InnerType[]> => {
    let all: InnerType[] = [];
    let pageNumber = 1;
    let results: PagedResult<InnerType[]> = null;
    while (pageNumber === 1 || !results.lastPage) {
        results = await func(pageNumber);
        all = [...all, ...results.results];
        pageNumber += 1;
    }
    return all;
};

export type AssetsPostRequest = OptionalExceptFor<CreateAssetRequest, "name" | "dataUrl" | "type" | "tags">;

export type AssetsPatchRequest = OptionalExceptFor<CreateAssetRequest, "id">;

export type AssetsPutRequest = OptionalExceptFor<CreateAssetRequest, "id">;

export type ModelsPostRequest = OptionalExceptFor<Model, "name" | "lods">;
