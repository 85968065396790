import { DataField } from "@iventis/domain-model/model/dataField";
import { MapObjectRepeatedTimeRangeValue } from "@iventis/types";
import { AnySupportedGeometry } from "@iventis/map-types";
import { Feature } from "@turf/helpers";

/**
 * Gets all the repeated time ranges for a map object
 */
export function getAllMapObjectRepeatedTimeRangeValues(mapObject: Partial<Feature<AnySupportedGeometry, Record<string, unknown>>>, repeatedTimeRangesDataFields: DataField[]) {
    const dateFilterValues: MapObjectRepeatedTimeRangeValue[] = [];
    repeatedTimeRangesDataFields.forEach((df) => {
        const dateFilterValue = mapObject.properties[df.id] as MapObjectRepeatedTimeRangeValue[];
        if (dateFilterValue != null) {
            dateFilterValues.push(...dateFilterValue);
        }
    });
    return dateFilterValues;
}

/**
 * Checks if a map object is within a single repeated time range
 */
export function isMapObjectWithinSingleRepeatedTimeRange(repeatedTimeRangeValues: MapObjectRepeatedTimeRangeValue[], dayFilter: number, timeFilter: number) {
    return repeatedTimeRangeValues.some(
        ({ startDate, endDate, startTime, endTime }) => startDate <= dayFilter && endDate >= dayFilter && startTime <= timeFilter && endTime >= timeFilter
    );
}
