/* eslint-disable no-underscore-dangle */
import { createElement, FunctionComponent } from "react";
import { OptionsObject, useSnackbar, VariantType, WithSnackbarProps } from "notistack";
import { v4 as uuid } from "uuid";
import { getToastComponent } from "./toast.components";
import { Toast } from "./toast.types";

let toastRef: WithSnackbarProps;

export const ToastComponent: FunctionComponent = () => {
    // Set toastRef to this hook so it can be accessed outside of react components, for example an api interceptor
    toastRef = useSnackbar();
    return null;
};

export const toast = {
    success(toast: Toast, options: OptionsObject = {}) {
        this._create(toast, "success", options);
    },
    warning(toast: Toast, options: OptionsObject = {}) {
        this._create(toast, "warning", options);
    },
    info(toast: Toast, options: OptionsObject = {}) {
        this._create(toast, "info", options);
    },
    error(toast: Toast, options: OptionsObject = {}) {
        this._create({ ...toast, props: { ...toast.props, icon: toast.props.icon || "triangle-exclamation" } }, "error", options);
    },
    // Function should not be called directly
    _create(toast: Toast, variant: VariantType = "default", options: OptionsObject) {
        // If a key is not provided create a key
        const key = options.key || uuid();
        // Create a toast using the toast type passed into the function
        toastRef.enqueueSnackbar(createElement(getToastComponent(toast.type), toast.props), {
            variant,
            anchorOrigin: { vertical: "top", horizontal: "center" },
            // By default clicking the toast stops it
            onClick: () => this.remove(key),
            key,
            ...options,
        });
    },
    // Given a toast key you can close a toast
    remove(key: string) {
        toastRef.closeSnackbar(key);
    },
} as const;
