import { indexNext } from "./geojson-helpers";

/* eslint-disable */
export function unitVector(a: number[], b: number[]): [number, number] {
    const join = [b[0] - a[0], b[1] - a[1]];
    const magnitude = Math.sqrt(Math.pow(join[0], 2) + Math.pow(join[1], 2));
    const unit = [join[0] / magnitude, join[1] / magnitude] as [number, number];
    return unit;
}

export function midPoint(a: number[], b: number[]): number[] {
    return [a[0] + (b[0] - a[0]) / 2, a[1] + (b[1] - a[1]) / 2];
}

export function opposite(a: number[]): number[] {
    return [-a[0], -a[1]];
}

export function pointToAngle(point: number[]) {
    return Math.atan2(point[1], point[0]) * (180 / Math.PI);
}

export function distanceAngle(a: number, b: number) {
    const phi = Math.abs(b - a) % 360;
    return phi > 180 ? 360 - phi : phi;
}

/** Nearest element to point */
export function nearest<T>(elements: T[], accessor: (element: T) => number[], point: number[]): T {
    return elements.reduce(
        (previous: any, current: any) => {
            const el_point = accessor(current);
            const distance = Math.sqrt(Math.pow(point[1] - el_point[1], 2) + Math.pow(point[0] - el_point[0], 2));
            return distance < previous.dist ? { dist: distance, e: current } : previous;
        },
        { dist: Infinity, e: null }
    ).e;
}

/** Furthest element to point */
export function furthest<T>(elements: T[], accessor: (element: T) => number[], point: number[]): T {
    return elements.reduce(
        (previous: any, current: any) => {
            const el_point = accessor(current);
            const distance = Math.sqrt(Math.pow(point[1] - el_point[1], 2) + Math.pow(point[0] - el_point[0], 2));
            return distance > previous.dist ? { dist: distance, e: current } : previous;
        },
        { dist: 0, e: null }
    ).e;
}

export function length(a: [number, number], b: [number, number]) {
    return Math.sqrt(Math.pow(b[0] - a[0], 2) + Math.pow(b[1] - a[1], 2));
}

/**
 * Turns a vector into a -180 to +180 degree angle with the origin being the upward y-aaxis
 */
export function unitVectorToAngle(a: [number, number], b: [number, number]) {
    return pointToAngle(unitVector(a, b));
}

export function coordinatesEqual(a: [number, number], b: [number, number]) {
    return a[0] === b[0] && a[1] === b[1];
}

/**
 * Gets the unit vector of the normal to the shape at a given vertex.
 * The normal is a unit vector equidistant to the unit vectors of neighbouring nodes.
 * @param vertex The coordinate from which to calculate the normal
 * @param shape The shape which the vertex in question exists as a part of
 * @param inside Whether to return an unit vector pointing inside or outisde the shape
 * @param areCoordinatesEqual Used for finding the index of the provided vertex in the shape
 * @returns Neighbours, where the 0th neighbour is the next neighbour in the sequence, and the 1st is the previous neighbour in the sequence.
 */
export function getNormalAtVertex(
    vertex: [number, number],
    shape: [number, number][],
    inside: boolean,
    areCoordinatesEqual: (a: [number, number], b: [number, number]) => boolean = coordinatesEqual
): { normal: [number, number]; finalPoint: [number, number]; neighbours: { unitVector: [number, number]; coordinate: [number, number] }[] } {
    const indexSource = shape.findIndex((coord) => areCoordinatesEqual(coord, vertex));

    if (indexSource === -1) {
        throw new Error("Could not find the source coordinate in the provided shape geometry");
    }

    const nextCoord = shape[indexNext(indexSource, -1, shape.length)];
    const previousCoord = shape[indexNext(indexSource, 1, shape.length)];

    const previousCoordUnitVector = unitVector(vertex, previousCoord);
    const nextCoordUnitVector = unitVector(vertex, nextCoord);

    // Find the mid point between the unit vectors of the previous and last coordinates. Is not necessarily the same position as the one we are displaying the button for.
    const midPointNeighbourCoords = midPoint(previousCoordUnitVector, nextCoordUnitVector) as [number, number];

    const direction = inside ? 1 : -1;

    // Identify the outside of the shape so we can be consistent about where we place our button.
    const testPointMagnitude = 1.5;
    const testPoint = [vertex[0] + midPointNeighbourCoords[0] * (testPointMagnitude * direction), vertex[1] + midPointNeighbourCoords[1] * (testPointMagnitude * direction)];
    let finalPoint: [number, number];
    if (pointInPolygon(testPoint, shape)) {
        finalPoint = opposite(midPointNeighbourCoords) as [number, number];
    } else {
        finalPoint = midPointNeighbourCoords;
    }

    const directionedUnitVector = unitVector([0, 0], finalPoint);
    return {
        normal: directionedUnitVector as [number, number],
        finalPoint,
        neighbours: [
            { unitVector: previousCoordUnitVector as [number, number], coordinate: previousCoord },
            { unitVector: nextCoordUnitVector as [number, number], coordinate: nextCoord },
        ],
    };
}

export function pointAlongLine(a: [number, number], b: [number, number], distance: number) {
    const magnitude = length(a, b);
    const ratio = distance / magnitude;
    return [(1 - ratio) * a[0] + ratio * b[0], (1 - ratio) * a[1] + ratio * b[1]];
}

export function pointInPolygon(point, vs) {
    // https://gist.github.com/vlasky/d0d1d97af30af3191fc214beaf379acc

    var x = parseFloat(point[0]),
        y = parseFloat(point[1]);

    var wn = 0;

    for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
        var xi = parseFloat(vs[i][0]),
            yi = parseFloat(vs[i][1]);
        var xj = parseFloat(vs[j][0]),
            yj = parseFloat(vs[j][1]);

        if (yj <= y) {
            if (yi > y) {
                if (isLeft([xj, yj], [xi, yi], [x, y]) > 0) {
                    wn++;
                }
            }
        } else {
            if (yi <= y) {
                if (isLeft([xj, yj], [xi, yi], [x, y]) < 0) {
                    wn--;
                }
            }
        }
    }

    return wn != 0;
}

function isLeft(P0, P1, P2) {
    var res = (P1[0] - P0[0]) * (P2[1] - P0[1]) - (P2[0] - P0[0]) * (P1[1] - P0[1]);
    return res;
}

// calculate the coordinates the border needs to pass through in order to appear as though it is on the outside of the polygon
// works by shifting each of the polygon's edges out by half the width of the border, and then finding the intersections of the new lines
export function calculateBorderCoords(coords: { x: number; y: number }[], border_width: number) {
    const border_length = border_width / 2;

    const border_coords: { x: number; y: number }[] = [];
    const lines: { p1: number[]; p2: number[] }[] = [];

    // shift each line outwards by half the border width
    for (let i = 0; i < coords.length - 1; i++) {
        // difference between the start and end points of the line
        const y_diff = coords[i + 1].y - coords[i].y;
        const x_diff = coords[i + 1].x - coords[i].x;

        // calculate the angle perpendicular to the line, and the mid point of the line
        let perp_angle = Math.atan2(-x_diff, y_diff);
        const mid_point = [(coords[i].x + coords[i + 1].x) / 2, (coords[i].y + coords[i + 1].y) / 2];

        if (x_diff !== 0) {
            // We know the angle we need to shift but not which direction
            // for now ensure perp angle is pointing up
            perp_angle = perp_angle > 0 ? perp_angle : perp_angle + Math.PI;

            // if polygon is above this edge, then flip perp angle to point downwards
            if (isPolygonAbove(coords, mid_point, i)) {
                perp_angle -= Math.PI;
            }
            // special rare case when x_diff = 0
            // normal method does not work as testing whether polygon is above or below is impossible
            // so we test left / right instead
        } else {
            // We know the angle we need to shift but not which direction
            // for now ensure perp angle is pointing right
            perp_angle = 0;

            // if polygon is on the right of this edge, then flip perp angle to point left
            if (isPolygonAbove(coords, mid_point, i, "x")) {
                perp_angle = Math.PI;
            }
        }
        // calculate the new mid point by shifting the old one along in the right direction
        const point = [mid_point[0] + border_length * Math.cos(perp_angle), mid_point[1] + border_length * Math.sin(perp_angle)];

        // max_length is the max length either side of the mid point which any point can be
        // this stops border coords from getting too far away from the actual polygon coord
        const line_length = Math.sqrt(x_diff * x_diff + y_diff * y_diff);
        const max_length = (line_length * 1.2) / 2;

        // compute and add the line to the list, specified by its endpoints, which are each max_length away from the midpoint
        if (x_diff !== 0) {
            const grad = y_diff / x_diff;
            const x_offset = max_length / Math.sqrt(1 + grad * grad);
            const y_offset = grad * x_offset;
            lines.push({ p1: [point[0] + x_offset, point[1] + y_offset], p2: [point[0] - x_offset, point[1] - y_offset] });
        } else {
            lines.push({ p1: [point[0], point[1] + max_length], p2: [point[0], point[1] - max_length] });
        }
    }

    // adds the first line back onto the end for ease of computation
    lines.push(lines[0]);

    // finds the intersection point of each pair of adjacent lines
    // if getIntersectionPoint returns false, then lines were parallel, only happens if 3 points in same location, just use the original coord
    for (let i = 0; i < lines.length - 1; i++) {
        const intersection = getIntersectionPoint(lines[i], lines[i + 1], true);
        if (intersection) {
            border_coords.push(intersection);
        } else {
            border_coords.push(coords[i]);
        }
    }

    // adds the first point back onto the end as is standard
    border_coords.push(border_coords[0]);
    return border_coords;
}

/**
 * Finds the intersection point of two line segments.
 * If no point can be found within the segments then false is returned
 * Works using vector representation of lines e.g. r = a + ub, where a and b are vector constants and u is a scalar
 * Computes u for each line, and clamps this to the between 0 and 1 if needed
 * @param line1
 * @param line2
 * @param force_onto_line If true, and the lines are not parallel but do not intersect, then the closest possible point on the first line is returned
 */
function getIntersectionPoint(line1: { p1: number[]; p2: number[] }, line2: { p1: number[]; p2: number[] }, force_onto_line = false) {
    const x1 = line1.p1[0],
        x2 = line1.p2[0],
        x3 = line2.p1[0],
        x4 = line2.p2[0];
    const y1 = line1.p1[1],
        y2 = line1.p2[1],
        y3 = line2.p1[1],
        y4 = line2.p2[1];
    const denominator = (x1 - x2) * (y3 - y4) - (y1 - y2) * (x3 - x4);

    // if lines are parallel
    if (denominator === 0) {
        return false;
    }

    // calculates the u values for each line
    let ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denominator;
    const ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denominator;

    if (force_onto_line) {
        if (ua < 0) {
            ua = 0;
        }
        if (ua > 1) {
            ua = 1;
        }
    } else {
        // if the u values are outside this range, then the intersection is not within the segments
        if (ua < 0 || ua > 1 || ub < 0 || ub > 1) {
            return false;
        }
    }

    // calculates final coords using equation of first line
    const x = x1 + ua * (x2 - x1);
    const y = y1 + ua * (y2 - y1);

    return { x, y };
}

/**
 * Determines whether a polygon is above (true) or below (false) a specified point on the polygon's edge
 * Based on the even-odd point in polygon algorithm, shoots a ray downwards and counts intersection points with polygon
 * https://en.wikipedia.org/wiki/Even%E2%80%93odd_rule
 * @param polygon_coords The coords of the polygon to be tested. The first and last point should be the same
 * @param point The point to be tested
 * @param exclude The index of the line which the point is on. E.g. if the point is between coords 2 and 3, then exclude should be 2
 * @param test_direction The direction to shoot the ray (x or y), defaults to y. Useful if point being tested is on completely vertical line. If x specified, method actually tests whether polygon is on the right of the line or not
 * @returns A boolean specifying whether the polygon is above (true) or below (false) the point
 */
function isPolygonAbove(polygon_coords: { x: number; y: number }[], point, exclude = -1, test_direction: "x" | "y" = "y") {
    // find the maximum possible x/y value so we have an endpoint for the test line
    let max_coord = -Infinity;
    for (let i = 0; i < polygon_coords.length - 1; i++) {
        if (test_direction === "x") {
            max_coord = Math.max(polygon_coords[i].x, max_coord);
        } else {
            max_coord = Math.max(polygon_coords[i].y, max_coord);
        }
    }

    // shoot testing line downwards from point
    const testing_line = { p1: point, p2: test_direction === "x" ? [max_coord + 10, point[1]] : [point[0], max_coord + 10] };

    // count the number of intersections with testing line
    let intersection_count = 0;
    for (let i = 0; i < polygon_coords.length - 1; i++) {
        // skip the line which the point is on, as this can cause weird results
        if (i === exclude) {
            continue;
        }

        const line = { p1: [polygon_coords[i].x, polygon_coords[i].y], p2: [polygon_coords[i + 1].x, polygon_coords[i + 1].y] };
        if (getIntersectionPoint(line, testing_line)) {
            intersection_count++;
        }
    }

    return intersection_count % 2 !== 0;
}

// calculates the area of a rectangle
export function rectangleArea(coords: Rectangle) {
    return Math.abs((coords.right - coords.left) * (coords.top - coords.bottom));
}

// computes the area of the intersecting area between two rectangles
// https://math.stackexchange.com/questions/99565/simplest-way-to-calculate-the-intersect-area-of-two-rectangles
export function rectangleOverlapArea(rect1: Rectangle, rect2: Rectangle) {
    const x_overlap = Math.max(0, Math.min(rect1.right, rect2.right) - Math.max(rect1.left, rect2.left));
    const y_overlap = Math.max(0, Math.min(rect1.bottom, rect2.bottom) - Math.max(rect1.top, rect2.top));
    return x_overlap * y_overlap;
}

interface Rectangle {
    left: number;
    right: number;
    bottom: number;
    top: number;
}

export function rotateVector([cx, cy]: [number, number], [x, y]: [number, number], angle: number): [[number, number], [number, number]] {
    var radians = (Math.PI / 180) * angle,
        cos = Math.cos(radians),
        sin = Math.sin(radians),
        nx = cos * (x - cx) + sin * (y - cy) + cx,
        ny = cos * (y - cy) - sin * (x - cx) + cy;
    return [
        [cx, cy],
        [nx, ny],
    ];
}

/** Given a two point line and a distance, this returns a point in the direction of this line, that is measured from the base coordinate of the line
 *
 *         _       c <- this is the resulting point, x distance from the base coordinate
 *         /
 *        /      b
 *    x  /      /
 *      /      /   <- this is the line
 *     /      /
 *    /      /
 *  _/      a  <- this is the base coordinate of the line
 */
export const pointInDirectionOfLine = (a: [number, number], b: [number, number], distance: number): [number, number] => {
    // Account for line length of 0
    const multiplier = length(a, b) === 0 ? 0 : distance / length(a, b);
    const vx = b[0] - a[0];
    const vy = b[1] - a[1];
    const cx = a[0] + multiplier * vx;
    const cy = a[1] + multiplier * vy;
    return [cx, cy];
};

export const getMidpointPositionWithPadding = (
    vector: [[number, number], [number, number]],
    padding: number,
    geometryScreen: [number, number][],
    /** Should the padding (offset) apply to the inside or outside of the shape */
    placeInsideShape: boolean
): [number, number] => {
    const vectorMidpoint = midPoint(vector[0], vector[1]) as [number, number];

    let rotatedVector = rotateVector(vectorMidpoint, vector[0], 90);

    // If we have rotated into the shape, we must ensure that is what is intended
    if (
        (pointInPolygon(pointInDirectionOfLine(...rotatedVector, 0.000005), geometryScreen) && !placeInsideShape) ||
        (!pointInPolygon(pointInDirectionOfLine(...rotatedVector, 0.000005), geometryScreen) && placeInsideShape)
    ) {
        rotatedVector = rotateVector(vectorMidpoint, rotatedVector[1], 180);
    }
    return pointInDirectionOfLine(...rotatedVector, padding);
};
