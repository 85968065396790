import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { borderRadius, styled } from "@iventis/styles";
import React, { FunctionComponent, useState } from "react";

export const UserThumbnail = ({ imageUrl, className }: { imageUrl?: string; className?: string }) => {
    const [loading, setLoading] = useState(true);

    if (imageUrl == null) {
        return <UserPlaceholder className={className} />;
    }

    return (
        <>
            <StyledImage className={className} imageLoading={loading} alt="Person" src={imageUrl} onLoadStart={() => setLoading(true)} onLoad={() => setLoading(false)} />
            {loading && <UserPlaceholder className={className} />}
        </>
    );
};

const UserPlaceholder: FunctionComponent<{ className: string }> = ({ className }) => (
    <StyledUserPlaceholder className={className}>
        <FontAwesomeIcon icon={{ prefix: "fas", iconName: "user" }} />
    </StyledUserPlaceholder>
);

const StyledImage = styled.img<{ imageLoading: boolean }>`
    border-radius: ${borderRadius.circle};
    background-color: white;
    width: 100%;
    height: 100%;
    display: ${({ imageLoading }) => (imageLoading ? "none" : "block")};
`;

const StyledUserPlaceholder = styled.div`
    border-radius: 100%;
    background-color: #eeeeee;
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1/1;

    svg {
        color: #b7b7b7;
        margin: auto;
        width: 50%;
        height: 50%;
    }
`;
