import { css, Theme } from "@emotion/react";

export const skeletonShimmer = ({ sequence, backgroundColour }: { sequence?: number; backgroundColour?: string }) => ({ theme }: { theme: Theme }) => css`
    position: relative;
    overflow: hidden;
    background-color: ${backgroundColour || theme.shimmer.background};

    /* Hide any text inside; text can instead be used to give the element a reasonable width without explicitly setting one */
    color: rgba(0, 0, 0, 0);

    &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        background-image: linear-gradient(90deg, ${theme.shimmer.pulse});
        animation: shimmer 2s infinite;
        animation-delay: ${(sequence || 0) * 100}ms;
        content: "";
    }
`;
