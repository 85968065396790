import { convertLength } from "@turf/helpers";
import { UnitOfMeasurement } from "@iventis/domain-model/model/unitOfMeasurement";
import { metersInKilometer, feetInMile } from "./constants";

/**
 * Converts length value to Metric or imperial and then to an easily readable value. eg) "10,000 m" changes to "10 km"
 * @param length - Measurement value in meters
 * @param unitOfMeasurement - Metric or imperial system
 * @returns converted length with unit appended. eg) "10 m" or "7.2 mi"
 */
export function convertLengthMeasurement(length: number, unitOfMeasurement: UnitOfMeasurement) {
    switch (unitOfMeasurement) {
        case UnitOfMeasurement.Metric:
            return convertMetric(length);
        case UnitOfMeasurement.Imperial:
            return convertImperial(length);
        default:
            throw new Error(`Unit of measurement ${unitOfMeasurement} is not supported`);
    }
}

function convertMetric(length: number) {
    if (length > metersInKilometer) {
        return `${(length / metersInKilometer).toFixed(2)} km`;
    }
    return `${length.toFixed(2)} m`;
}

function convertImperial(length: number) {
    const lengthFeet = convertLength(length, "meters", "feet");
    if (lengthFeet > feetInMile) {
        return `${(lengthFeet / feetInMile).toFixed(2)} mi`;
    }
    return `${lengthFeet.toFixed(2)} ft`;
}

export function convertLengthMetersToFeet(metersValue: number): number {
    return convertLength(metersValue, "meters", "feet");
}

export function convertLengthFeetToMeters(feetValue: number): number {
    return convertLength(feetValue, "feet", "meters");
}
