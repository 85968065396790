import React, { FunctionComponent } from "react";
import { styled, media, iconButtonSize } from "@iventis/styles";
import { Theme } from "@emotion/react";

import { ApplicationName } from "@iventis/domain-model/model/applicationName";
import { IconName } from "@fortawesome/fontawesome-svg-core";

import AppButton from "./app-bar-button";

export interface AppDetails {
    appName: string;
    displayName?: string;
    icon: string;
    url: string;
}
interface AppBarProps {
    applications?: AppDetails[];
    selectedApp: string;
    handleApplicationSelected: (clickedAppId: string) => void;
    bottomAlignedAppNames?: ApplicationName[];
}

export const ApplicationNavigationComponent: FunctionComponent<AppBarProps> = ({ applications, selectedApp, handleApplicationSelected, bottomAlignedAppNames = [] }) => {
    const bottomAlignedApps = applications.filter((app) => bottomAlignedAppNames.includes(app.appName as ApplicationName));

    const createAppButtonsListItems = (apps: AppDetails[], checkIfMobile: boolean) => {
        const buttonsListItems = apps.map((app) => (
            <StyledListItem key={app.appName} className={checkIfMobile && bottomAlignedAppNames.includes(app.appName as ApplicationName) ? "on-mobile" : ""}>
                <AppButton
                    value={app.appName}
                    selected={app.appName.toLowerCase() === selectedApp.toLowerCase()}
                    iconName={app.icon as IconName}
                    text={app.displayName ?? app.appName}
                    onClick={(event) => {
                        handleApplicationSelected(event.currentTarget.value);
                    }}
                />
            </StyledListItem>
        ));
        return buttonsListItems;
    };
    return (
        <StyledAppBar className="app-bar">
            <StyledList>{createAppButtonsListItems(applications, true)}</StyledList>
            <StyledList className="non-mobile">{createAppButtonsListItems(bottomAlignedApps, false)}</StyledList>
        </StyledAppBar>
    );
};

const StyledAppBar = styled.div`
    height: 100%;
    width: 64px;
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }: { theme: Theme }) => theme.primaryColors.subdued50};
    z-index: 15;
    justify-content: space-between;

    ${media.medium} {
        .on-mobile {
            display: none;
        }
    }

    ${media.appAlongBase} {
        .non-mobile {
            display: none;
        }
        height: 49px;
        width: 100%;
        flex-direction: row;
        padding-top: 0;
        box-shadow: 3px 4px 4px -4px rgba(0, 0, 0, 0.2);
    }
`;
const StyledList = styled.ul`
    display: grid;
    grid-auto-rows: 54px;
    grid-template-columns: 100%;
    row-gap: 10px;
    ${media.medium} {
        margin-top: 10px;
        margin-bottom: 10px;
    }
    overflow-y: auto;
    ${media.appAlongBase} {
        display: flex;
        justify-content: space-around;
        width: 100%;
        height: 49px;
        justify-items: center;
        > * {
            min-width: ${iconButtonSize};
        }
    }
`;
const StyledListItem = styled.li`
    width: 64px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    cursor: pointer;
`;
