import styled from "../transient-styled";
import { main } from "../themes";

export const StyledVerticalRule = styled.hr<{ margin: number; ruleHeight: number }>`
    border: none;
    width: 1px;
    background-color: ${main.shades.three};
    margin: ${(props) => `0px ${props.margin}px`};
    height: ${({ ruleHeight }) => `${ruleHeight}px`};
    margin-top: auto;
    margin-bottom: auto;
`;
