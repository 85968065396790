import { setMonitoringTag } from "../tags/set-monitoring-tag";
import { ReplayTag } from "../tags/tag-types";

/** Adds the user device's height and width to the monitoring software */
export function setMonitoringUserScreenSize() {
    const clientHeight = document?.documentElement?.clientHeight;
    const clientWidth = document?.documentElement?.clientWidth;

    if (clientHeight != null && clientWidth != null) {
        // Set the screen height and width as tags
        setMonitoringTag(ReplayTag.SCREEN_HEIGHT, clientHeight);
        setMonitoringTag(ReplayTag.SCREEN_WIDTH, clientWidth);
    }
}
