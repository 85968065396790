import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StyledIconButton, styled } from "@iventis/styles";

interface RefreshButtonProps {
    onClick: () => void;
    disabled?: boolean;
    className?: string;
}

export const RefreshButtonComponent = ({ onClick, disabled, className }: RefreshButtonProps) => (
    <RefreshButton className={className} onClick={onClick} disabled={disabled} data-testid="refresh-button">
        <FontAwesomeIcon className="icon" icon={["far", "refresh"]} />
    </RefreshButton>
);

const RefreshButton = styled(StyledIconButton)`
    .icon {
        height: 16px;
        width: 16px;
    }
`;
