import { MapObjectRepeatedTimeRangeValue, RepeatedTimeRange } from "@iventis/types";
import { parseDayStringToEpochSeconds, parseEpochSecondsToDateOnlyString, parseTimeStringToNumber, parseTimeNumberToString } from "@iventis/utilities";

/**
 * Valid example:
 *
 * ```[{ id: "1", name: "time-1", startTime: "10:30:00", endTime: "12:00:00", startDate: "2024-06-20", endDate: "2024-06-21" }]```
 */
export const isDataFieldValueRepeatedTimeRange = (value: unknown): value is RepeatedTimeRange[] =>
    value != null && Array.isArray(value) && value[0] != null && typeof value[0] !== "string" && typeof value[0]?.startDate === "string";

/**
 * Valid example:
 *
 * ```[{ id: "1", name: "time-1", startTime: 1030, endTime: 1200, startDate: 1718869932, endDate: 1718956332 }]```
 */
export const isMapObjectRepeatedTimeRange = (value: unknown): value is MapObjectRepeatedTimeRangeValue[] =>
    value != null && Array.isArray(value) && value[0] != null && typeof value[0] !== "string" && typeof value[0]?.startDate === "number";

/** Checks if any of datafield values are repeated time range and converts them to a string format */
export const parseDataFieldValuesWithRepeatedTimeRange = (dataFieldValues: { [id: string]: unknown }, parseToType: "number" | "string" = "string"): { [id: string]: unknown } => {
    const parsedValues = {};
    Object.entries(dataFieldValues).forEach(([key, value]) => {
        if (parseToType === "string" && isMapObjectRepeatedTimeRange(value)) {
            parsedValues[key] = parseRepeatedTimeRangeDataFieldValueToString(value);
        } else if (parseToType === "number" && isDataFieldValueRepeatedTimeRange(value)) {
            parsedValues[key] = parseRepeatedTimeRangeDataFieldValueToNumber(value);
        } else {
            parsedValues[key] = value;
        }
    });
    return parsedValues;
};
/**
 * Parses repeated time range value to number format
 *
 * Example:
 *
 *  ```[{ id: "1", name: "time-1", startTime: "10:30:00", endTime: "12:00:00", startDate: "2024-06-20", endDate: "2024-06-21" }]``` -> ```[{ id: "1", name: "time-1", startTime: 1030, endTime: 1200, startDate: 1718869932, endDate: 1718956332 }]```
 */
export const parseRepeatedTimeRangeDataFieldValueToNumber = (values: RepeatedTimeRange[]): MapObjectRepeatedTimeRangeValue[] =>
    values.map((v) => ({
        ...v,
        startTime: parseTimeStringToNumber(v.startTime),
        endTime: parseTimeStringToNumber(v.endTime),
        startDate: parseDayStringToEpochSeconds(v.startDate),
        endDate: parseDayStringToEpochSeconds(v.endDate),
    }));

/**
 * Parses map repeated time range value to string format
 *
 * Example:
 *
 * ```[{ id: "1", name: "time-1", startTime: 1030, endTime: 1200, startDate: 1718869932, endDate: 1718956332 }]``` -> ```[{ id: "1", name: "time-1", startTime: "10:30:00", endTime: "12:00:00", startDate: "2024-06-20", endDate: "2024-06-21" }]```
 */
export const parseRepeatedTimeRangeDataFieldValueToString = (values: MapObjectRepeatedTimeRangeValue[]): RepeatedTimeRange[] =>
    values.map((v) => ({
        ...v,
        startDate: parseEpochSecondsToDateOnlyString(v.startDate),
        endDate: parseEpochSecondsToDateOnlyString(v.endDate),
        startTime: parseTimeNumberToString(v.startTime),
        endTime: parseTimeNumberToString(v.endTime),
    }));
