import React from "react";
import { ProjectSubscription } from "@iventis/domain-model/model/projectSubscription";
import { SubscriptionPlanPrice } from "@iventis/domain-model/model/subscriptionPlanPrice";
import { BillingUnit } from "@iventis/domain-model/model/billingUnit";
import { Content } from "@iventis/translations";
import { currencyCodeToSymbol, valueToCurrency } from "@iventis/utilities";
import { useIventisTranslate, UseIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { SubscriptionPlanPriceFrequency } from "@iventis/domain-model/model/subscriptionPlanPriceFrequency";
import { SubscriptionPlan } from "@iventis/domain-model/model/subscriptionPlan";
import { getDiscountsPerUser, getMaximumUsersAllowedForTier } from "./subscription-wizard-helpers";
import { SubscriptionPlanTier } from "./subscription-plan-details";

export const usePaymentDetails = (
    projectSubscription: ProjectSubscription,
    subscriptionPlan: SubscriptionPlan,
    subscriptionPlanPrice: SubscriptionPlanPrice = subscriptionPlan?.subscriptionPlanPrices.find((price) => price.id === projectSubscription.subscriptionPlanPriceId)
) => {
    const translate = useIventisTranslate();
    const currencySymbol = subscriptionPlanPrice?.currencyCode ? currencyCodeToSymbol(subscriptionPlanPrice?.currencyCode) : "";
    const subscriptionFee = subscriptionPlanPrice?.price * projectSubscription?.maximumUsers;
    const discount =
        subscriptionPlan && projectSubscription
            ? getDiscountsPerUser(
                  projectSubscription?.maximumUsers,
                  getMaximumUsersAllowedForTier(subscriptionPlan?.name),
                  subscriptionPlan.name.toLowerCase() as SubscriptionPlanTier
              )
            : 0;
    const discountTotal = subscriptionFee * (discount / 100);
    const total = discount > 0 ? subscriptionFee - discountTotal : subscriptionFee;
    const billiedAnnually = subscriptionPlanPrice?.frequency === SubscriptionPlanPriceFrequency.Annually;
    const nextPaymentString = getNextPaymentString(subscriptionPlanPrice, projectSubscription, translate, discountTotal);
    return { currencySymbol, subscriptionFee, discount, discountTotal, total, billiedAnnually, nextPaymentString } as const;
};

export const getNextPaymentString = (pricePlan: SubscriptionPlanPrice, subscription: ProjectSubscription, translate: UseIventisTranslate, discountTotal = 0) => {
    if (pricePlan != null && subscription != null) {
        switch (pricePlan.billingUnit) {
            case BillingUnit.Project: {
                const totalPrice = valueToCurrency(pricePlan.price, pricePlan?.currencyCode);
                return translate(Content.settings2.billing.payment_details_box.payment_breakdown_project, { amount: totalPrice });
            }
            case BillingUnit.User: {
                const totalPrice = valueToCurrency(pricePlan.price * subscription.maximumUsers - discountTotal, pricePlan?.currencyCode);
                return (
                    <>
                        {totalPrice} ({subscription.maximumUsers} {translate(Content.settings2.billing.payment_details_box[subscription.maximumUsers === 1 ? "user" : "users"])}
                        {"  "}
                        &times;
                        {"  "}
                        {valueToCurrency(pricePlan.price, pricePlan?.currencyCode)}
                        {discountTotal > 0 ? (
                            <span style={{ color: "#EB0C00" }}>
                                {"  "}
                                {`- ${valueToCurrency(discountTotal, pricePlan?.currencyCode, 2)}`} {translate(Content.settings2.billing.payment_details_box.discount)}
                            </span>
                        ) : (
                            ""
                        )}
                        )*
                    </>
                );
            }
            default:
                throw new Error(`Billing unit ${pricePlan.billingUnit} not handled`);
        }
    }
    return undefined;
};
