export const ZERO_TIME_SPAN = "00:00:00";

export const SVG_NAMESPACE = "http://www.w3.org/2000/svg";

export const EMPTY_GUID = "00000000-0000-0000-0000-000000000000";

export const SUPPORT_EMAIL = "support@iventis.com";

export const SUPPORT_URL = "https://support.iventis.com/kb-tickets/new";

export const PRICING_URL = "https://www.iventis.com/pricing/";

export const CONTACT_URL = "https://www.iventis.com/contact/";

export const BASE_64_REGEX = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;

export const IVENTIS_LOGO = "https://cdn.iventis.co.uk/assets/iventis_logo_new.png";

export const FALLBACK_PROJECT_IMAGE = "https://cdn.iventis.co.uk/assets/fallback-project-image.jpg";

export const IVENTIS_HELP_FORM = "https://support.iventis.com/kb-tickets/new";

export const IVENTIS_TERMS_URL = "https://www.iventis.com/wp-content/uploads/2023/09/Iventis-Standard-SaaS-Terms-Conditions.pdf";

export const IVENTIS_PRIVACY_POLICY_URL = "https://www.iventis.com/your-privacy/";

export const API_KEY_QUERY_PARAM_KEY = "api-key";

export const PROJECT_ID_QUERY_PARAM_KEY = "project-id";

// TODO: Should be replaced with finished article once uploaded
export const CUSTOM_IMAGE_MODEL_SUPPORT_URL = "https://support.iventis.com/model-custom-images";
export const CUSTOM_COLOUR_MODEL_SUPPORT_URL = "https://support.iventis.com/model-custom-colours";
