export enum NodeType {
    Folder = "Folder",
    Group = "Group",
    Map = "Map",
    Layer = "Layer",
    MapObject = "MapObject",
    MappingLibrary = "MappingLibrary",
    MapBackground = "MapBackground",
    SiteMap = "SiteMap",
    SiteMapLevel = "SiteMapLevel",
    Project = "Project",
    LinkedLayer = "LinkedLayer",
    DataFieldListItem = "DataFieldListItem",
    PersonalMappingLibrary = "PersonalMappingLibrary",
    Schedule = "Schedule",
    ScheduleVersion = "ScheduleVersion",
    EventBlock = "EventBlock",
    VenueLocation = "VenueLocation",
    Application = "Application"
}