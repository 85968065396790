import { removeIdAndQueryFromPathname } from "@iventis/utilities";
import { AwsRum as AwsRumWeb, AwsRumConfig } from "aws-rum-web";

function initialiseAwsRum() {
    try {
        if (window.location.hostname.toLowerCase().endsWith("app.iventis.com")) {
            // Config taken from code snippet inside AWS
            const config: AwsRumConfig = {
                sessionSampleRate: 1,
                guestRoleArn: "arn:aws:iam::553408121339:role/iventis-v2-demo-RUMMonitorcwrumunauthenticatedrole9-2adn29iXaUwQ",
                identityPoolId: "eu-west-2:a363d43a-65cb-40da-86bb-27f215166060",
                endpoint: "https://dataplane.rum.eu-west-2.amazonaws.com",
                telemetries: ["errors", "performance", "http"],
                allowCookies: true,
                enableXRay: true,
                sessionEventLimit: 0,
                disableAutoPageView: true,
            };

            const APPLICATION_ID = "0fdbf894-548d-414b-ac5b-47b68bc827aa";
            const APPLICATION_VERSION = "1.0.0";
            const APPLICATION_REGION = "eu-west-2";

            const awsRum = new AwsRumWeb(APPLICATION_ID, APPLICATION_VERSION, APPLICATION_REGION, config);

            // Record initial page view
            awsRum.recordPageView(removeIdAndQueryFromPathname(window.location.pathname));
            // Record page view on any route change, using this proxy is reliable way to do this
            window.history.pushState = new Proxy(window.history.pushState, {
                apply: (target, thisArg, argArray) => {
                    awsRum.recordPageView(removeIdAndQueryFromPathname(argArray?.[2]));
                    return target.apply(thisArg, argArray);
                },
            });

            return awsRum;
        }
    } catch (error) {
        // Ignore initialisation errors
    }
    return undefined;
}

/** Initialises AWS Rum and returns the Rum object. Must be called at the app level */
const AwsRum = initialiseAwsRum();

export default AwsRum;
