import { MapInterfaceStyle, SidebarContent, SidebarState } from "./sidebar.slice.types";

export const initialState: SidebarState = {
    mapInterfaceStyle: MapInterfaceStyle.SPACIOUS,
    popoverOpen: false,
    importLayer: false,
    parentFolderId: null,
    fullScreenSelector: null,
    popover: {
        component: null,
        nodeId: null,
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
        },
        transformOrigin: {
            vertical: "top",
            horizontal: "center",
        },
        translateY: "0px",
    },
    showImportLayer: false,
    content: SidebarContent.LAYERS,
    editingNodeName: false,
    modalLayer: null,
    attributeId: null,
    attributeLayerId: null,
    previousLibraryId: undefined,
    layersUndertakingPreviewSnapshots: [],
};
