import { ObjectUpdate } from "@iventis/map-engine/src/types/events-store";

export class UploadGeometryQueue {
    private objects: Map<string, ObjectUpdate> = new Map<string, ObjectUpdate>();

    /** Adds object geometry update to the queue */
    queue(update: ObjectUpdate) {
        this.objects.set(update.objectId, update);
    }

    /** Clears the given map objects from the queue and returns the latest updates */
    getObjectsFromQueueAndClear(objects: ObjectUpdate[]) {
        const objectsToUpdate: ObjectUpdate[] = [];
        objects.forEach((object) => {
            const queuedObject = this.objects.get(object.objectId);
            if (queuedObject != null) {
                objectsToUpdate.push(queuedObject);
                this.objects.delete(queuedObject.objectId);
            }
        });

        return objectsToUpdate;
    }
}
