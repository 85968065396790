import React, { FunctionComponent, useEffect, useState, useMemo, memo, PropsWithChildren } from "react";
import { styled, media } from "@iventis/styles";

import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import { ApplicationName } from "@iventis/domain-model/model/applicationName";
import { queryParams } from "@iventis/utilities";
import { ApplicationNavigationComponent } from "@iventis/components";
import { Content } from "@iventis/translations";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { AppSubBarMenuContext } from "@iventis/utilities/src/app-bar-submenu-context";
import { AuthState } from "@iventis/permissions/src/auth.slice";
import { IventisErrorBoundaryWithToast } from "@iventis/error-boundaries";
import { toast } from "@iventis/toasts";
import { useHistory } from "@iventis/subscriptions";
import { applicationDetails } from "./AppDetails";
import { handleAppClicked } from "./app-bar.helpers";

interface PageAppBarProps {
    libraryId?: string;
    children: React.ReactNode | React.ReactNodeArray;
}
const PageWithAppBarNoMemo: FunctionComponent<PageAppBarProps> = ({ children, libraryId }) => {
    const location = useLocation();
    const [selectedApp, setSelectedApp] = useState("");
    const { projectApplications, applicationsUpdating, projectAdmin } = useSelector(
        ({
            auth: {
                projectApplications,
                applicationsUpdating,
                user: { projectAdmin },
            },
        }: {
            auth: AuthState;
        }) => ({
            projectApplications,
            applicationsUpdating,
            projectAdmin,
        })
    );
    const history = useHistory();
    const translate = useIventisTranslate();
    const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
    // Using query params, decide if the app bar should be shown. Example use case: External share links.
    const showAppBar = !(queryParams["app-bar"] === "false");
    useEffect(() => {
        if (location.pathname === "/") setSelectedApp("Dashboard");
        if (location.pathname.includes("spatial-planner")) setSelectedApp(ApplicationName.Maps.toString());
        if (location.pathname.includes("schedule")) setSelectedApp(ApplicationName.Schedule.toString());
        if (location.pathname.includes("people")) setSelectedApp(ApplicationName.People.toString());
        if (location.pathname.includes("project-settings")) setSelectedApp(ApplicationName.Settings.toString());
        if (location.pathname.includes("dashboard")) setSelectedApp(ApplicationName.Dashboard.toString());
    }, [location.pathname]);

    const handleSelectedApplication = (applicationIdSelected: string) => {
        handleAppClicked(applicationIdSelected, selectedApp, setSelectedApp, history, mobileDrawerOpen, setMobileDrawerOpen, libraryId);
    };
    const getApplicationDisplayName = (appName: string) => {
        switch (appName) {
            case ApplicationName.Dashboard:
                return translate(Content.map5.dashboard);
            case ApplicationName.Maps:
                return translate(Content.map5.plan);
            case ApplicationName.People:
                return translate(Content.people.people);
            case ApplicationName.Schedule:
                return translate(Content.schedule.schedule);
            case ApplicationName.Settings:
                return translate(Content.settings2.settings);
            default:
                return appName;
        }
    };

    const applications = useMemo(
        () =>
            applicationDetails.reduce((cum, appDetails) => {
                const app = projectApplications.find((projectApp) => projectApp.name === appDetails.appName);
                let include = false;
                if (appDetails.appName === ApplicationName.Settings.toString()) {
                    include = app && projectAdmin;
                } else if (appDetails.appName === ApplicationName.Dashboard.toString()) {
                    include = projectApplications.find((projectApp) => projectApp.name === ApplicationName.Maps.toString()) != null;
                } else {
                    include = app !== undefined;
                }
                const displayName = getApplicationDisplayName(appDetails.appName);
                // eslint-disable-next-line no-param-reassign
                appDetails.displayName = displayName;
                return include
                    ? [
                          ...cum,
                          // If the app is updating, show loading spinner
                          applicationsUpdating.some((applicationUpdating) => applicationUpdating.toString() === appDetails.appName)
                              ? { ...appDetails, icon: "circle-notch" }
                              : appDetails,
                      ]
                    : cum;
            }, []),
        [projectApplications, applicationsUpdating]
    );

    return (
        <AppSubBarMenuContext.Provider value={{ mobileDrawerOpen, setMobileDrawerOpen }}>
            {showAppBar && (
                <StyledPageWithAppBar className="page-with-appbar">
                    <IventisErrorBoundaryWithToast size="overlay" toast={toast}>
                        <ApplicationNavigationComponent
                            handleApplicationSelected={handleSelectedApplication}
                            applications={applications}
                            selectedApp={selectedApp}
                            bottomAlignedAppNames={[ApplicationName.Settings]}
                        />
                    </IventisErrorBoundaryWithToast>

                    {children}
                </StyledPageWithAppBar>
            )}
            {!showAppBar && children}
        </AppSubBarMenuContext.Provider>
    );
};

const StyledPageWithAppBar = styled.div`
    display: grid;
    grid-template-columns: 64px auto;
    grid-template-rows: 100%;
    height: 100%;
    .app-bar {
        grid-column: 1;
    }
    ${media.appAlongBase} {
        grid-template-columns: 100%;
        grid-template-rows: calc(100% - 49px) 49px;
        .app-bar {
            grid-row: 2;
            grid-column: 1;
        }
    }
`;
const PageWithAppBar = memo(PageWithAppBarNoMemo);
export default PageWithAppBar;

// Skeleton variant of the page with app bar, wrap around other skeletons
export const PageWithAppBarSkeleton: React.FC<PropsWithChildren> = ({ children }) => (
    <StyledSkeletonPageWithAppBar>
        <Sidebar />
        {children}
    </StyledSkeletonPageWithAppBar>
);

const Sidebar = styled.div`
    background-color: #484848;
`;

const StyledSkeletonPageWithAppBar = styled.div`
    display: grid;
    grid-template-columns: 64px auto;
    grid-template-rows: 100%;
    height: 100%;
`;
