import { useMemo } from "react";
import { useSelector } from "react-redux";
import { AuthState } from "./auth.slice";

export const useProject = () => {
    const { user, projects } = useSelector((state: { auth: AuthState }) => state.auth);

    const projectId = user?.projectId;

    const project = useMemo(() => (projectId ? projects.find(({ id }) => id === projectId) : null), [projectId, projects]);

    return project;
};
