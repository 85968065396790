/* eslint-disable import/prefer-default-export */
import { hiddenTextCss } from "../atomic-rules";
import styled from "../transient-styled";

// Wrap around any interactive element that doesn't use a native interactive tag
// Inside this add a span tag describing the interaction.
export const InteractiveElement = styled.button`
    border: 0;
    padding: 0;
    background: none;
    :not([disabled]) {
        color: inherit;
    }
    cursor: pointer;
    ${hiddenTextCss}
`;

export const StyledInteractiveArea = styled(InteractiveElement)<{ zIndex?: number }>`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: ${(props) => props.zIndex || 0};
`;
