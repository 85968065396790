import { Subject } from "rxjs";
import { IventisWebsocketOptions } from "./iventis-websocket-options";

/**
 * A generic class to handle connecting, sending, recieving and reconnecting to a websocket server
 * */
export class IventisWebSockets<TEventType> {
    /** Subject of all messages recieved via this websocket connection */
    public messagesReceived: Subject<TEventType> = new Subject<TEventType>();

    /** The websocket object for this websocket connection */
    private socket: WebSocket | null = null;

    /** The interval to keep the websocket connection alive */
    private keepAliveInterval: NodeJS.Timeout | null = null;

    /** How many times can this websocket connection reconnect? < 0 means infinite reconnect attempts */
    private reconnectMaxAttempts: number;

    /** How many times have we tried to reconnect */
    private reconnectAttempts: number;

    /** What is the maximum time a reconnection should wait for? */
    private reconnectMaxInterval;

    /** The payload to send in the keep alive event */
    private keepAlivePayload: TEventType;

    /** How often should we send the keep alive event? */
    private keepAliveIntervalTime: number;

    /** If a message is sent without there being a connection, it is added to the queue */
    private messageQueue: TEventType[] = [];

    /** The last host name we connected to */
    private host: string;

    private attemptingToReconnect = false;

    private onOpenCallback: () => void;

    private onCloseCallback: () => void;

    private onErrorCallback: () => void;

    private onConnectCallback: () => void;

    private onMessageCallback: (message: TEventType) => void;

    constructor({ keepAlivePayload, reconnectMaxAttempts = 20, reconnectMaxInterval = 10000, keepAliveIntervalTime = 360000 }: IventisWebsocketOptions<TEventType>) {
        this.keepAlivePayload = keepAlivePayload;
        this.reconnectMaxAttempts = reconnectMaxAttempts;
        this.reconnectMaxInterval = reconnectMaxInterval;
        this.keepAliveIntervalTime = keepAliveIntervalTime;
    }

    public isConnected(): boolean {
        return this.socket?.readyState === WebSocket.OPEN;
    }

    public isConnecting(): boolean {
        return this.socket?.readyState === WebSocket.CONNECTING;
    }

    public isDisconnected(): boolean {
        return this.socket == null || this.socket.readyState === WebSocket.CLOSED;
    }

    public willBeDisconnected(): boolean {
        return this.isDisconnected() || this.socket?.readyState === WebSocket.CLOSING;
    }

    /**
     * Connects to the url host provided. Caches host for reconnection & sets up events
     * @param host the host to connect to
     * @returns
     */
    public connect(host: string) {
        if (this.isConnected() || this.isConnecting()) {
            return;
        }

        // Disconnect to clear any existing connections
        this.disconnect();

        this.onConnectCallback?.();

        // Connect to the remote host
        this.socket = new WebSocket(host);

        // Websocket handlers
        this.socket.onmessage = (evnt) => this.onMessageInternal(evnt);
        this.socket.onclose = () => this.onCloseInternal();
        this.socket.onopen = () => this.onOpenInternal();
        this.socket.onerror = () => this.onErrorInternal();

        this.host = host;
    }

    /**
     * Disconnects from the websocket server, clear message queue and stop keep alive interval
     */
    public disconnect() {
        if (!this.willBeDisconnected() && this.isConnected()) {
            this.socket?.close();
            this.socket = null;
        }
        if (this.keepAliveInterval != null) {
            clearInterval(this.keepAliveInterval);
            this.keepAliveInterval = null;
        }

        // Clear the message queue
        this.messageQueue = [];
    }

    /**
     * Attempts to reconnect to the websocket server. If we run out of connection retries, disconnect
     */
    public reconnect() {
        if (this.willBeDisconnected()) {
            if (this.reconnectMaxAttempts < 0 || this.reconnectAttempts < this.reconnectMaxAttempts) {
                // Reconnect attempts should be made quickly, and wait longer each time, up to a maximum of 10 seconds
                this.checkForReconnectInFuture();
                this.connect(this.host);
            } else {
                this.disconnect();
            }
        } else if (!this.isConnected()) {
            this.checkForReconnectInFuture();
        }
    }

    public sendMessage(message: TEventType) {
        if (this.isConnected()) {
            this.socket?.send(JSON.stringify(message));
        } else {
            this.messageQueue.push(message);
        }
    }

    /**
     * Sets the open callback. Open callback runs first in the onOpen event
     * @param callback the callback to run when the websocket is opened
     */
    public onOpen(callback: () => void) {
        this.onOpenCallback = callback;
    }

    /**
     * Sets the close callback. Close callback runs first in the onClose event
     * @param callback the callback to run when the websocket is closed
     */
    public onClose(callback: () => void) {
        this.onCloseCallback = callback;
    }

    /**
     * Sets the error callback.
     * @param callback the callback to run when the websocket errors
     */
    public onError(callback: () => void) {
        this.onErrorCallback = callback;
    }

    /**
     * Sets the message callback. Message callback runs first in the onMessage event with the parsed message
     * @param callback the callback to run when the websocket recieves a message
     */
    public onMessage(callback: (message: TEventType) => void) {
        this.onMessageCallback = callback;
    }

    /**
     * Sets the connect callback. Connect callback runs first when connect is called.
     * @param callback the callback to run when connect is called
     */
    public onConnect(callback: () => void) {
        this.onConnectCallback = callback;
    }

    private checkForReconnectInFuture() {
        this.reconnectAttempts += 1;
        let reconnectInterval = this.reconnectAttempts * 1000;
        if (reconnectInterval > this.reconnectMaxInterval) {
            reconnectInterval = this.reconnectMaxInterval;
        }
        setTimeout(() => this.reconnect(), reconnectInterval);
    }

    private processQueue() {
        while (this.messageQueue.length > 0) {
            const message = this.messageQueue.shift();
            this.socket?.send(JSON.stringify(message));
        }
    }

    private onMessageInternal(messageEvent: MessageEvent) {
        const message = JSON.parse(messageEvent.data);
        this.messagesReceived.next(message);
        this.onMessageCallback?.(message);
    }

    private onOpenInternal() {
        this.onOpenCallback?.();
        this.reconnectAttempts = 0;
        this.attemptingToReconnect = false;
        this.processQueue();
        this.keepAliveInterval = setInterval(() => {
            this.sendMessage(this.keepAlivePayload);
        }, this.keepAliveIntervalTime);
    }

    private onCloseInternal() {
        this.onCloseCallback?.();
        if (!this.attemptingToReconnect) {
            this.reconnect();
            this.attemptingToReconnect = true;
        }
    }

    private onErrorInternal() {
        this.onErrorCallback?.();
    }
}
