export const comments = {
    commentForm: {
        placeholder: "Add a comment",
    },
    commentList: {
        title: "All Comments",
    },
    viewAllComments: "View all comments",
    edited: "Edited",
    comment: "Comment",
    comments: "Comments",
    moveComments: "Move comments here",
    showComments: "Show comments",
    hideComments: "Hide comments",
    justNow: "Just now",
    deleteConfirmation: { title: "Delete comment", description: "Are you sure you want to delete this comment? This action cannot be reversed." },
    addComment: "Add comment",
    cancelComment: "Cancel adding comment",
} as const;
