/* eslint-disable camelcase */
export const settings = {
    theme: {
        dark: "Dark Theme",
        main: "Main Theme",
    },
    projectCustomisation: {
        projectDetails: "Project details",
        editDetails: "Edit details",
        projectName: "Project name",
        projectNameHelperText: "Max 24 characters",
        projectLogoTitle: "Project logo",
        projectLogoHint: "This image will be shown in the top navigation bar and on the project login page",
        projectLogoRemoveTitle: "Remove the logo image from this project",
        projectLogoRemoveText: "This will remove the logo image file and everyone with access to the project will no longer see it. Are you sure you wish to continue?",
        projectLogoRestrictionHint: "Max image size is 1920px (height) and 1080px (width)",
        projectImageTitle: "Project image",
        projectImageHint: "This image will be shown in the project card and dashboard",
        projectImageRemoveTitle: "Remove the image from this project",
        projectImageRemoveText: "This will remove the image file and everyone with access to the project will no longer see it. Are you sure you wish to continue?",
        projectImageRestrictionHint: "Max image size is 1920px (height) and 1080px (width)",
    },
    projectRolePermissions: {
        projectAdministratorsSubMenu: "Project Administrators",
        projectAdministratorsTitle: "Project administrators",
        projectAdminRoleDescription: "Here you can add or remove project administrators. These users have access to and can edit all project data, settings and permissions.",
        addAdmin: "Add user",
        addProjectAdmin: "Add project admin",
        removeAdmin: "Remove admin status",
        removeAdminConfirm: "This person will no longer be a project admin. Do you wish to continue?",
        removeYourselfAdminConfirm: "You will lose project admin access and wont be able to re-add it. Do you wish to continue?",
        projectAdminsSelectedConfirmButton: "Add selected",
        addAdminsToast: "Admins added successfully",
        removeAdminToast: "Admin removed successfully",
    },
    scheduleConfig: {
        title: "Schedule Configuration",
        description:
            "The schedule can be configured using the settings below. Only make changes here if you have a detailed understanding of how the schedule configuration works. If you are unsure, contact Iventis support:",
        save: "Save schedule changes",
        timesAndDates: "Times and Dates",
        venueTypeHierarchy: "Venue Type Hierarchy",
        scheduleVersionHierarchy: "Schedule Version Hierarchy",
        eventBlockHierarchy: "Event Block Hierarchy",
        venueRunSheets: "Venue Run Sheets (DCAS)",
        timezone: "Timezone",
        dayStartTime: "Start time for each day",
        dayEndTime: "End time for each day",
        projectStartDate: "Project start date",
        projectEndDate: "Project end date",
        projectZeroDate: "Project day 0",
        eventBlockTitleTop: "Top Level Calendar Event Blocks",
        eventBlockTitleSecond: "Second Level Calendar Event Blocks",
        eventBlockTitleThird: "Third Level Calendar Event Block",
        eventBlockTitleDefault: "Level Calendar Event Block",
        eventBlockTypeName: "Event Block Type Name",
        eventBlockApiSingle: "API Name Singular",
        eventBlockApiPlural: "API Name Plural",
        eventBlockAllowedVenueTypes: "Allowed Venue Types",
        eventBlockRelativeTypes: "Can Be Relative To Types",
        eventBlockSummaryText: "Attributes of Child Event Block to use in Summary",
        eventBlockDCAS: "Can be created inside the DCAS",
        eventBlockCustomerIdentifier: "Customer identifier",
        clusterVenueLocationTemplateTitle: "Cluster venue location template",
        mainVenueLocationTemplateTitle: "Main venue location template",
        subVenueLocationTemplateTitle: "Sub venue location template",
        enableClusters: "Enable clusters",
        editVenues: "Edit venues",
        venueLocations: "Venue Locations",
        venueLocationTypeName: "Venue Location Type Name",
        attributes: "Attributes",
        scheduleVersionTopLevelTitle: "Top Level Schedule Version",
        scheduleVersionSecondLevelTitle: "Second Level Schedule Version",
        scheduleVersionLevelTitle: "Level Schedule Version",
        scheduleVersionName: "Schedule Version Name",
        problems: "There are {{ problemCount }} problems with the submitted schedule configuration",
        dcasEventBlock: "Event Block for Run Sheet",
        dcasEventBlockQuestion: "Enable run sheet only event block",
        dcasEventBlockCalendar: "Event Blocks To Use",
        dcasEventBlockAttributes: "Attributes for Run Sheet",
        dcasEnableDone: 'Mark activities with "Done"',
    },
} as const;

export const settings2 = {
    projectAttributes: {
        title: "Attribute Settings",
        subtitle: "Here you can add and delete predefined attributes that your project users can view and use.",
        predefined_title: "Predefined attributes",
        predefined_text: "Customise your project's predefined attributes which can be added to plan layers.",
        no_attributes: "There are no project attributes, create one using the add attribute button above",
    },
    billing: {
        title: "Billing",
        page_title: "Billing and plans",
        page_content: "You can change and manage your subscription plan and edit your payment details.",
        in_arrears_error: {
            title: "Missing or invalid payment method",
            content: "Your account is currently suspended. Please update your payment details to activate your account",
        },
        cancelled_error_payed: {
            title: "Current subscription is cancelled",
            content_1: "Your account has been cancelled. Please",
            content_2: "contact us",
            content_3: "to reactivate your account",
        },
        cancelled_error_trial: {
            title: "Your trial period is over",
            content: "Your account has been cancelled. Please change your plan to a payed plan to continue using Iventis.",
        },
        subscription_box: {
            title: "Subscription",
            current_plan: "Current plan",
            next_payment: "Next payment",
            renews_on: "Renews on",
            paid: "Paid",
            days: "to go",
            get_in_touch: "Get in touch to discuss your plan",
            Trial: "Trial",
            Active: "Active",
            Cancelled: "Cancelled",
            InArrears: "In arrears",
        },
        change_plan: "Change plan",
        payment_details_box: {
            title: "Payment Details",
            payment_method: "Payment method",
            card_ending: "Card ending in",
            expires: "Expires",
            billing_address: "Billing address",
            no_payment_method: "No payment method",
            payment_breakdown_project: "{{ amount }} (1 project x {{ amount }})",
            payment_breakdown_users: "{{ totalAmount }} ({{ maxUsers }} users x {{ userAmount }})",
            payment_breakdown_user: "{{ totalAmount }} (1 user x {{ totalAmount }})",
            users: "users",
            user: "user",
            discount: "discount",
        },
        redirect_modal: {
            title: "Edit Payment Method",
            content: "You will be redirected to our billing provider to edit your payment details.",
        },
        excludes_taxes: "Excludes any applicable taxes",
    },
    upgrade_plan_warnings: {
        upgrade_plan: "Upgrade plan",
        contact_us: "Contact us",
        view_plans: "View plans",
    },
    view_plans: "View plans",
    subscription_details: {
        title: "Subscription details",
        selected_plan: "Selected plan",
        plan_type: "Plan type",
        currency: "Currency",
        billed: "Billed",
        payment_date: "Payment date",
        payment_date_desc: { Monthly: "{{ date }} of every month", Annually: "{{ date }}" },
        renewal_date: "Renewal date",
        add_users: "Add users",
        add_payment_method: "Add payment method",
        cannot_switch_from_annual: "Please contact us if you need to switch from annual to monthly billing",
        exceeded_max_users_message: "Please contact us to discuss your requirements if you need more than {{ numberOfUsers }} users",
        continue_to_payment: "Continue to payment",
        redirect_modal: {
            title: "Edit subscription",
            message: "By clicking continue, you will be redirected to our billing provider to make any required payments towards the updated subscription",
        },
        update_successful: "Subscription successfully updated",
    },
    subscription_price_breakdown: {
        title: "Price breakdown",
        subscription_fee: "Subscription fee",
        individual_price_breakdown: "Individual price breakdown",
        discount_applied: "Discount applied",
        discount_has_been_applied: "A {{ discount }} discount has been auto-applied to your account",
        total: "Total",
        frequency_proice_breakdown: "{{ frequency }} price breakdown",
        frequency: {
            Monthly: "Monthly",
            Annually: "Annually",
        },
        time_unit: {
            Monthly: "months",
            Annually: "year",
        },
        users: "users",
        excludes_taxes: "Excludes any applicable taxes which will be added on the payment page",
    },
    subscription_payment_breakdown: {
        title: "Payment breakdown",
        vat: "VAT ({{ percentage }} on {{ total }})",
        total_excluding_tax: "Total excluding tax",
        amount_due: "Amount due",
        description: "Description",
        total: "Total",
        unit: "Unit",
        pay: "Pay {{ amount }}",
    },
    order_summary: "Order summary",
    settings: "Settings",
} as const;

export const settings3 = {
    sitemaps: {
        title: "Sitemaps",
        page_content: "Sitemaps allow you to integrate detailed CAD drawings of venues/locations into the map. You can add and manage sitemaps and CAD drawings below.",
        no_sitemaps_title: "It's a bit empty here...",
        no_sitemaps_body_1: "There are currently no key locations or sitemaps! Click the ",
        no_sitemaps_body_2: " button to add as many locations as you want",
        add_sitemap: "Add Sitemap",
        create_sitemap_comming_soon_message: "Self upload of CAD drawings is coming soon. In the meantime, our support team can upload your CADs for you.",
        click_to_open_sitemap_options: "Click to open sitemap options",
        edit_sitemap: "Edit sitemap",
        delete_sitemap: "Delete sitemap",
    },
    user_settings: "User settings",
    help: "Help",
    help_menu: {
        title: "Help and resources",
        getting_started: "Getting started with Iventis",
        knowledge_base: "Iventis knowledge base",
        support: "Support",
        about: "About Iventis",
    },
    sso: {
        title: "Sso Configuration",
        page_content: "Here you can edit the SSO config for the project",
        response_signing_certificate: "Response signing certificate",
        meta_data_location: "Identity Provider Metadata URL",
        identity_provider: "Identity Provider Entity ID",
        saved: "Sso configuration saved successfully",
        setup_description: "When setting up SSO with your identity provider, you will need to select SAML and enter the following properties",
        entity_id: "Entity Id",
        rely_url: "Reply URL",
        signon_url: "SignOn URL",
    },
    projectCustomisation: {
        projectNameWarning: "Changing the project name will change the following:",
        projectNameWarningSsoKey: "Sso:",
        projectNameWarningSsoValue: "Configuration urls (if enabled)",
        projectNameWarningUrlKey: "Project Url:",
    },
} as const;
