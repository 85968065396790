import { DialogContentText } from "@mui/material";
import { Theme } from "@emotion/react";
import { inlineTextIconMargin, fontSizes } from "../atomic-rules";
import { InteractiveElement } from "./interactive-elements";
import { Text } from "../style.types";
import styled from "../transient-styled";

export const SectionalHeader = styled.p<Text>`
    text-transform: uppercase;
    font-weight: normal;
    font-size: 0.75rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 1.2px;
    color: #26273f;
`;

export const ProjectTitle = styled.h1<Text>`
    font-size: 2.375rem;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.95;
    min-height: 1em;
    letter-spacing: normal;
    color: #fff;
`;

export const ProjectSubtitle = styled.h1<Text>`
    font-size: 0.875rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    min-height: 1em;
    letter-spacing: normal;
    color: #fff;
`;

export const Header1 = styled.h1<Text>`
    color: ${({ color, theme }) => color && theme.typographyColors[color]};
    font-size: ${fontSizes.xxxLarge};
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.06;
    letter-spacing: normal;
`;

export const Header2 = styled.h2<Text>`
    color: ${({ color, theme }) => color && theme.typographyColors[color]};
    font-size: ${fontSizes.xxLarge};
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.011rem;
`;

export const Header3 = styled.h3<Text>`
    color: ${({ color, theme }) => color && theme.typographyColors[color]};
    font-size: ${fontSizes.xLarge};
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: 0.009rem;
`;

export const Header4 = styled.h4<Text>`
    color: ${({ color, theme }) => color && theme.typographyColors[color]};
    font-size: ${fontSizes.large};
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;

    svg {
        width: 14px;
        height: 14px;
        margin-right: ${inlineTextIconMargin};
    }
`;

export const Header5 = styled.h5<Text>`
    color: ${({ color, theme }) => color && theme.typographyColors[color]};
    font-size: ${fontSizes.medium};
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
`;

export const Header6 = styled.h6<Text>`
    color: ${({ color, theme }) => color && theme.typographyColors[color]};
    font-size: ${fontSizes.small};
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
`;

export const Header7 = styled.h6<Text>`
    color: ${({ color, theme }) => color && theme.typographyColors[color]};
    font-size: ${fontSizes.xSmall};
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
`;

export const Body1 = styled.p<Text>`
    color: ${({ color, theme }) => color && theme.typographyColors[color]};
    font-size: ${fontSizes.medium};
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    white-space: pre-line;
`;

export const Body2 = styled.p<Text>`
    color: ${({ color, theme }) => color && theme.typographyColors[color]};
    font-size: ${fontSizes.small};
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    white-space: pre-line;
`;

export const Body3 = styled.p<Text>`
    color: ${({ color, theme }) => color && theme.typographyColors[color]};
    font-size: ${fontSizes.xSmall};
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    white-space: pre-line;
`;

export const Label1 = styled.label<Text>`
    font-size: ${fontSizes.medium};
    color: ${(props) => props.color};
`;

export const Subtitle = styled.h6<Text>`
    color: ${({ color, theme }) => color && theme.typographyColors[color]};
    font-size: ${fontSizes.medium};
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
`;

export const DialogDescription = styled(DialogContentText)`
    color: ${({ theme }) => theme.typographyColors.lessSubdued};
`;

/**
 * @deprecated
 * Use styled button in styles folder instead
 */
export const ButtonText = styled.p<Text>`
    color: ${({ color, theme }) => color && theme.typographyColors[color]};
    font-size: ${fontSizes.small};
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: center;
`;

export const Caption = styled.p<Text>`
    color: ${({ color, theme }) => color && theme.typographyColors[color]};
    font-size: ${fontSizes.xSmall};
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
`;

export const Overline = styled.p<Text>`
    color: ${({ color, theme }) => color && theme.typographyColors[color]};
    font-size: ${fontSizes.xSmall};
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 1.8px;
    text-transform: uppercase;
`;

export const Attention = styled(Header3)`
    font-size: ${fontSizes.xxLarge};
    font-weight: lighter;
`;

export const FormLabel = styled.p<Text>`
    color: #1a1a1a;
    font-size: ${fontSizes.small};
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
`;

// Underlined primary text which can be used as a button
export const PrimaryTextLink = styled(InteractiveElement)`
    text-decoration: underline;
    font-size: inherit;
    :not([disabled]) {
        color: ${({ theme }: { theme: Theme }) => theme.typographyColors.primary};
    }
`;

export const EmphasisText = styled.p<{ size?: keyof typeof fontSizes }>`
    font-size: ${(props) => fontSizes[props.size ?? "xSmall"]};
    font-style: italic;
`;

export const nonBreakingSpace = `\u00A0`;
