/* eslint-disable react/jsx-props-no-spreading */
import { LoadingComponent } from "@iventis/components";
import { queryParams, API_KEY_QUERY_PARAM_KEY } from "@iventis/utilities";
import React from "react";
import { Route } from "react-router-dom";
import { useCheckUserLoggedIn } from "@iventis/permissions/src/use-check-user-logged-in";
import { useHistory } from "@iventis/subscriptions";
import { LinkExpiredOverlay } from "./link-expired-overlay";

// A route only accessible if the user is authenticated
const PrivateRoute: React.FC<{
    render: (props) => React.ReactElement;
    path: string;
    exact?: boolean;
}> = ({ ...rest }) => {
    const [isAuthenticated, loadingUser] = useCheckUserLoggedIn();

    const history = useHistory();

    if (loadingUser) {
        return <LoadingComponent />;
    }

    if (!isAuthenticated) {
        // If we fail to authenticate with an API key, push to observable
        if (queryParams[API_KEY_QUERY_PARAM_KEY] != null) {
            return <LinkExpiredOverlay />;
        }

        // Window.location is used over the UseLocation() hook because it the location hook did not have the most updated location.
        const windowLocation = window.location;
        const searchParams = new URLSearchParams(window.location.search);
        if (windowLocation.pathname + windowLocation.search !== `/` && !windowLocation.search.includes("redirectUrl") && !windowLocation.pathname.includes("login")) {
            searchParams.append("redirectUrl", windowLocation.pathname + windowLocation.search);
        }

        history.push({
            pathname: "/login",
            search: searchParams.toString(),
        }); // Refresh to the login page
        return <div />;
    }

    return <Route {...rest} />;
};

export default PrivateRoute;
