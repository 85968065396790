import { Project } from "@iventis/domain-model/model/project";
import React from "react";
import { styled, Body2, SolidButton, formPadding, inlineTextIconMargin, zIndexes, borderRadius, cardShadow } from "@iventis/styles";
import { Theme } from "@emotion/react";
import { Header3 } from "@iventis/styles/src/components/texts";
import { LoadingComponent, DisabledOverlay } from "@iventis/components";
import { StyledInteractiveArea } from "@iventis/styles/src/components";

import { CONTACT_URL } from "@iventis/utilities";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { Content } from "@iventis/translations";
import { useHistory } from "@iventis/subscriptions";

const ProjectCard: React.FC<{
    project: Project;
    imageUrl?: string;
    loadingProject: boolean;
    className?: string;
    disabled?: boolean;
    onSelected: (project: Project) => void;
    showBillingPopover: boolean;
    showServiceUnavailablePopover: boolean;
}> = ({ project, imageUrl, loadingProject, className, disabled, onSelected, showBillingPopover, showServiceUnavailablePopover }) => (
    <>
        <StyledProjectCard data-cy={`project-${project.id}`} className={className} $loading={loadingProject}>
            {disabled ? <DisabledOverlay /> : <StyledInteractiveArea zIndex={zIndexes.projectCard.interactiveOverlay} onClick={() => onSelected(project)} />}

            <Thumbnail>
                {imageUrl && <StyledProjectLogo imageUrl={imageUrl} />}
                {imageUrl === undefined && <LoadingComponent />}
                {loadingProject && <LoadingComponent />}
                {showBillingPopover && <BillingPopover projectId={project.id} />}
                {showServiceUnavailablePopover && <ServiceUnavailablePopover />}
            </Thumbnail>
            <Bottom>
                <Header3 className="project-name">{project.name}</Header3>
            </Bottom>
        </StyledProjectCard>
    </>
);

const BillingPopover: React.FC<{ projectId: string }> = ({ projectId }) => {
    const translate = useIventisTranslate();
    const history = useHistory();
    return (
        <BillingPopoverContainer>
            <BillingPopoverContent>
                <Body2>{translate(Content.projects.billing_popover.body)}</Body2>
                <SolidButton
                    onClick={(e) => {
                        e.stopPropagation();

                        history.push(`projects/${projectId}/billing`);
                    }}
                >
                    {translate(Content.projects.billing_popover.renew_button)}
                </SolidButton>
                <Body2 className="support-email">
                    <a target="_blank" rel="noopener noreferrer" href={CONTACT_URL}>
                        {translate(Content.login.footer.contact)}
                    </a>
                </Body2>
            </BillingPopoverContent>
        </BillingPopoverContainer>
    );
};

const ServiceUnavailablePopover: React.FC = () => {
    const translate = useIventisTranslate();
    return (
        <BillingPopoverContainer>
            <BillingPopoverContent>
                <Body2>{translate(Content.projects.service_unavailable.body)}</Body2>

                <Body2 className="support-email">
                    {translate(Content.projects.service_unavailable.support)} <StyledAnchor href={CONTACT_URL}>{translate(Content.login.footer.contact)}</StyledAnchor>
                </Body2>
            </BillingPopoverContent>
        </BillingPopoverContainer>
    );
};

const BillingPopoverContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: ${zIndexes.projectCard.billingPopover};
`;

const BillingPopoverContent = styled.div`
    background-color: ${({ theme }: { theme: Theme }) => theme.secondaryColors.light20};
    padding: ${formPadding};
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-items: center;
    text-align: center;

    .support-email {
        a {
            color: ${({ theme }: { theme: Theme }) => theme.typographyColors.primary};
            text-decoration: underline;
            font-weight: 500;
        }
    }
`;

const StyledProjectCard = styled.div<{ $loading: boolean }>`
    border-radius: ${borderRadius.double};
    overflow: hidden;
    box-shadow: ${cardShadow};
    background-color: ${({ theme }: { theme: Theme }) => theme.secondaryColors.blank};
    position: relative;
    :hover {
        outline: 2px solid ${({ theme, $loading }: { theme: Theme; $loading: boolean }) => ($loading ? theme.typographyColors.primary : theme.tertiaryColors.primary)};
    }

    ${({ theme, $loading }: { theme: Theme; $loading: boolean }) =>
        $loading ? `outline: 2px solid ${theme.typographyColors.primary}; background-color: ${theme.secondaryColors.selection};` : ""}

    display: flex;
    flex-direction: column;
`;

const Thumbnail = styled.div`
    width: 100%;
    height: 246px;
    position: relative;
`;

const Bottom = styled.div`
    margin: 17px;
    .project-name {
        color: ${({ theme }: { theme: Theme }) => theme.shades.one};
        width: 80%;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: ${inlineTextIconMargin};
    flex: 1 0;
`;

export const StyledProjectLogo = styled.div<{ imageUrl: string }>`
    width: 100%;
    height: 100%;
    background: ${({ imageUrl }) => `url(${imageUrl})`};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
`;

const StyledAnchor = styled.a`
    text-transform: lowercase;
`;

export default ProjectCard;
