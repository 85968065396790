import React, { ReactNode } from "react";
import { styled } from "@iventis/styles";
import { InteractiveElement } from "@iventis/styles/src/components";
import { Theme } from "@emotion/react";
import { CompanyThumbnail } from "./thumbnails/company-thumbnail";

interface NavigationBarProps {
    onLogoClicked: () => void;
    logoUrl: string;
    children?: ReactNode;
}

export const NavigationBarComponent = ({ onLogoClicked, logoUrl, children }: NavigationBarProps) => (
    <StyledNav role="navigation">
        <InteractiveElement onClick={() => onLogoClicked()}>
            <StyledCompanyLogo imageUrl={logoUrl} />
        </InteractiveElement>
        {children}
    </StyledNav>
);

const StyledNav = styled.nav`
    display: flex;
    position: relative;
    z-index: 5;
    background-color: ${({ theme }: { theme: Theme }) => theme.secondaryColors.blank};
    justify-content: space-between;
    height: 35px;
    flex-shrink: 0;
    box-shadow: 3px 4px 4px -4px rgba(0, 0, 0, 0.2);
    padding: 5px;
`;

const StyledCompanyLogo = styled(CompanyThumbnail)`
    max-width: 7.5em;
`;
