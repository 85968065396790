import { parseISO } from "date-fns";
import { BASE_64_REGEX } from "./constants";

/* Checks if string is valid UUID */
export const isValidUuid = (str) => {
    // Regular expression to check if string is a valid UUID
    const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

    return regexExp.test(str);
};

export const camelToSnakeCase = (str) => str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const thousandSeparator = (str: string | number) => String(str).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

export const queryParams = (new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop: string) => searchParams.get(prop),
}) as unknown) as { [key: string]: string };

export const emailValidationRegex = /^\S+@\S+\.\S+$/;

export const isUrlBase64 = (url: string) => BASE_64_REGEX.test(url.split("base64,")[1]);

/** Converts a boolean into string format (type-safe)
 * @example boolToString(true) = "true"
 */
export const boolToString = <TBool extends boolean>(bool: TBool) => String(bool) as TBool extends true ? "true" : TBool extends false ? "false" : "true" | "false";

/** Converts a string into a boolean
 * @example stringToBool("true") = true
 */
export const stringToBool = (bool: string) => (bool === "true" ? true : bool === "false" ? false : undefined);

/** Has an array of objects (with an id) changed? - order changes not included */
export const hasObjectArrayChanged = <T extends { id: string }>(
    oldArr: T[],
    newArr: T[],
    hasObjectChanged: (val1: T, val2: T, keysOfChangedValues?: (keyof T)[]) => boolean,
    keysOfChangedValues?: (keyof T)[]
): boolean => {
    let hasChanged = false;

    // Map each object to it's "previous" and "next" state
    const mapping: { [objectId: string]: { prev: T; next: T } } = {};
    oldArr?.forEach((item) => {
        mapping[item.id] = { prev: item, next: null };
    });
    newArr?.forEach((item) => {
        mapping[item.id] = { prev: mapping[item.id]?.prev, next: item };
    });

    // Loop through the mappings and check if the matching objects have changed
    Object.values(mapping).forEach(({ prev, next }) => {
        if (hasObjectChanged(prev, next, keysOfChangedValues)) {
            hasChanged = true;
        }
    });
    return hasChanged;
};

/** Are both values given null? */
export const bothNull = (val1: unknown, val2: unknown) => val1 == null && val2 == null;

/** Returns true if it's a valid date */
export const isValidDate = (date: Date): boolean => date instanceof Date && !!date.getTime();

/** Checks if the two values are both null or undefined, else check if the two values are the same */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isValueEqualWithNullOrUndefinedCheck = (valueOne: any, valueTwo: any): boolean => {
    if (valueOne == null && valueTwo == null) {
        return true;
    }
    return valueOne === valueTwo;
};

export const regexLowercase = /[.*[a-z].*/;
export const regexUppercase = /[.*[A-Z].*/;
export const regexDigit = /[.*\d].*/;

export const numberInputAcceptedSymbols = ["e", ".", "-", "+"];

/** Tuple of numbers as string of the given length */
export const blankCodeAsArray = (length: number) => Array(length).fill("");

/** Converts Pascal case string with no spaces into a sentence */
export const convertPascalToSentence = (text: string) => {
    const result = text.replace(/^[a-z0-9]|^([A-Z0-9]+)(?=[A-Z0-9]|$)|([A-Z0-9])+(?=[A-Z0-9]|$)|([A-Z0-9])(?=[a-z0-9]+)/g, (m) => ` ${m.toLowerCase()}`).trim();
    return result.charAt(0).toUpperCase() + result.slice(1);
};

/**  Returns a currency value formatted for the given currency code. */
// Replace is used as it can return odd unicode whitespace
export const valueToCurrency = (value: number, currencyCode: string, maximumFractionDigits?: number) =>
    Intl.NumberFormat("en-US", { style: "currency", currency: currencyCode, maximumFractionDigits }).format(value).replace(/\s/g, " ");

/**  Returns a currency symbol for the given currency code. Returns an empty string if no symbol found */
export const currencyCodeToSymbol = (currencyCode: string) => {
    const symbol = Intl.NumberFormat("en-US", { style: "currency", currency: currencyCode })
        .formatToParts(1)
        .find((x) => x.type === "currency").value;
    return symbol.length > 1 ? "" : symbol;
};

export const canParseInt = (stringToParse: string) => !Number.isNaN(parseInt(stringToParse, 10));
export const canParseFloat = (stringToParse: string) => !Number.isNaN(parseFloat(stringToParse));

/** Converts all  string dates that are meant to be Date's into Date's. Typically happens when we use data from the server */
export const convertStringDatesToDates = <T, K extends (keyof T)[]>(entities: T[], ...dateKeys: K) => {
    dateKeys.forEach((key) => {
        entities.forEach((entity) => {
            if (entity[key]) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore Spent a while trying to extract the date keys from the generic T object but I'm not sure it's possible without typescript strict mode, so ts-ignore here instead
                // eslint-disable-next-line no-param-reassign
                entity[key] = parseISO(entity[key].toString());
            }
        });
    });
};

export const readFileAsJson = async (file: File) => {
    if (!file.name.endsWith("json")) {
        throw new Error("File extension must be '.json'");
    }
    try {
        const json = await new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.onload = (event) => {
                if (typeof event.target.result === "string") {
                    resolve(JSON.parse(event.target.result));
                } else {
                    reject();
                }
            };
            fileReader.onerror = (error) => reject(error);
            fileReader.readAsText(file);
        });
        return json;
    } catch (e) {
        throw new Error(e);
    }
};

/** Converts a coordinate [lng, lat] into a string format "lng,lat" */
export const coordinatesToString = ([x, y]: number[]) =>
    typeof x !== "number" || typeof y !== "number" ? "N/A" : `${Number.isInteger(x) ? x : x.toFixed(6)}, ${Number.isInteger(y) ? y : y.toFixed(6)}`;

/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * Capitalises the first character of a string
 *
 */
export const titleFormat = (str: string) => {
    if (str == null) {
        return null;
    }
    const split = str.split("");
    split[0] = split[0].toLocaleUpperCase();
    return split.join("");
};

/**
 * Loops through an array of entities and either replaces a given item or pushes it depending on whether or not it is found
 */
export const replaceOrConcatEntity = (array: { id: string }[], item: { id: string }) => {
    const newArray = JSON.parse(JSON.stringify(array));
    const index = array.findIndex(({ id }) => id === item.id);
    if (index !== -1) {
        newArray[index] = item;
    } else {
        newArray.push(item);
    }
    return newArray;
};

/**
 * Takes a string and puts a space between every capital letter for example -> HelloWorldMyNameIsJosh = Hello World My Name Is Josh
 */
export const spaceBetweenCapitalLetters = (value: string) =>
    value
        .match(/([A-Z]?[^A-Z]*)/g)
        .slice(0, -1)
        .join(" ");

/** Has the array changed? Pass a comparator function which allows you to sort the arrays first so we can better check - order changes not included */
export const hasSortableArrayChanged = <T>(oldArr: T[], newArr: T[], comparator: (val1: T, val2: T) => number): boolean => {
    if (oldArr?.length !== newArr?.length) {
        return true;
    }
    let hasChanged = false;
    const oldDates = oldArr?.sort(comparator);
    const newDates = newArr?.sort(comparator);
    oldDates?.forEach((val, index) => {
        if (comparator(val, newDates?.[index]) !== 0) {
            hasChanged = true;
        }
    });
    return hasChanged;
};

/**  Take a pathname with an id or query at the end e.g. "/spatial-planner/e3925874-8ac2-4a2f-b5fb-c65aafade265?abc=1" and remove the id so it becomes "/spatial-planner" */
export const removeIdAndQueryFromPathname = (pathname: string) => {
    let path = pathname;

    // Remove the query if one exists
    if (path.includes("?")) {
        // Split by query (?) and remove
        const [newPathname] = pathname.split("?");
        path = newPathname;
    }

    // Remove the last part if it contains an ID
    const pathParts = path.split("/");
    const lastPart = pathParts[pathParts.length - 1];
    // If the last part contains an ID or a query parameter, remove it
    if (isValidUuid(lastPart)) {
        pathParts.pop();
    }
    return pathParts.join("/");
};
