import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StyledIconButton, styled } from "@iventis/styles";

import { closeButton } from "@iventis/styles/src/atomic-rules";

interface CloseIconProps {
    onMouseDown: () => void;
    className?: string;
    dataTestId?: string;
}

/** An icon button which will display in top right of it's container */
export const CloseButton = ({ onMouseDown, className, dataTestId }: CloseIconProps) => (
    <StyledCloseButton className={className} onMouseDown={onMouseDown} data-testid={dataTestId}>
        <FontAwesomeIcon className="icon" icon={["far", "xmark"]} />
    </StyledCloseButton>
);

const StyledCloseButton = styled(StyledIconButton)`
    position: absolute;
    top: 0px;
    right: 0px;
    .icon {
        height: ${closeButton.height};
        width: ${closeButton.width};
    }
`;
