/**
 * Converts a date stamp to a relative time string for example "3 days ago"
 * @param input the object of which we are checking
 * @returns a string representing the relative time from the input date
 *
 * @example
 * timeAgo("Wed Mar 06 2024 15:41:38 GMT+0000 (Greenwich Mean Time)") => 15 minutes ago/ 3 days ago/ 2 weeks ago etc
 */

export function timeAgo(input: Date | string | number, noDifferenceText = "", ensureTimeIsInPast = false) {
    const date = input instanceof Date ? input : new Date(input);
    const formatter = new Intl.RelativeTimeFormat("en");
    const ranges = [
        ["years", 3600 * 24 * 365],
        ["months", 3600 * 24 * 30],
        ["weeks", 3600 * 24 * 7],
        ["days", 3600 * 24],
        ["hours", 3600],
        ["minutes", 60],
        ["seconds", 1],
    ] as const;
    const secondsElapsed = (date.getTime() - Date.now()) / 1000;

    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    const [rangeType, rangeVal] = ranges.find(([_, val]) => val < Math.abs(secondsElapsed)) || [];

    if (rangeType && rangeVal) {
        const delta = secondsElapsed / rangeVal;

        // If ensureTimeIsInPast is enabled then it ensures that the time is not in the future
        if (ensureTimeIsInPast && delta > 0) {
            return noDifferenceText;
        }

        return formatter.format(Math.round(delta), rangeType);
    }
    // if no range is found return no date
    return noDifferenceText;
}
