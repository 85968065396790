/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { UserNotification } from "@iventis/domain-model/model/userNotification";
import { NotificationResourceData } from "@iventis/domain-model/model/notificationResourceData";
import { createContext, useContext } from "react";

export interface INotificationContext {
    createNotification: (notification: UserNotification<NotificationResourceData>) => void;
    invalidateNotifications: () => void;
}

export const NotificationContext = createContext<INotificationContext>({
    createNotification: (notification: UserNotification<NotificationResourceData>) => {},
    invalidateNotifications: () => {},
});

export const useNotificationContext = () => useContext(NotificationContext);
