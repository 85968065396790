/** text-align: center; */
export const textCenter = "text-center";

/** display: flex; flex-direction: column */
export const flexColumn = "flex-column";

/** margin-left: 11px */
export const inlineTextIcon = "inline-text-icon";

/** truncate text - reallyLongLabel becomes reallyLongLab... */
export const truncateText = "truncate-text";
