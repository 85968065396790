import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Body1, Header4, borderRadius, formGap, narrowPadding, styled } from "@iventis/styles";
import { Theme } from "@emotion/react";
import React, { FunctionComponent } from "react";

import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { Content } from "@iventis/translations";
import { CONTACT_URL } from "@iventis/utilities";
import { Link } from "@mui/material";

export const UserLimitReachedMessage: FunctionComponent = () => {
    const translate = useIventisTranslate();

    return (
        <ErrorBox>
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <FontAwesomeIcon icon={["fas", "exclamation-circle"]} />
                <Header4>{translate(Content.subscriptionplan3.limits.users.limitReached.title)}</Header4>
            </div>
            <Body1>
                {translate(Content.subscriptionplan3.limits.users.limitReached.content)} <SupportLink />
            </Body1>
        </ErrorBox>
    );
};

export const EditStyleLimitMessage: FunctionComponent = () => {
    const translate = useIventisTranslate();

    return (
        <ErrorBox>
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <FontAwesomeIcon icon={["fas", "exclamation-circle"]} />
                <Header4>{translate(Content.subscriptionplan3.limits.custom_layer.title)}</Header4>
            </div>
            <Body1>
                {translate(Content.subscriptionplan3.limits.custom_layer.content)} <SupportLink />
            </Body1>
        </ErrorBox>
    );
};

export const ErrorBox = styled.div`
    display: flex;
    flex-direction: column;

    padding: ${narrowPadding};
    gap: ${formGap};

    background-color: ${({ theme }: { theme: Theme }) => theme.secondaryColors.dark};
    border: 1px solid ${({ theme }: { theme: Theme }) => theme.toastColours.error};

    border-radius: ${borderRadius.standard};
`;

export const UserLimitRemainingMessage: FunctionComponent = () => {
    const translate = useIventisTranslate();

    return (
        <WarningContainer>
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <FontAwesomeIcon icon={["fas", "exclamation-circle"]} />
                <Header4>{translate(Content.subscriptionplan3.limits.users.limitRemaining.title)}</Header4>
            </div>
            <Body1>
                {translate(Content.subscriptionplan3.limits.users.limitRemaining.content)} <SupportLink />
            </Body1>
        </WarningContainer>
    );
};

const WarningContainer = styled.div`
    display: flex;
    flex-direction: column;

    padding: ${narrowPadding};
    gap: ${formGap};

    background-color: ${({ theme }: { theme: Theme }) => theme.secondaryColors.warning};
    border: 1px solid ${({ theme }: { theme: Theme }) => theme.otherColors.warningBackground};

    border-radius: ${borderRadius.standard};
`;

export const SupportLink: FunctionComponent = () => {
    const translate = useIventisTranslate();
    return (
        <Link color="inherit" underline="none" target="_blank" rel="noopener noreferrer" href={CONTACT_URL}>
            <b>{translate(Content.login.footer.contact)}</b>
        </Link>
    );
};
