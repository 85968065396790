export const smallestKilometerValueToShow = 1;
export const smallestMilesValueToShow = 1;

export const feetInMile = 5280;
export const squaredFeetInSquaredMile = 27878400;

export const metersInKilometer = 1000;
export const metersSquaredInKiloMeterSquared = 1000000;

export const feetToMeter = 3.28084;
export const mileToMeter = 1609.34;

export const squareFeetToSquareMeter = 10.7639;
export const squareMeterToSquareMile = 2590002.59;
