/* eslint-disable prettier/prettier */
import ErrorStackParser from "error-stack-parser";
import murmurHash3 from "murmurhash3js";

/**
 * Calculates the error code (hash) for a given string.
 *
 * @param s - The string to calculate the code for.
 * @returns The error code as a string.
 */
export function stringToErrorCode(s: string): string {
    // Hash the string using murmur
    const murmurhash: number = murmurHash3.x86.hash32(s);

    // Limit the hash result to 35^4 so we can split into 4 base 35 digits
    const charactersToGenerate = 4;
    let hash: number = murmurhash % (35 ** charactersToGenerate);

    // Get the base 35 characters (1-2, A-Z)
    const chars = "123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let result = "";
    for (let i = 0; i < charactersToGenerate; i += 1) {
        result = chars[hash % 35] + result;
        hash = Math.floor(hash / 35);
    }
    return result;
}

/**
 * Converts an error to an error code.
 * @param e Error to parse
 * @returns Error code string
 */
export function errorToErrorCode(e: Error): string {
    const errorStack = ErrorStackParser.parse(e);
    return stringToErrorCode(e.message + errorStack[0].fileName + errorStack[0].lineNumber);
}
