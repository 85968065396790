/* eslint-disable camelcase */

export const runSheet = {
    headings: {
        start: "Start",
        end: "End",
        duration: "Dur.",
        zero: "Zero +/-",
        activity: "Activity",
        location_space: "Location/Space",
    },
    actions: {
        insert_row_below: "Insert Row Below",
        insert_row_below_alt: "An Icon Displaying Insert Row Below",
        insert_row_above: "Insert Row Above",
        insert_row_above_alt: "An Icon Displaying Insert Row Above",
        export_as_excel: "Export as Excel",
    },
    activity: {
        start: "starts",
        zero: "ZERO TIME",
    },
    deleting: {
        deleting_affects_all_instances: "Deleting affects all instances",
        delete_activity_description_1: "Deleting this activity will also delete all the instances where it's set to repeat on other days",
        delete_activity_description_2: "If you'd rather just edit the repeat settings instead of deleting it, click on the start column",
    },
    add: {
        "Event unit": "Add scheduled item",
        Activity: "Add activity",
    },
    done: "Done",
    export_form: {
        title: "Export DCAS",
        days_to_export: "Days to export",
        all_days: "All days",
        all_days_desc: "Export all DCAS tables for this venue into a single excel file",
        selected_day: "Selected day",
        selected_day_desc: "Export the selected DCAS to an excel file",
    },
} as const;
