/* eslint-disable no-console */
import { Environment } from "./environments-types";

export function getEnvironment(apiUrl: string): Environment | undefined {
    switch (apiUrl) {
        case "app.iventis.com":
        case "sign-up.iventis.com":
            return Environment.Production;
        case "staging.iventis.studio":
            return Environment.Staging;
        case "ci.iventis.studio":
        case "dev.iventis.studio":
        case "sign-up-dev.iventis.com":
            return Environment.Development;
        case "localhost:8000":
        case "localhost":
            return Environment.Local;
        default:
            console.error(`Unknown environment for ${apiUrl}`);
            return undefined;
    }
}
