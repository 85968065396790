// eslint-disable-next-line iventis/filenames
import { AppDetails } from "@iventis/components";
import { ApplicationName } from "@iventis/domain-model/model/applicationName";

export const applicationDetails: AppDetails[] = [
    {
        icon: "house-chimney",
        appName: ApplicationName.Dashboard.toString(),
        url: "dashboard",
    },
    {
        icon: "map",
        appName: ApplicationName.Maps.toString(),
        url: "spatial-planner",
    },
    {
        icon: "calendar",
        appName: ApplicationName.Schedule.toString(),
        url: "schedule",
    },
    {
        icon: "users",
        appName: ApplicationName.People.toString(),
        url: "people",
    },
    {
        icon: "gear",
        appName: ApplicationName.Settings.toString(),
        url: "project-settings",
    },
];
