import { convertArea } from "@turf/helpers";
import { UnitOfMeasurement } from "@iventis/domain-model/model/unitOfMeasurement";
import { smallestKilometerValueToShow, smallestMilesValueToShow } from "./constants";

/**
 * Converts area value to Metric or imperial and then to an easily readable value. eg) "100,000 m\u00B2" changes to "0.1 km\u00B2"
 * @param length - Measurement value in meters
 * @param unitOfMeasurement - Metric or imperial system
 * @returns converted length with unit appended. eg) "10 m\u00B2" or "7.2 mi\u00B2"
 */
export function convertAreaMeasurement(area: number, unitOfMeasurement: UnitOfMeasurement) {
    switch (unitOfMeasurement) {
        case UnitOfMeasurement.Metric:
            return convertMetric(area);
        case UnitOfMeasurement.Imperial:
            return convertImperial(area);
        default:
            throw new Error(`Unit of measurement ${unitOfMeasurement} is not supported`);
    }
}

function convertMetric(area: number) {
    const areaInKilometers = convertArea(area, "meters", "kilometers");
    if (areaInKilometers >= smallestKilometerValueToShow) {
        return `${areaInKilometers.toFixed(2)} km\u00B2`;
    }
    return `${area.toFixed(2)} m\u00B2`;
}

function convertImperial(area: number) {
    const areaInMiles = convertArea(area, "meters", "miles");
    if (areaInMiles >= smallestMilesValueToShow) {
        return `${areaInMiles.toFixed(2)} mi\u00B2`;
    }
    return `${convertArea(area, "meters", "feet").toFixed(2)} ft\u00B2`;
}
