import React, { useContext } from "react";
import { ToastType, Toasts } from "@iventis/toasts";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { Content } from "@iventis/translations";
import { SUPPORT_EMAIL } from "@iventis/utilities";
import { styled } from "@iventis/styles";
import { Theme } from "@emotion/react";
import { ErrorBoundaryProps, IventisErrorBoundary } from "./error-boundary";

export const ErrorBoundaryToastContext = React.createContext<{ toast: Toasts }>(null);

export interface ErrorBoundaryToastProps extends ErrorBoundaryProps {
    toast?: Toasts;
}
/**
 * Error boundary that displays a toast when an error occurs and will replace the component with the fallback
 * Toast can be provided as a prop or through the context, prop will take precedence
 * @param Action - The action to perform in the error boundary fallback
 * @param size - The size of the toast. Large will display an image and a reload button. Overlay will make the component grey
 */
export const IventisErrorBoundaryWithToast: React.FC<ErrorBoundaryToastProps> = ({ children, action = "none", size = "large", onError, toast, className }) => {
    const translate = useIventisTranslate();

    const toastContext = useContext(ErrorBoundaryToastContext);

    return (
        <IventisErrorBoundary
            className={className}
            action={action}
            size={size}
            onError={(errorCode, error) => {
                onError?.(errorCode, error);
                (toast ?? toastContext?.toast)?.error(
                    {
                        type: ToastType.TITLE,
                        props: {
                            title: translate(Content.errors.error_boundaries.error_code, { code: errorCode }),
                            message: (
                                <>
                                    {translate(Content.errors.error_boundaries.error_message)}{" "}
                                    <StyledLink href={`mailto:${SUPPORT_EMAIL}?subject=Error code: ${errorCode}`}>{SUPPORT_EMAIL}</StyledLink>
                                </>
                            ),
                            icon: "circle-exclamation",
                        },
                    },
                    { key: errorCode, preventDuplicate: true, persist: true }
                );
            }}
        >
            {children}
        </IventisErrorBoundary>
    );
};

const StyledLink = styled.a`
    color: ${({ theme }: { theme: Theme }) => theme.typographyColors.core};
    font-weight: 600;
    text-decoration-line: underline;
`;
