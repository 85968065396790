import { DataField } from "@iventis/domain-model/model/dataField";
import { MapLayer } from "@iventis/domain-model/model/mapLayer";
import { StyleType } from "@iventis/domain-model/model/styleType";
import { SystemDataFieldName } from "@iventis/domain-model/model/systemDataFieldName";
import { createMapFromArray } from "@iventis/utilities";

/**
 * Returns the datafield for map object name
 * @Note SystemDataFieldName enums are only ever on project datafields
 */
export const getMapObjectNameSystemDatafield = (projectDatafields: DataField[]) => projectDatafields?.find((df) => df.systemDataFieldName === SystemDataFieldName.MapObjectName);

/**
 * Returns the datafield for Area, only ever on a mapobject with geometry type Polygon
 * @Note SystemDataFieldName enums are only ever on project datafields
 */
export const getMapObjectAreaSystemDataField = (projectDatafields: DataField[]) => projectDatafields?.find((df) => df.systemDataFieldName === SystemDataFieldName.MapObjectArea);

/**
 * Returns the datafield for Length, only ever on a mapobject with geometry type LineString
 * @Note SystemDataFieldName enums are only ever on project datafields
 */
export const getMapObjectLengthSystemDataField = (projectDatafields: DataField[]) =>
    projectDatafields?.find((df) => df.systemDataFieldName === SystemDataFieldName.MapObjectLength);

export const getMapObjectCoordinatesSystemDataField = (projectDatafields: DataField[]) =>
    projectDatafields?.find((df) => df.systemDataFieldName === SystemDataFieldName.Coordinates);

export const getMapObjectRouteTimeSystemDataField = (projectDatafields: DataField[]) => projectDatafields?.find((df) => df.systemDataFieldName === SystemDataFieldName.RouteTime);

/**
 * Returns the datafield for Quantity of models on a line, only ever on a mapobject with geometry type LineModel
 * @Note SystemDataFieldName enums are only ever on project datafields
 */
export const getMapObjectQuantityOfModelsOnLineSystemDataField = (projectDatafields: DataField[]) =>
    projectDatafields?.find((df) => df.systemDataFieldName === SystemDataFieldName.QuantityOfModelsOnLine);

/** Returns a mapping from System data field name to the matching data field id  */
export const getSystemDataFieldToIdMapping = (projectDataFields: DataField[]) => ({
    [SystemDataFieldName.MapObjectName]: getMapObjectNameSystemDatafield(projectDataFields)?.id,
    [SystemDataFieldName.MapObjectArea]: getMapObjectAreaSystemDataField(projectDataFields)?.id,
    [SystemDataFieldName.MapObjectLength]: getMapObjectLengthSystemDataField(projectDataFields)?.id,
    [SystemDataFieldName.Coordinates]: getMapObjectCoordinatesSystemDataField(projectDataFields)?.id,
    [SystemDataFieldName.RouteTime]: getMapObjectRouteTimeSystemDataField(projectDataFields)?.id,
    [SystemDataFieldName.QuantityOfModelsOnLine]: getMapObjectQuantityOfModelsOnLineSystemDataField(projectDataFields)?.id,
});

/**
 * Returns the required datafields for a layer
 * @return datafield for MapObjectName
 */
export const getRequiredDataFieldsForLayer = (projectDataFields: DataField[], styleType: StyleType) => {
    const systemDataFields = getLayerSystemDataFieldsFromStyleType(styleType);
    return projectDataFields.filter((df) => (df.systemDataFieldName != null ? systemDataFields.includes(df.systemDataFieldName) : false));
};

const getLayerSystemDataFieldsFromStyleType = (styleType: StyleType): SystemDataFieldName[] => {
    switch (styleType) {
        case StyleType.Area:
            return [SystemDataFieldName.MapObjectArea, SystemDataFieldName.MapObjectName];
        case StyleType.Line:
            return [SystemDataFieldName.MapObjectLength, SystemDataFieldName.RouteTime, SystemDataFieldName.MapObjectName];
        case StyleType.LineModel:
            return [SystemDataFieldName.MapObjectLength, SystemDataFieldName.RouteTime, SystemDataFieldName.MapObjectName, SystemDataFieldName.QuantityOfModelsOnLine];
        case StyleType.Point:
        case StyleType.Icon:
        case StyleType.Model:
            return [SystemDataFieldName.Coordinates, SystemDataFieldName.MapObjectName];
        default:
            return [SystemDataFieldName.MapObjectName];
    }
};

/**
 * Given a new style type will return the layer's updated datafields (remove or add system datafields)
 */
export function updateLayerDataFieldsOnStyleTypeChange(newStyleType: StyleType, mapLayer: MapLayer, projectDataFields: DataField[]): DataField[] {
    switch (newStyleType) {
        case StyleType.LineModel: {
            const quantityDataField = getMapObjectQuantityOfModelsOnLineSystemDataField(projectDataFields);
            return [...mapLayer.dataFields, quantityDataField];
        }
        case StyleType.Line: {
            const quantityDataField = getMapObjectQuantityOfModelsOnLineSystemDataField(projectDataFields);
            return mapLayer.dataFields.filter((df) => df.id !== quantityDataField.id);
        }
        default:
            return mapLayer.dataFields;
    }
}

/** Sorts datafields to ensure MapObjectName datafield is first element in the array */
export const sortDataFieldsBySystemDataField = (projectDataFields: DataField[], dataFields: DataField[]) => {
    const containsSystemDataField = projectDataFields?.some((dataField) => dataField.systemDataFieldName != null);

    // If does not contain a system datafield just return the datafields as is
    if (!containsSystemDataField) {
        return dataFields;
    }
    const projectDataFieldsMap = createMapFromArray(projectDataFields, "id");
    const mapObjectNameDataField = getMapObjectNameSystemDatafield(projectDataFields);

    return dataFields.reduce<DataField[]>((orderedDataFields, dataField) => {
        // Remove any repeated items
        if (orderedDataFields.some((df) => df.id === dataField.id)) return orderedDataFields;
        const projectDataField = projectDataFieldsMap.get(dataField.id);
        switch (projectDataField?.systemDataFieldName) {
            case SystemDataFieldName.MapObjectName:
                return [dataField, ...orderedDataFields];
            case SystemDataFieldName.MapObjectArea:
            case SystemDataFieldName.MapObjectLength:
            case SystemDataFieldName.Coordinates:
            case SystemDataFieldName.QuantityOfModelsOnLine:
            case SystemDataFieldName.RouteTime: {
                if (orderedDataFields[0]?.id === mapObjectNameDataField?.id) {
                    // Find where last map object name datafield is
                    const indexOfLastMapObjectNameDataField = orderedDataFields.map(({ id }) => id).lastIndexOf(mapObjectNameDataField?.id);
                    // Spilt the datafields into map object names and other datafields
                    const mapObjectNameDataFields = orderedDataFields.slice(0, indexOfLastMapObjectNameDataField + 1);
                    const otherDataFields = orderedDataFields.slice(indexOfLastMapObjectNameDataField + 1);
                    // Place datafield after map object names and before the other datafields
                    return [...mapObjectNameDataFields, dataField, ...otherDataFields];
                }
                return [dataField, ...orderedDataFields];
            }
            default:
                return [...orderedDataFields, dataField];
        }
    }, []);
};
