import { IRouteWaypointService } from "@iventis/map-engine";
import { RouteWaypoint } from "@iventis/domain-model/model/routeWaypoint";
import { mappingApi } from "@iventis/api/src/api";

/**
 * Api calls needed to edit route waypoints and generate routes in the main application
 */
export const routeWaypointService: IRouteWaypointService = {
    getRouteWaypoints: async (mapObjectId: string, mapId: string) => {
        const { data } = await mappingApi.get<RouteWaypoint[]>(`/maps/${mapId}/map-objects/${mapObjectId}/route-waypoints`);
        return data;
    },
    putRouteWaypoints: async (mapObjectId: string, mapId: string, routeWaypoints: RouteWaypoint[]) => {
        if (routeWaypoints.length < 2) {
            throw new Error("Route waypoints must have at least two waypoints");
        }

        const { data } = await mappingApi.put<RouteWaypoint[]>(`/maps/${mapId}/map-objects/${mapObjectId}/route-waypoints`, routeWaypoints);
        return data;
    },
};
