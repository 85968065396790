import { ExportFormat } from "@iventis/domain-model/model/exportFormat";
import { ExportStatus } from "@iventis/domain-model/model/exportStatus";
import { Content } from "@iventis/translations";

/** Generates a notification text for plan exports */
export function getExportNotificationText(type: ExportFormat, status: ExportStatus, exportName: string, translate: (key: string) => string) {
    const exportTypeText = type.toUpperCase();
    let statusText = "";
    switch (status) {
        case ExportStatus.Completed:
            statusText = translate(Content.notifications.planExport.completed);
            break;
        case ExportStatus.Failed:
            statusText = translate(Content.notifications.planExport.failed);
            break;
        default:
            statusText = translate(Content.notifications.planExport.inProgress);
            break;
    }
    return `${exportTypeText} ${translate(Content.notifications.planExport.export)} '${exportName}' ${statusText}.`;
}
