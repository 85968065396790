/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactNode, useMemo, useState } from "react";
import { Header5, borderRadius, styled, fontSizes } from "@iventis/styles";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { Divider } from "@mui/material";
import { CloseButton, RefreshButtonComponent } from "@iventis/components";
import { Content } from "@iventis/translations";
import { useIsElementScrollable } from "@iventis/utilities";
import { NotificationResourceType } from "@iventis/domain-model/model/notificationResourceType";
import { ExportPlanNotificationComponent } from "./export-plan-notification";
import { UserNotificationSkeletonComponent } from "./user-notification-skeleton";
import { isNotificationInProgress } from "./notification-helpers";
import { LayersImportNotificationComponent } from "./layers-import-notification";
import { UserNotificationUnion } from "./notification-types";
import { LayersCopyNotificationComponent } from "./layers-copy-notification";

type UserNotificationComponentsProps = {
    [NotificationResourceType.MapExport]: Omit<Parameters<typeof ExportPlanNotificationComponent>[0], "notification" | "onClick">;
    [NotificationResourceType.LayersImport]: Omit<Parameters<typeof LayersImportNotificationComponent>[0], "notification" | "onClick">;
    [NotificationResourceType.LayersCopy]: Omit<Parameters<typeof LayersCopyNotificationComponent>[0], "notification" | "onClick">;
};

interface UserNotificationListProps {
    loading: boolean;
    notifications: UserNotificationUnion[];
    registerNotificationAsRead: (notificationId: string) => void;
    onClose: () => void;
    onRefreshNotifications: () => void;
    notificationProps: UserNotificationComponentsProps;
}

// 10 Seconds between each time the user can press the refresh button
const REFRESH_BUTTON_TIMEOUT = 10000;

/** Displays a list of notifications in two separate groups, read and unread */
export const UserNotificationListComponent = ({
    loading,
    notifications,
    registerNotificationAsRead,
    onRefreshNotifications,
    notificationProps,
    onClose,
}: UserNotificationListProps) => {
    const translate = useIventisTranslate();

    // If the list is scrollable, the width of the container should be increased to accommodate the scrollbar
    const { elementRef: listRef, isElementScrollable } = useIsElementScrollable();

    const [refreshButtonDisabled, setRefreshButtonDisabled] = useState(false);

    const refreshNotifications = () => {
        setRefreshButtonDisabled(true);
        onRefreshNotifications();
        setTimeout(() => {
            setRefreshButtonDisabled(false);
        }, REFRESH_BUTTON_TIMEOUT);
    };

    // Get all the notification components
    const { inProgress: inProgressNotifications, completed: completedNotifications } = useMemo(
        () =>
            notifications.reduce<{ inProgress: ReactNode[]; completed: ReactNode[] }>(
                (notifications, notification) => {
                    // Get notification component based on its type
                    const component = getUserNotificationComponent(notification, registerNotificationAsRead, notificationProps);
                    // Sort notifications into inProgress and completed groups
                    if (isNotificationInProgress(notification)) {
                        return { ...notifications, inProgress: [...notifications.inProgress, component] };
                    }
                    return { ...notifications, completed: [...notifications.completed, component] };
                },
                { inProgress: [], completed: [] }
            ),
        [notifications]
    );

    return (
        <NotificationListContainer data-testid="notification-list-container" isListScrollable={isElementScrollable}>
            <div className="notification-header">
                <Header5>{translate(Content.notifications.list.title)}</Header5>
                <div className="header-buttons">
                    <RefreshButtonComponent disabled={refreshButtonDisabled || loading} onClick={refreshNotifications} />
                    <CloseButton onMouseDown={onClose} className="notification-close-button" dataTestId="notifications-close-button" />
                </div>
            </div>
            <Divider className="notification-divider" sx={{ marginBottom: "10px" }} />
            <div data-testid="notification-list" className="notification-list notification-list-overflow" ref={listRef}>
                {loading ? (
                    <>
                        <UserNotificationSkeletonComponent />
                        <UserNotificationSkeletonComponent />
                        <UserNotificationSkeletonComponent />
                        <UserNotificationSkeletonComponent />
                        <UserNotificationSkeletonComponent />
                        <UserNotificationSkeletonComponent />
                    </>
                ) : (
                    <>
                        <div className="notification-list">{...inProgressNotifications}</div>
                        {inProgressNotifications.length > 0 && completedNotifications.length > 0 && <Divider className="notification-divider" />}
                        {completedNotifications.length > 0 && <div className="notification-list">{...completedNotifications}</div>}
                        {completedNotifications.length === 0 && inProgressNotifications.length === 0 && (
                            <div className="no-notifications">{translate(Content.notifications.noNotifications)}</div>
                        )}
                    </>
                )}
            </div>
        </NotificationListContainer>
    );
};

/** Gets a notification component based on it's type */
const getUserNotificationComponent = (notification: UserNotificationUnion, registerNotificationAsRead: (id: string) => void, additionalProps: UserNotificationComponentsProps) => {
    switch (notification.resourceType) {
        case NotificationResourceType.MapExport:
            return (
                <ExportPlanNotificationComponent
                    key={`${notification.id}-${notification.lastUpdatedAt.toString()}`}
                    notification={notification}
                    onClick={() => registerNotificationAsRead(notification.id)}
                    // eslint-disable-next-line react/destructuring-assignment
                    {...additionalProps[notification.resourceType]}
                />
            );
        case NotificationResourceType.LayersImport:
            return (
                <LayersImportNotificationComponent
                    key={`${notification.id}-${notification.lastUpdatedAt.toString()}`}
                    notification={notification}
                    onClick={() => registerNotificationAsRead(notification.id)}
                    // eslint-disable-next-line react/destructuring-assignment
                    {...additionalProps[notification.resourceType]}
                />
            );
        case NotificationResourceType.LayersCopy:
            return (
                <LayersCopyNotificationComponent
                    key={`${notification.id}-${notification.lastUpdatedAt.toString()}`}
                    notification={notification}
                    onClick={() => registerNotificationAsRead(notification.id)}
                    // eslint-disable-next-line react/destructuring-assignment
                    {...additionalProps[notification.resourceType]}
                />
            );
        default:
            // @ts-expect-error All resource types should be handled here
            throw new Error(`Notification ${notification.resourceType} was not handled`);
    }
};

const NotificationListContainer = styled.div<{ isListScrollable: boolean }>`
    width: ${(props) => (props.isListScrollable ? "330px" : "310px")};
    max-height: 510px;
    padding: 10px ${(props) => (props.isListScrollable ? "10px" : "20px")} 20px 20px;
    box-sizing: border-box;
    overflow: hidden;

    display: flex;
    flex-direction: column;

    border-radius: ${borderRadius.standard};
    box-shadow: 5px 5px 5px ${({ theme }) => theme.shades.three};

    .notification-list {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
    }

    .no-notifications {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        padding: 20px;
        font-size: ${fontSizes.small};
    }

    .notification-list-overflow {
        overflow-y: auto;
        overflow-x: hidden;
    }

    .notification-divider {
        height: 2px;
        width: 100%;
    }

    .notification-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .header-buttons {
            display: flex;
        }
    }

    .notification-close-button {
        position: relative;
    }
`;
