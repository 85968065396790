/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";
import { Button, DialogTitle as DialogTitleMUI, FormControl, FormControlLabel, Radio, RadioGroup, TextField } from "@mui/material";
import { styled, Body1, Body2, Header2, Header5, InteractiveElement, fontSizes, formFieldVerticalGap, minimumConfirmationDialogWidth, muiInputFormsCSS } from "@iventis/styles";

import { Theme } from "@emotion/react";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { Content } from "@iventis/translations";
import { ProjectSubscriptionEvent } from "@iventis/domain-model/model/projectSubscriptionEvent";
import { DialogActions, DialogContent } from "./subscription-billing-cancel-modal";

export type CancellationQuestions = Pick<ProjectSubscriptionEvent, "cancelReason"> & Pick<ProjectSubscriptionEvent, "cancelAdditionalReason">;

export const ReasonCancelContent = ({ onConfirm, cancel }: { onConfirm: (answers?: CancellationQuestions) => void; cancel: () => void }) => {
    const translate = useIventisTranslate();

    const [answers, setAnswers] = useState<CancellationQuestions>({ cancelReason: null, cancelAdditionalReason: null });

    const cancellationAnswers = [
        { value: "I am not getting complete use of Iventis", label: translate(Content.subscriptionplan3.cancellation.reasonOne) },
        { value: "I am finding it difficult to use", label: translate(Content.subscriptionplan3.cancellation.reasonTwo) },
        { value: "It does not have a feature I am looking for", label: translate(Content.subscriptionplan3.cancellation.reasonThree) },
        { value: "It is too expensive", label: translate(Content.subscriptionplan3.cancellation.reasonFour) },
        { value: "I have deciced to switch to another product", label: translate(Content.subscriptionplan3.cancellation.reasonFive) },
        { value: "Other", label: translate(Content.subscriptionplan3.cancellation.reasonSix) },
    ];

    return (
        <>
            {/* Component is a non-typed prop for MUI DialogTitle - https://stackoverflow.com/questions/70441304/how-do-i-make-the-mui-dialog-title-an-h1-element-so-the-modal-is-accessible */}
            <DialogTitle {...{ component: "div" }}>
                <Header2>{translate(Content.subscriptionplan3.cancellation.reasonHeadingOne)}</Header2>
                <Body1>{translate(Content.subscriptionplan3.cancellation.reasonHeadingTwo)}</Body1>
            </DialogTitle>
            <DialogContent>
                <DialogContentContainer>
                    <FormControl className="question-container">
                        <Header5 className="question-title">{translate(Content.subscriptionplan3.cancellation.reasonSubheadingOne)}</Header5>
                        <RadioGroup value={answers?.cancelReason ?? ""} onChange={(event) => setAnswers({ ...answers, cancelReason: event.target.value })}>
                            {cancellationAnswers.map((answer) => (
                                <FormControlLabel key={answer.value} value={answer.value} control={<Radio />} label={<Body2>{translate(answer.label)}</Body2>} />
                            ))}
                        </RadioGroup>
                    </FormControl>
                    <FormControl className="question-container">
                        <Body1>{translate(Content.subscriptionplan3.cancellation.reasonSubheadingTwo)}</Body1>
                        <TextField
                            placeholder={translate(Content.subscriptionplan3.cancellation.addComment)}
                            onChange={(event) => setAnswers({ ...answers, cancelAdditionalReason: event.target.value })}
                        />
                    </FormControl>
                </DialogContentContainer>
            </DialogContent>
            <DialogActions justifycontent="space-between">
                <SkipButton className="form-button" onClick={() => onConfirm(answers)}>
                    {translate(Content.common.buttons.skip)}
                </SkipButton>
                <ConfirmCancelContainer>
                    <Button className="form-button continue-sub" variant="outlined" onClick={cancel}>
                        {translate(Content.subscriptionplan3.cancellation.keepSubscription)}
                    </Button>
                    <Button className="form-button" variant="contained" disabled={answers?.cancelReason == null} onClick={() => onConfirm(answers)}>
                        {translate(Content.common.buttons.confirm)}
                    </Button>
                </ConfirmCancelContainer>
            </DialogActions>
        </>
    );
};

const DialogTitle = styled(DialogTitleMUI)`
    border-bottom: ${({ theme }: { theme: Theme }) => `1px ${theme.shades.three} solid`};
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    padding: 30px 0px 15px 0px;
`;

const DialogContentContainer = styled.div`
    ${muiInputFormsCSS}

    padding: 0px 10px;
    width: ${minimumConfirmationDialogWidth};
    display: flex;
    flex-direction: column;
    gap: ${formFieldVerticalGap};

    .question-title {
        font-size: ${fontSizes.large};
    }

    .question-container {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
`;

const ConfirmCancelContainer = styled.div`
    display: flex;
    gap: 10px;
    .continue-sub {
        width: fit-content !important;
    }
`;

const SkipButton = styled(InteractiveElement)`
    text-decoration: underline;
`;
