export const login = {
    headings: {
        login: "Log in",
        forgotten: "Forgotten my password",
        confirmation: "Email sent",
        sending: "Email sending...",
        setPass: "Set your password",
        startYourFreeTrial: "Start your free trial.",
    },
    subHeadings: {
        freeTrial: "30-day free trial. No credit-card required.",
        login: "To access your Iventis account, start by entering your email address.",
        forgotten: "Enter the email address linked to your Iventis account and we'll send you an email to reset your password.",
        confirmation: "Check your email for a link to reset your password. It may take up to 10 mins to arrive.",
        confirmationCode: "Check your email for a verification code for your new account. It may take up to 10 minutes to arrive.",
        setPass: "Enter a new password for your account below.",
    },
    labels: {
        firstName: "First name",
        lastName: "Last name",
        projectName: "Project Name",
        email: "Email address",
        password: "Password",
        confirmPass: "Repeat password",
        verificationCode: "Verification Code",
    },
    placeholders: {
        email: "Enter your email address",
        password: "Enter your password",
        passwordCreate: "Enter password",
        passwordConfirm: "Type password again",
    },
    links: {
        signIn: "Sign In",
        forgotPass: "Forgotten my password",
        sendAgain: "Send again",
        termsAndCo: "Terms and Conditions",
        back: "Back",
        register: "Register",
        loginWithEmail: "Login with email instead",
        loginWithSingleSignOn: "Login with Single Sign-on",
        loginWithMicrosoft: "Login with your Microsoft account",
    },
    options: {
        alreadyHaveAccount: "Already have an Iventis account?",
        dontHaveAccount: "Don't have a {{ ssoProviderName }} ?",
        dontHaveIventis: "Don't have an Iventis account?",
        rememberDetails: "Remember my details",
        notReceived: "Not received an email?",
        consentTos1: "In creating this account, I agree to the",
        consentTos2: "of the Iventis platform.",
        verified: "Code verified successfully! Your account is now active.",
    },
    passwordConditions: {
        min8characters: "Minimum 8 characters",
        lowercaseLetter: "Contains a lowercase letter",
        uppercaseLetter: "Contains an uppercase letter",
        digit: "Contains a number",
    },
    buttons: {
        continue: "Continue",
        login: "Log in",
        sendLink: "Send reset link",
        createAccount: "Create account",
    },
    footer: {
        terms: "Terms of Service",
        policy: "Privacy policy",
        contact: "Contact us",
    },
    error: {
        invalidCode: "Invalid code.",
        unknownError: "Something went wrong",
        failed: "Failed to set password. Please try again.",
        required: "Required",
        invalid: "Invalid email address",
        cantFindEmail: "We can't find an Iventis account linked to that email address. Try again, or create an account.",
        passwordTooLong: "Password can be maximum 64 characters long",
        passwordsMustMatch: "Passwords don't match",
        mustAgreeToTC: "To create an account, you must accept our Terms and Conditions",
        resetPasswordLinkInvalidTitle: "Link Expired",
        resetPasswordLinkInvalid:
            "The link to reset your password was invalid or has expired. We have sent a new link to the email address associated with your account. If you do not receive a new link, try using the 'forgotten password' link below or contact",
        emailInUse: {
            0: "This email is already in use.",
            1: "Click here",
            2: "to login",
        },
    },
} as const;

export const login2 = {
    questionnaire: {
        welcomeIntro: "Help us get you set up by answering a few quick questions",
        step: "Step",
        steps: "Step {{ currentStep }} of {{ totalSteps }}",
        reason: {
            question: "Tell us what brought you to Iventis today?",
            options: {
                SitePlanForMyEvent: "To create a site plan for my event",
                TeamPlanMoreEfficiently: "See how my team can plan more efficiently",
                PlanMultipleEventsPerProject: "Explore how to plan multiple events or projects",
                JustCurious: "Just curious",
            },
        },
        eventType: {
            question: "And what are you looking to plan?",
            options: {
                SportsEvent: "Sports event",
                RoadEvent: "Road event",
                FestivalOrParade: "Festival or parade",
                ExhibitionOrShow: "Exhibitions & conferences",
                MajorEvent: "Major event",
                MultipleEvents: "Multiple events",
                Venue: "A venue",
                SomethingElse: "Something else",
            },
        },
        experience: {
            question: "How much experience do you have with planning tools?",
            options: {
                HaventUsedBefore: "I haven't used any before",
                Powerpoint: "Used PowerPoint",
                GoogleEarth: "Used apps like Google Earth",
                GisAndCadSoftware: "Used GIS and CAD software",
                Iventis: "I have used Iventis before",
            },
        },
        projectName: {
            question: "And finally, what name do you have in mind for your project today?",
            subLabel: "Hint: this could be your event or company name.",
            options: {
                projectName: "Project name",
            },
            label: "Please enter a name for your project:",
        },
        skip: "Skip for now",
        ending_screen: {
            initial_loading: {
                message: "We are just setting things up for you",
                subtext: "Setting up your project and analysing your requirements",
                footerText: "This may take a few seconds",
            },
            taking_longer: {
                message: "It is taking a little longer than we expected, hang tight!",
                subtext: "Sharpening up our tools to give you the crispest results",
                footerText: "This may take a few seconds",
            },
            error: {
                message: "Ooops something has gone wrong.",
                subtext: "There seems to be an issue on our side.",
                footer_1: "Please",
                footer_2: "so we can help you figure out what’s happened.",
            },
        },
        setup_completed: "Setup completed",
    },
    passwordConditions: {
        commonPassword: "Password too simple. Please try something more complex.",
    },
    verification: {
        headings: {
            checkEmail: "Check your email",
            emailVerified: "Email verified",
            alreadyVerified: "You're good to go",
        },
        ctas: {
            continueToLogin: "Continue to login",
            resendEmail: "Click to resend",
        },
        noEmail: "Didn't receive the email?",
        emailSent: "We sent a verification link to",
        timerForResend: "in {{ time }} seconds",
        accountVerifiedMessage: "Your email account has been verified, click the button below to login.",
        unverifiedErrorMessage: "We have been unable to verify your email account please contact our support team on",
        alreadyVerifiedMessage: "This email account has already been verified. Click the button below to login.",
    },
    createAccount: {
        free_trial: "Start Free Trial",
        marketing: "Keep me up-to-date with the latest news and updates",
        iventisAccount: "Already have an Iventis account?",
        error: "There has been an issue creating your account. Please contact",
        disableAdBlocker: "or try disabling your ad-blocker and try again.",
    },
    logout: "Logout",
} as const;
