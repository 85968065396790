import { IdentifyableEntity } from "@iventis/domain-model/model/identifyableEntity";

/**
 * Immutably replace array index. Returns a new array with the index set to the new element value.
 */
export const replaceArrayIndex = <T>(array: T[], index: number, value: T) => Object.assign(array.slice(), { [index]: value });

/**
 * Immutably replace an item in an array. Returns a new array with the index set to the new element value.
 * Requires item to be an identifyable entity
 */
export const replaceItemInArray = <T extends IdentifyableEntity>(array: T[], value: T) => {
    const index = array.findIndex(({ id }) => value.id === id);
    return replaceArrayIndex(array, index, value);
};

/**
 * Replaces an element inside the array, on a property of the elements being equal
 */
export const replaceArrayElementByProperty = <TElement>(array: TElement[], property: keyof TElement, item: TElement): TElement[] =>
    array.map((element) => (element[property] === item[property] ? item : element));

/**
 * Turns an array into a map with a given property as a key
 */
export const createMapFromArray = <TElement extends Object>(array: TElement[], propertyForMapId: keyof TElement): Map<TElement[keyof TElement], TElement> => {
    const map: Map<TElement[keyof TElement], TElement> = new Map();
    array.forEach((element) => {
        map.set(element[propertyForMapId], element);
    });
    return map;
};

/** This function will remove any items from the array that have repeated comparison values */
export const removeRepeatedItemsFromArray = <T>(getPrimitiveValue: (t: T) => string | number, array: T[]) =>
    array.reduce((acc, curr) => (acc.some((val) => getPrimitiveValue(val) === getPrimitiveValue(curr)) ? acc : [...acc, curr]), [] as T[]);

/** Splits an array into smaller arrays  */
export const splitArrayByLength = <TElement>(array: TElement[], splitLength: number): TElement[][] => {
    const arrays: TElement[][] = [];
    for (let index = 0; index <= array.length - 1; index += splitLength) {
        const elements = array.slice(index, index + splitLength);
        arrays.push(elements);
    }
    return arrays;
};
