/* eslint-disable react/jsx-props-no-spreading */
import React, { Suspense } from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import { lazy } from "@loadable/component";
import { LoadingComponent } from "@iventis/components";
import { isDeployed } from "@iventis/api/src/api";
import PrivateRouter from "./private.router";
import PrivateRoute from "./private.route";
import PublicRoute from "./public.route";
import PublicRouteWithRedirect from "./public-redirect.route";

// The public router contains all the publics routes (Routes that can only be accessed when not authenticated).
// The public router also contains a link to the private router (Routes that can only be acccesed when authenticated).
const PublicRouter: React.FC = () => {
    const Login = lazy(() => import("@iventis/login/src/login"));
    const ForgottenPasswordComponent = lazy(() => import("@iventis/login/src/forgotten-password-panel"));
    const ForgottenPasswordConfirmationComponent = lazy(() => import("@iventis/login/src/forgotten-password-confirmation"));
    const CalendarTestingComponent = lazy(() => import("@iventis/calendar/testing/calendar-testing"));
    const DataTableTestingComponent = lazy(() => import("@iventis/data-table/components/data-table-testing-component"));
    const DragAndDropTestingComponent = lazy(() => import("@iventis/drag-and-drop/src/drag-and-drop-testing-component"));
    const ModelTestingComponent = lazy(() => import("@iventis/map-engine/src/testing/model-testing-component"));
    const LibraryTestingComponent = lazy(() => import("@iventis/plans/src/plan-library/library-testing-component"));
    const MapPerformanceComponent = lazy(() => import("@iventis/testing-components/src/map-performance-component"));
    const SetPasswordPanel = lazy(() => import("@iventis/login/src/set-password-panel"));
    const VerifyEmailPending = lazy(() => import("@iventis/login/src/verify-email-pending"));
    const VerifyEmail = lazy(() => import("@iventis/login/src/verify-email"));
    const Onboarding = lazy(() => import("@iventis/login/src/onboarding"));

    return (
        <BrowserRouter>
            <Suspense fallback={<LoadingComponent />}>
                <Switch>
                    <PublicRouteWithRedirect exact path="/login" component={Login} />
                    <PublicRouteWithRedirect exact path="/forgotten-password" component={ForgottenPasswordComponent} />
                    <PublicRouteWithRedirect path="/forgotten-password/email-sent" component={ForgottenPasswordConfirmationComponent} />
                    <Route path="/verify" component={VerifyEmail} />
                    <Route path="/verify-email" component={VerifyEmailPending} />
                    {!isDeployed && <PublicRoute exact path="/calendar-testing" component={CalendarTestingComponent} />}
                    {!isDeployed && <PublicRoute exact path="/data-table-testing" component={DataTableTestingComponent} />}
                    {!isDeployed && <PublicRoute exact path="/drag-and-drop-testing" component={DragAndDropTestingComponent} />}
                    {!isDeployed && <PublicRoute exact path="/model-testing" component={ModelTestingComponent} />}
                    {!isDeployed && <PublicRoute exact path="/library-testing" component={LibraryTestingComponent} />}
                    {!isDeployed && <PublicRoute exact path="/map-performance" component={MapPerformanceComponent} />}
                    {/* Dummy route to set password for testing purposes */}
                    <PublicRouteWithRedirect exact path="/set-password" component={SetPasswordPanel} />
                    {/* We do not want to redirect, the user maybe authenticated but still needs to answer the questionaire, don't try and redirect them */}
                    <Route path="/onboarding" render={() => <Onboarding />} />
                    <PrivateRoute path="/" render={(props) => <PrivateRouter {...props} />} />
                </Switch>
            </Suspense>
        </BrowserRouter>
    );
};
export default PublicRouter;
