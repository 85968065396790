import type { History } from "history";
import { useHistory as _useHistory } from "react-router";
import { API_KEY_QUERY_PARAM_KEY, PROJECT_ID_QUERY_PARAM_KEY } from "@iventis/utilities/src/constants";

const preservedApiKeys = [API_KEY_QUERY_PARAM_KEY, PROJECT_ID_QUERY_PARAM_KEY];

export const useHistory = (): History<unknown> => {
    const history = _useHistory();

    return {
        ...history,
        push: (props) => {
            const params = new URLSearchParams(history.location.search);
            const keptParams = new URLSearchParams(typeof props.search === "string" ? props.search : "");
            preservedApiKeys.forEach((key) => {
                // Wipe any existing key
                keptParams.delete(key);

                // Add new keys
                const value = params.get(key);
                if (value != null) {
                    keptParams.append(key, value);
                }
            });
            if (typeof props === "string") {
                const [pathname, search] = props?.split("?");
                const keptSearchString = keptParams.toString();
                return history.push({
                    pathname,
                    search: keptSearchString === "" ? (search ? `?${search}` : "") : `${keptSearchString}${search ? `&${search}` : ""}` ?? "",
                    hash: history.location.hash,
                });
            }
            return history.push({ ...props, search: keptParams.toString(), hash: props.hash ?? history.location.hash });
        },
    };
};
