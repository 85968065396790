import { styled } from "@iventis/styles";
import React from "react";

interface SkeletonCircleProps {
    width: string;
}

export const SkeletonCircleComponent = ({ width }: SkeletonCircleProps) => <SkeltonCircle width={width} />;

const SkeltonCircle = styled.div<SkeletonCircleProps>`
    width: ${({ width }) => width};
    height: ${({ width }) => width};
    border-radius: 50%;
    background-color: ${({ theme }) => theme.shimmer.background};
`;
