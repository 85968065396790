import { IGridColours } from "./style.types";

export const defaultIGridColours: IGridColours = {
    secondLevelRowHeaderLineColour: "#b6b6b6",
    thirdLevelRowHeaderLineColour: "#e9e9e9",
    alternateColumnColour: "#fbf9fa",
    firstLevelRowHeaderBackgroundColour: "#e6e6e6",
    secondLevelRowHeaderBackgroundColour: "#d5d5d5",
    thirdLevelRowHeaderBackgroundColour: "#f4f2f3",
};
