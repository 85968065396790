/* eslint-disable camelcase */
export const schedule = {
    create_new_schedule: "Create a new schedule",
    copy_schedule_alert: "It may take a few minutes to copy the Schedule data",
    manage_schedule_settings: "Manage schedule settings",
    all_venues: "All venues",
    no_venues: "No venues",
    x_venues_selected: "{{ count }} venues selected",
    view_by: "View by",
    day: "Day",
    venue: "Venue",
    cluster: "Cluster",
    no_space_defined: "No space defined",
    no_sessions_in_view: "No sessions in this view",
    discipline: "Discipline",
    day_discipline: "Day (Discipline)",
    day_venue: "Day (Venue)",
    daily_run_sheet: "Daily Run Sheet",
    venue_run_sheet: "Venue Run Sheet",
    DCAS: "DCAS",
    summary: "Summary",
    show_scheduled_items: "Show scheduled items",
    hide_scheduled_items: "Hide scheduled items",
    show_sessions: "Show sessions",
    hide_sessions: "Hide sessions",
    error_loading_schedule: "Something went wrong loading the schedule",
    schedule: "Schedule",
    create_schedule_form: {
        create_new_schedule: "Create a new schedule",
        name_field_label: "Name of new schedule",
        status_field_label: "Set status of new schedule",
        status_field_options: {
            draft_label: "Draft",
            draft_description: "Schedule only visible to those named in the permission settings",
            published_label: "Published",
            published_description: "Visible to everyone",
        },
        copy_versions_label: "Select the Discipline Schedules that you'd like to copy into this new schedule",
    },
    create_sub_schedule_form: {
        create_new_sub_schedule: "Create a new {{ subSchedule }} Schedule",
        parent_schedule: "Parent schedule",
        new: "New",
        copy_existing: "Copy of existing",
        name_of_calendar: "Name of calendar",
        selectSubSchedulesToCopy: "Select the {{ scheduleVersionType }}(s) that you'd like to copy",
    },
    edit_schedule_form: {
        edit_schedule: "Manage schedule settings",
        name_field_label: "Name of schedule",
        status_field_label: "Set status of schedule",
    },
    edit_schedule_data_options: {
        title: "Edit schedule data options",
    },
    add_data_fields_form: {
        add_datafield_list_items: "Add Attribute List Items",
        download_template: "Download CSV Template",
        upload: "Upload CSV",
        venue_location: "Venue Location",
    },
    settings: "settings",
    visibility: "visibility",
    create_session_form: {
        create_new_session: "Create new session",
        ticketedDescription: "Session flagged as ticketed by default",
        headings: {
            formTitleNew: "New session",
            formTitleEdit: "Edit Session",
            details: "Details",
            time: "Session times",
            venue: "Venue",
            events: "Events in this session",
            type: "Session style",
            entry: "Entry and broadcasting",
            ticketingAndBroadcasting: "Ticketing and broadcasting",
            ticketed: "Ticketed",
        },
        inputLabels: {
            code: "Session code",
            style: "Session Style",
            description: "Session description (optional)",
            day: "Day",
            spare: "Use as spare day",
            sTime: "Start Time",
            eTime: "End Time",
            zTime: "Zero Time",
            zeroCheckBoxLabel: "Same as session start time",
            estimated: "Estimated End",
            duration: "Duration (minutes)",
            ticketed: "Ticketed session",
        },
        buttons: {
            addEvent: "Add an event",
            addAnother: "Add another event",
            removeEvent: "Remove event",
            addPhase: "Add Phase",
            addAnotherPhase: "Add Another Phase",
            cancel: "Cancel",
            save: "Save",
            delete_session: "Delete session",
        },
        delete: {
            title: "Delete session",
            message: "Deleting this session will also delete its scheduled items and any activities that are specifically related to it, do you wish to continue?",
            confirm: "Confirm",
            cancel: "Cancel",
        },
    },
    summary_grid: {
        venue: "Venue",
        discipline: "Discipline",
    },
    scheduled_item: {
        CREATE: "New scheduled item",
        EDIT: "Edit scheduled item",
        within_event: "Within event",
        delete_item: "Delete item",
        venue: "Venue",
        space: "Space",
        time: "Time",
        location: "Location",
        delete: {
            title: "Delete scheduled item",
            message: "Are you sure?",
            confirm: "Confirm",
            cancel: "Cancel",
        },
        time_type: {
            not_before: {
                name: "Not Before",
                description: "Scheduled item will not begin before stated started time.",
            },
            followed_by: {
                name: "Followed By",
                description: "Start time depends on preceding scheduled item end time.",
            },
        },
        estimated_duration: "Estimated Duration",
        estimated_duration_description: "Exact duration of scheduled item is not certain.",
        save_and_next: "Save & next",
        save_and_finish: "Save & finish",
    },
    session_card: {
        day: "Day",
        start_time: "Start time",
        end_time: "End time",
        duration: "Duration",
        medal_session: "Medal session",
        add_scheduled_item: "Add scheduled item",
        scheduled_items_in_session: "Scheduled items in session",
        events_in_session: "Events in session",
        close_popup: "Close popup",
        edit: "Edit",
        zero_time: "Zero time",
        no_space: "No Space",
        in: "In",
        show_more: "Show more",
        show_less: "Show less",
    },
} as const;
