/* eslint-disable no-param-reassign */
import { notificationsApi } from "@iventis/api/src/api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ConnectionState, SocketStatus, UserNotificationSocketMessage, onSocketMessages } from "@iventis/api/src/connection.slice";
import { NotificationResourceType } from "@iventis/domain-model/model/notificationResourceType";
import { SocketEvent } from "@iventis/types/loading.types";
import { filter } from "rxjs/operators";
import { useEffect, useRef, useState } from "react";
import { isNotificationInProgress, UserNotificationUnion } from "@iventis/notifications";
import { useSelector } from "react-redux";
import { Subject } from "rxjs";

const getNotificationsQueryKey = (projectId: string) => `notifications-${projectId}`;
const getNotificationsUnreadCountQueryKey = (projectId: string) => `notifications-unread-count-${projectId}`;

/** Between each time we check for a new notification manually when web sockets are not working */
const TIME_BETWEEN_CHECKING_FOR_NEW_NOTIFICATIONS = 10000;

/** Only attempt to get the same notification for 20 mins */
const MAX_ATTEMPTS_TO_FETCH_NOTIFICATIONS = 1200000 / TIME_BETWEEN_CHECKING_FOR_NEW_NOTIFICATIONS;

/** Gets all the notifications for the given user and given project and listens to any inbound websocket events for user notifications */
export const useNotifications = (
    userId: string,
    projectId: string | undefined,
    listOpen: boolean,
    isGettingNotificationsAllowed: boolean,
    setNotificationListVisibility: (open: boolean) => void,
    onIncomingNotification: (notification: UserNotificationUnion) => void,
    onIncomingNotificationWithNoWebSockets: (notification: UserNotificationUnion[]) => void
) => {
    const queryClient = useQueryClient();
    const [, setLocalNotifications] = useState<UserNotificationUnion[]>([]);

    const websocketStatus = useSelector(
        (state: { connectionReducer: ConnectionState }) => state.connectionReducer.connectionStatus,
        (prev, next) => prev === next
    );

    const $updatedNotifications = useRef(new Subject<UserNotificationUnion[]>());
    const localInProgressNotifications = useRef<{ notificationId: string; attempts: number }[]>([]);

    /**
     * When web sockets are not working and we create a local notification, every 10 seconds we will check if the notification is completed.
     */
    useEffect(() => {
        const updateNotificationSubscription = $updatedNotifications.current.subscribe(async (notifications) => {
            // Find the notifications which have been created locally and are finished
            const finishedNotifications = notifications.filter((notification) => {
                const isLocalNotification = localInProgressNotifications.current.some(({ notificationId }) => notificationId === notification.id);
                return isLocalNotification ? !isNotificationInProgress(notification) : false;
            });

            // Remove all finished notifications from the local notifications
            if (finishedNotifications.length > 0) {
                setNotificationListVisibility(true);
                if (websocketStatus === SocketStatus.ERRORED || websocketStatus === SocketStatus.CLOSED) {
                    onIncomingNotificationWithNoWebSockets(finishedNotifications);
                }
                localInProgressNotifications.current = localInProgressNotifications.current.filter(
                    ({ notificationId }) => !finishedNotifications.some((finishedNotification) => finishedNotification.id === notificationId)
                );
            }

            // Check if any notifications have exceeded the maximum attempts
            localInProgressNotifications.current = localInProgressNotifications.current.reduce<{ notificationId: string; attempts: number }[]>((notifications, notification) => {
                notification.attempts += 1;
                if (notification.attempts < MAX_ATTEMPTS_TO_FETCH_NOTIFICATIONS) {
                    notifications.push(notification);
                }
                return notifications;
            }, []);

            // If the notification is not completed, then we need to keep checking after a wait
            if (localInProgressNotifications.current.length > 0) {
                setTimeout(async () => {
                    await invalidateQueries();
                }, TIME_BETWEEN_CHECKING_FOR_NEW_NOTIFICATIONS);
            }
        });
        return () => {
            updateNotificationSubscription.unsubscribe();
        };
    }, []);

    // All notifications
    const { data: notifications, isInitialLoading } = useQuery({
        queryKey: [getNotificationsQueryKey(projectId)],
        queryFn: async () => {
            const { data: notifications } = await notificationsApi.get<UserNotificationUnion[]>(`/users/${userId}/notifications`);
            const combinedNotifications = notifications;
            setLocalNotifications((localNotifications) => {
                // Ensure that there are no duplicates between the local notifications and the notifications from the server
                const filteredLocalNotifications = localNotifications.filter(
                    (localNotification) => !notifications.some((notification) => notification.id === localNotification.id)
                );
                combinedNotifications.push(...filteredLocalNotifications);
                return filteredLocalNotifications;
            });
            const updatedNotifications = combinedNotifications?.sort((a, b) => new Date(b.lastUpdatedAt).getTime() - new Date(a.lastUpdatedAt).getTime());
            $updatedNotifications.current.next(updatedNotifications);
            return updatedNotifications;
        },
        keepPreviousData: true,
        // If web sockets are not working then fetch notifications even when list is closed
        enabled: (listOpen || websocketStatus === SocketStatus.ERRORED) && projectId != null && isGettingNotificationsAllowed,
        retry: false,
    });

    // Notifications unread count
    const { data: notificationsUnread } = useQuery({
        queryKey: [getNotificationsUnreadCountQueryKey(projectId)],
        queryFn: async () => {
            const { data: notificationsUnread } = await notificationsApi.get<number>(`/users/${userId}/notifications/count`);
            return notificationsUnread;
        },
        enabled: projectId != null && isGettingNotificationsAllowed,
        retry: false,
    });

    // Invalidate the notifications and unread count queries
    const invalidateQueries = async () =>
        Promise.all([
            queryClient.invalidateQueries({ queryKey: [getNotificationsQueryKey(projectId)] }),
            queryClient.invalidateQueries({ queryKey: [getNotificationsUnreadCountQueryKey(projectId)] }),
        ]);

    // Update the read status of a user notification
    const { mutateAsync: registerNotificationAsRead } = useMutation({
        mutationFn: async (notificationId: string) => {
            await notificationsApi.patch(`/users/${userId}/notifications/${notificationId}`);
        },
        onSuccess: invalidateQueries,
    });

    const createLocalNotification = (notification: UserNotificationUnion) => {
        setLocalNotifications((n) => [notification, ...n]);
        let alreadyExists = false;
        queryClient.setQueryData<UserNotificationUnion[]>([getNotificationsQueryKey(projectId)], (existingNotifications) => {
            if (existingNotifications != null) {
                const newList = [...existingNotifications];
                const index = newList.findIndex((n) => n.id === notification.id);
                alreadyExists = index !== -1;
                // If the local notification already exists, update it
                if (alreadyExists) {
                    newList[index] = notification;
                    return newList;
                }
                // Else add it to the top of the list
                newList.unshift(notification);
                return newList;
            }
            return existingNotifications;
        });
        // Update the unread count by one
        queryClient.setQueryData<number>([getNotificationsUnreadCountQueryKey(projectId)], (count) => {
            if (alreadyExists) {
                return count;
            }
            if (count == null) {
                return 1;
            }
            return count + 1;
        });

        // If web sockets are not working then we need to manually check for new notifications
        if (websocketStatus === SocketStatus.ERRORED) {
            localInProgressNotifications.current = [...localInProgressNotifications.current, { notificationId: notification.id, attempts: 0 }];
            if (localInProgressNotifications.current.length === 1) {
                invalidateQueries();
            }
        }

        // When a notification is added, then open the notification list
        setNotificationListVisibility(true);
    };

    // Listen to user notification events
    useEffect(() => {
        const messageStream = onSocketMessages(SocketEvent.USER_NOTIFICATION);
        // Ensure the notification is for the current project
        const subscription = messageStream.pipe(filter<UserNotificationSocketMessage>((msg) => projectId === msg?.message?.projectId)).subscribe(async (msg) => {
            // Layers import/copy is handled elsewehere
            if (msg.message.resourceType === NotificationResourceType.LayersImport || msg.message.resourceType === NotificationResourceType.LayersCopy) {
                return;
            }
            // Invalidate the queries
            await invalidateQueries();
            onIncomingNotification(msg.message);
        });
        return () => {
            subscription.unsubscribe();
        };
    }, []);

    return {
        notifications,
        isLoadingList: isInitialLoading,
        notificationsUnread,
        registerNotificationAsRead,
        refreshNotifications: invalidateQueries,
        createLocalNotification,
    } as const;
};
