export function createOrReplaceStyleElement(id: string, innerHTML: string) {
    let element: HTMLStyleElement;
    const existing = document.getElementById(id) as HTMLStyleElement;
    if (existing) {
        element = existing;
    } else {
        element = document.createElement("style");
        element.id = id;
        document.head.appendChild(element);
    }

    element.innerHTML = innerHTML;
}
