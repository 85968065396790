import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";
import React, { FunctionComponent } from "react";
import { IconToastProps, ButtonToastProps, TitleToastProps } from "./toast.types";
import { ToastButton, ToastContainer, TitleToastContainer } from "./toast.styles";

export enum ToastType {
    BASIC,
    BUTTON,
    TITLE,
}

// Given a ToastType return a toast component
export const getToastComponent = (type: ToastType) => {
    switch (type) {
        case ToastType.BASIC:
            return IconToastComponent;
        case ToastType.BUTTON:
            return ButtonToastComponent;
        case ToastType.TITLE:
            return TitleToast;
        default:
            return IconToastComponent;
    }
};

const TemplateToast: FunctionComponent<IconToastProps> = ({ icon, message }) => (
    <>
        {icon && <FontAwesomeIcon icon={icon} style={{ marginRight: "15px" }} />}
        {message}
    </>
);

// Displays a icon with a message
const IconToastComponent: FunctionComponent<IconToastProps> = ({ icon, message, testId }) => (
    <ToastContainer data-cy={testId}>
        <TemplateToast icon={icon} message={message} />
    </ToastContainer>
);

// Displays an icon with a message and a button
const ButtonToastComponent: FunctionComponent<ButtonToastProps> = ({ icon, message, buttonLabel, onClick, testId }) => (
    <ToastContainer data-cy={testId}>
        <TemplateToast icon={icon} message={message} />
        <ToastButton>
            <Button onClick={onClick} className="toast-button">
                {buttonLabel}
            </Button>
        </ToastButton>
    </ToastContainer>
);

const TitleToast: FunctionComponent<TitleToastProps> = ({ icon, title, message, testId }) => (
    <TitleToastContainer data-cy={testId}>
        <div className="icon-and-title">
            <TemplateToast icon={icon} message={title} />
        </div>
        <span className="toast-text">{message}</span>
    </TitleToastContainer>
);
