import { ExportStatus } from "@iventis/domain-model/model/exportStatus";
import { Body3, styled } from "@iventis/styles";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { Content } from "@iventis/translations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export type NotificationStatus = "in-progress" | "completed" | "failed";

const notificationStatusMap: { [Key in ExportStatus | NotificationStatus]: NotificationStatus } = {
    [ExportStatus.Requested]: "in-progress",
    [ExportStatus.Started]: "in-progress",
    [ExportStatus.Rendered]: "in-progress",
    [ExportStatus.Completed]: "completed",
    [ExportStatus.Failed]: "failed",
    "in-progress": "in-progress",
    completed: "completed",
    failed: "failed",
};

export const NotificationStatusComponent = ({ status, notificationId }: { status: ExportStatus | NotificationStatus; notificationId: string }) => {
    const translate = useIventisTranslate();
    switch (notificationStatusMap[status]) {
        case "completed":
            return (
                <CompletedStatus data-testid={`${notificationId}-completed-notification`}>
                    <FontAwesomeIcon size="lg" icon="circle-check" />
                </CompletedStatus>
            );
        case "failed":
            return (
                <FailedStatus>
                    <FontAwesomeIcon size="lg" icon="circle-xmark" />
                </FailedStatus>
            );
        default:
            return <InProgressStatus data-testid="notification-in-progress">{translate(Content.notifications.planExport.inProgressStatus)}</InProgressStatus>;
    }
};

const CompletedStatus = styled(Body3)`
    color: ${({ theme }) => theme.toastColours.success};
    font-weight: 500;
`;

const InProgressStatus = styled(Body3)`
    color: ${({ theme }) => theme.typographyColors.subdued};
    font-weight: 500;
    line-height: 18px;
`;

const FailedStatus = styled(Body3)`
    color: ${({ theme }) => theme.typographyColors.linkOnError};
    font-weight: 500;
`;
