import { useEffect, useRef, useState } from "react";

interface ResultBox<T> {
    v: T;
}

export function useConstant<T>(fn: () => T): T {
    const ref = useRef<ResultBox<T>>();

    if (!ref.current) {
        ref.current = { v: fn() };
    }

    return ref.current.v;
}

/**
 * Accepts an isReady param that will only set the const once isReady is true.
 */
export function useDelayedConstant<T>(fn: () => T, isReady: boolean): { value: T | null; isLoading: boolean } {
    const [value, setValue] = useState<T | null>(isReady ? fn() : null);
    const [isLoading, setIsLoading] = useState(!isReady);

    useEffect(() => {
        if (value == null && isReady) {
            setValue(fn());
            setIsLoading(false);
        }
    }, [isReady]);

    return { value, isLoading };
}
