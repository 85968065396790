import { ProjectBillingProvider } from "@iventis/domain-model/model/projectBillingProvider";
import { ProjectStatus } from "@iventis/domain-model/model/projectStatus";
import parseISO from "date-fns/parseISO";
import { ProjectSubscription } from "@iventis/domain-model/model/projectSubscription";
import { stringToBool } from "@iventis/utilities";
import { SubscriptionPlanTier } from "./subscription-plan-details";

const SUBSCRIPTION_UPDATED_QUERY_NAME = "subscriptionUpdated";

/**
 * Gets the discount to be applied to the price of the projects subscription.
 * @param users Number of users
 * @returns Discount percentage (e.g. 8%)
 */
export const getDiscountsPerUser = (users: number, maximumAllowed: number, tier: SubscriptionPlanTier) => {
    if (![SubscriptionPlanTier.Professional, SubscriptionPlanTier.Premium].includes(tier)) {
        return 0;
    }
    if (users > maximumAllowed) {
        return 0;
    }
    if (users < 5) {
        return 0;
    }
    if (users < 10) {
        return 8;
    }
    if (users < 20) {
        return 10;
    }
    if (users < 50) {
        return 20;
    }
    if (users >= 50) {
        return 40;
    }
    throw new Error("Invalid number of users");
};

/** Returns the maximum users a subscription can have based on the plan's tier */
export const getMaximumUsersAllowedForTier = (tier: string) => {
    switch (tier?.toLowerCase()) {
        case SubscriptionPlanTier.Professional:
        case SubscriptionPlanTier.Premium:
            return 5;
        default:
            return Infinity;
    }
};

export const readSubscriptionFromUrl = <TParams extends [] | [ProjectSubscription]>(
    ...[previousProjectSubscription]: TParams
): TParams extends [ProjectSubscription] ? ProjectSubscription : UrlProjectSubscription => {
    const searchParams = new URLSearchParams(window.location.search);
    const newProjectSubscription = { ...previousProjectSubscription };
    searchParams.forEach((value, key) => {
        if (key in mockCompleteSubscription) {
            const valueType = typeof mockCompleteSubscription[key];
            // Assign the value
            switch (valueType) {
                case "string":
                    newProjectSubscription[key] = value;
                    break;
                case "number":
                    newProjectSubscription[key] = Number(value);
                    break;
                case "boolean":
                    newProjectSubscription[key] = stringToBool(value);
                    break;
                case "object":
                    // The only object type we support in the url is Dates
                    newProjectSubscription[key] = parseISO(value);
                    break;
                default:
                    throw new Error("Value type not suitable for URL");
            }
        }
    });
    return newProjectSubscription;
};

const mockBasicSubscription: ProjectSubscription = {
    id: "",
    billingProvider: ProjectBillingProvider.Stripe,
    subscriptionPlanId: "",
    subscriptionPlanPriceId: "",
    status: ProjectStatus.Trial,
    projectId: "",
    cancelAtCommittedUntil: false,
};

export const mockCompleteSubscription: Required<ProjectSubscription> = {
    ...mockBasicSubscription,
    renewalDate: new Date(),
    maximumUsers: 1,
    committedUntil: new Date(),
};

export type UrlProjectSubscription = Pick<ProjectSubscription, "maximumUsers" | "subscriptionPlanId" | "subscriptionPlanPriceId" | "status">;

export const mockUrlProjectSubscription: UrlProjectSubscription = {
    subscriptionPlanId: "",
    subscriptionPlanPriceId: "",
    maximumUsers: 0,
    status: ProjectStatus.Active,
};

/** Returns true if the latest project subscription is stored in the url */
export const isRemoteSubscriptionInUrl = () => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get(SUBSCRIPTION_UPDATED_QUERY_NAME) !== "false" && Object.keys(mockUrlProjectSubscription).every((key) => searchParams.has(key))) {
        return true;
    }
    return false;
};

export const isUrlSubscriptionEqual = (a: UrlProjectSubscription, b: UrlProjectSubscription) => Object.keys(mockUrlProjectSubscription).every((key) => a[key] === b[key]);
