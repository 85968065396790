import { Subject } from "rxjs";
import { filter } from "rxjs/operators";

export interface EventMessage {
    type: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload?: any;
}

export class EventStream<T extends EventMessage> {
    public subject = new Subject<T>();

    listen(events?: T["type"][]) {
        if (events != null) {
            return this.subject.pipe(filter((ms) => events.includes(ms.type)));
        }
        return this.subject;
    }

    next(event: T) {
        this.subject.next(event);
    }

    destroy() {
        this.subject.complete();
    }

    restart() {
        this.subject = new Subject<T>();
    }
}
