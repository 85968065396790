import { css, Theme } from "@emotion/react";
import "./style.types";

/**
 * Embedd this into a scrollable component with a light background
 * (Supports dark mode)
 * */
export const lightScrollbarMixin = (theme: Theme) => css`
    ::-webkit-scrollbar {
        width: 16px;
        height: 16px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
        display: none;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: ${theme.scrollbar.thumbOne};
        border: 4px solid transparent;
        border-radius: 16px;
        background-clip: content-box;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: ${theme.scrollbar.thumbOneHover};
        border: 3px solid transparent;
        border-radius: 16px;
        background-clip: content-box;
    }
`;

/**
 * Embedd this into a scrollable component with a dark background
 * (Supports dark mode)
 * */
export const darkScrollbarMixin = (theme: Theme) => css`
    ::-webkit-scrollbar {
        width: 16px;
        height: 16px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
        display: none;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: ${theme.scrollbar.thumbTwo};
        border: 4px solid transparent;
        border-radius: 16px;
        background-clip: content-box;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: ${theme.scrollbar.thumbTwoHover};
        border: 3px solid transparent;
        border-radius: 16px;
        background-clip: content-box;
    }
`;
