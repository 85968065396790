import { css } from "@emotion/react";

export const pulsateCss = css`
    // Pulsating animation
    animation: pulsate 4s cubic-bezier(0.5, 0.75, 0.5, 0.25);
    animation-iteration-count: infinite;

    @keyframes pulsate {
        0% {
            opacity: 0.2;
        }
        50% {
            opacity: 1;
        }
        100% {
            opacity: 0.2;
        }
    }
`;
