import { createContext, useContext } from "react";
import { SubscriptionPlanFunctionality } from "@iventis/domain-model/model/subscriptionPlanFunctionality";
import { AnalysisType } from "@iventis/domain-model/model/analysisType";

interface FunctionalityContextInterface extends SubscriptionPlanFunctionality {
    open?: (type: keyof SubscriptionPlanFunctionality) => void;
}

export const FunctionalityContext = createContext<FunctionalityContextInterface>({
    sharedLinks: false,
    sharedWorkspace: false,
    iconAndModelUploads: false,
    layerCopying: false,
    importingLayers: false,
    pdfExports: false,
    layer3DTypes: false,
    customLogo: false,
    siteMaps: false,
    customLayer: false,
    sso: false,
    analysisTypes: [],
    attributeDataExport: false,
    listItemProperties: false,
    digitalTwin: false,
    unlinkingLayers: false,
    imagesOnModels: false,
    customColourOnModels: false,
});

export const allFunctionality: FunctionalityContextInterface = {
    sharedLinks: true,
    sharedWorkspace: true,
    iconAndModelUploads: true,
    layerCopying: true,
    importingLayers: true,
    pdfExports: true,
    layer3DTypes: true,
    customLogo: true,
    siteMaps: true,
    customLayer: true,
    sso: true,
    analysisTypes: Object.values(AnalysisType),
    attributeDataExport: true,
    listItemProperties: true,
    digitalTwin: true,
    unlinkingLayers: true,
    imagesOnModels: true,
    customColourOnModels: true,
};

export const useFunctionality = () => useContext(FunctionalityContext);
