/* eslint-disable react/jsx-props-no-spreading */
import { LoadingComponent } from "@iventis/components";
import { LoadableComponent } from "@loadable/component";
import React from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import { useCheckUserLoggedIn } from "@iventis/permissions/src/use-check-user-logged-in";

// A route accessible by any user only if they are not authenticated
const PublicRouteWithRedirect: React.FC<{
    component: React.FC | LoadableComponent<unknown>;
    path: string;
    exact?: boolean;
}> = ({ component: Component, ...rest }) => {
    const [isAuthenticated, loadingUser] = useCheckUserLoggedIn();
    const { search } = useLocation();

    if (loadingUser) {
        return <LoadingComponent />;
    }
    const urlParams = new URLSearchParams(search);
    const redirectUrl = urlParams.get("redirectUrl");
    if (isAuthenticated && redirectUrl != null) {
        window.location.replace(redirectUrl); // Using this instead of the redirect below to avoid odd state. it was causing the map not to load sometimes.
    }
    return <Route {...rest} component={(props) => (isAuthenticated ? <Redirect to="/" /> : <Component {...props} />)} />;
};

export default PublicRouteWithRedirect;
