import { styled } from "@iventis/styles";
import { Theme } from "@emotion/react";
import { InputBase, MenuItem } from "@mui/material";
import { borderRadius, fontSizes } from "@iventis/styles/src/atomic-rules";

/** Custom input element adding border radius, custom background colours using our theme and custom padding */
export const StyledItemSelect = styled(InputBase)<{ $borderColour?: string; textColour?: string; $verticalPadding?: string }>`
    .MuiInputBase-input {
        border-radius: ${borderRadius.standard};
        position: relative;
        background-color: ${({ theme }: { theme: Theme }) => theme.typographyColors.blank};
        border: 1px solid ${({ $borderColour }) => $borderColour};
        color: ${({ theme, textColour }: { theme: Theme; textColour: string }) => textColour || theme.typographyColors.core};
        font-size: ${fontSizes.small};
        padding: ${({ $verticalPadding }) => `${$verticalPadding} 26px ${$verticalPadding} 12px`};
        width: 100%;
        display: flex;
        align-items: center;
    }
    .MuiInputBase-input:focus {
        border-radius: ${borderRadius.input};
        background-color: ${({ theme }: { theme: Theme }) => theme.typographyColors.blank};
    }
`;

export const StyledMenuItem = styled(MenuItem)`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: ${fontSizes.small};
    ${(props) =>
        props.selected &&
        `
            font-weight: 500;
        `}
    :hover {
        background-color: ${({ theme }: { theme: Theme }) => theme.shades.four} !important;
    }
`;
