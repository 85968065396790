import { PayloadAction } from "@reduxjs/toolkit";

// TODO: Replace with domain model events
export enum SocketEvent {
    MAP_CREATED = "MAP_CREATED",
    LAYER_CREATED = "LAYER_CREATED",
    MAP_OBJECT_CREATED = "MAP_OBJECT_CREATED",
    MAP_LAYER_UPDATED = "MAP_LAYER_UPDATED",
    NODE_CREATED = "NODE_CREATED",
    NODE_UPDATED = "NODE_UPDATED",
    NODE_DELETED = "NODE_DELETED",
    RESOURCE_OPENED = "RESOURCE_OPENED",
    RESOURCE_CLOSED = "RESOURCE_CLOSED",
    LAYER_STYLE_UPDATED = "LAYER_STYLE_UPDATED",
    SCHEDULE_VERSION_COPIED = "SCHEDULE_VERSION_COPIED",
    EXPORT = "EXPORT",
    USER_NOTIFICATION = "USER_NOTIFICATION",
    LAYER_IMPORT = "LAYER_IMPORT",
    LAYER_COPY = "LAYER_COPY",
}

export enum LoadingEvent {
    GET_MAP = "GET_MAP",
    GET_BASEMAP = "GET_BASEMAP",
    GET_BACKGROUNDS = "GET_BACKGROUNDS",
    GET_NODE_TREE = "GET_NODE_TREE",
    GET_NODE_CONTENTS = "GET_NODE_CONTENTS",
    GET_PROJECTS = "GET_PROJECTS",
    GET_THUMBNAILS = "GET_THUMBNAILS",
    EXPAND_NODE = "EXPAND_NODE",
}

export type WaitingForEvent = {
    eventName: SocketEvent | LoadingEvent;
    id: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type PayloadActionRequests<T, A = any> = PayloadAction<T, string, { requestId: string; arg?: A }>;
