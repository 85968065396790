import { Header3 } from "@iventis/styles/src/components/texts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import React, { FunctionComponent } from "react";
import { styled, media } from "@iventis/styles";

import { formGap, inlineTextIconMargin, minimumConfirmationDialogWidth, minimumConfirmationDialogWidthSmallDevices } from "@iventis/styles/src/atomic-rules";
import { FormButtonsComponent } from "./form-buttons";
import { CustomDialog } from "./custom-dialog";

interface ConfirmationPopupRequiredProps {
    show: boolean;
    handleConfirm: () => void;
    handleCancel: () => void;
    title: string;
    confirmText: string;
    cancelText: string;
}

export interface ConfirmationPopupProps extends ConfirmationPopupRequiredProps {
    titleIcon?: IconProp;
    message?: string;
    isSubmitting?: boolean;
    dataCy?: string;
    children?: React.ReactNode;
}

export const ConfirmationDialogComponent: FunctionComponent<ConfirmationPopupProps> = ({
    show,
    handleConfirm,
    handleCancel,
    title,
    titleIcon,
    message,
    confirmText,
    cancelText,
    isSubmitting,
    dataCy,
    children,
}) => (
    <CustomDialog open={show} onClose={handleCancel}>
        <DialogContent>
            <EndContainer>
                <Header3>
                    {titleIcon && <FontAwesomeIcon style={{ marginRight: inlineTextIconMargin }} icon={titleIcon} />}
                    {title}
                </Header3>
            </EndContainer>
            {message && <MessageContainer>{message}</MessageContainer>}
            {children && <ChildrenContainer>{children}</ChildrenContainer>}
            <EndContainer>
                <FormButtonsComponent
                    dataCy={dataCy}
                    secondaryButtons={[{ buttonText: cancelText, onButtonPressed: handleCancel }]}
                    handleSubmit={handleConfirm}
                    submitButtonText={confirmText}
                    isSubmitting={!!isSubmitting}
                />
            </EndContainer>
        </DialogContent>
    </CustomDialog>
);

const DialogContent = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    min-width: ${minimumConfirmationDialogWidthSmallDevices};
    ${media.small} {
        min-width: ${minimumConfirmationDialogWidth};
    }
`;

const EndContainer = styled.div`
    flex-shrink: 1;
`;

const ChildrenContainer = styled.div`
    margin: 20px 0px;
    display: flex;
    flex-direction: column;
    gap: ${formGap};
`;

const MessageContainer = styled.div`
    flex-grow: 1;
    flex-shrink: 0;
    margin: 20px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;
