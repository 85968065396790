import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FunctionComponent } from "react";
import { css } from "@emotion/react";
import styled from "../transient-styled";
import { fontSizes } from "../atomic-rules";

interface UncheckedOptions {
    borderStyle: string;
    backgroundColour: string;
    size: number;
}
interface CheckedOptions extends UncheckedOptions {
    tickColour: string;
    tickWeight?: "far" | "fas";
}

export const CheckedCheckBox: FunctionComponent<CheckedOptions> = (styleBoxOptions) => (
    <StyledBox options={styleBoxOptions}>
        <FontAwesomeIcon color={styleBoxOptions.tickColour} icon={[styleBoxOptions.tickWeight || "far", "check"]} />
    </StyledBox>
);

export const UncheckedCheckBox: FunctionComponent<UncheckedOptions> = (styleBoxOptions = { borderStyle: "1px solid #757575", backgroundColour: "white", size: 15 }) => (
    <StyledBox options={styleBoxOptions} />
);

const StyledBox = styled.div<{ options: UncheckedOptions }>`
    width: ${({ options }) => `${options.size}px`};
    height: ${({ options }) => `${options.size}px`};
    border: ${(props) => props.options.borderStyle};
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${fontSizes.small};
    background-color: ${(props) => props.options.backgroundColour};
`;

export const inputCheckboxCSS = css`
    display: flex;
    gap: 10px;
`;
