export enum ReplayTag {
    PROJECT_NAME = "project.name",
    PROJECT_ID = "project.id",
    SIGN_UP = "sign-up",
    USER_NAME = "user.name",
    USER_ID = "user.id",
    USER_EMAIL = "user.email",
    /** A user with an Iventis email  */
    IVENTIS_EMPLOYEE = "user.iventis",
    /** User using an external readonly link */
    EXTERNAL_USER = "user.external",
    SCREEN_HEIGHT = "screen.height",
    SCREEN_WIDTH = "screen.width",
    TOUCH_DEVICE = "screen.touch-device",
}

export type TagValue = number | string | boolean | symbol | null | undefined;
