/* eslint-disable @typescript-eslint/no-explicit-any */
import _styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";

const tags = "a|abbr|address|area|article|aside|audio|b|base|bdi|bdo|big|blockquote|body|br|button|canvas|caption|cite|code|col|colgroup|data|datalist|dd|del|details|dfn|dialog|div|dl|dt|em|embed|fieldset|figcaption|figure|footer|form|h1|h2|h3|h4|h5|h6|head|header|hgroup|hr|html|i|iframe|img|input|ins|kbd|keygen|label|legend|li|link|main|map|mark|marquee|menu|menuitem|meta|meter|nav|noscript|object|ol|optgroup|option|output|p|param|picture|pre|progress|q|rp|rt|ruby|s|samp|script|section|select|small|source|span|strong|style|sub|summary|sup|table|tbody|td|textarea|tfoot|th|thead|time|title|tr|track|u|ul|var|video|wbr|circle|clipPath|defs|ellipse|foreignObject|g|image|line|linearGradient|mask|path|pattern|polygon|polyline|radialGradient|rect|stop|svg|text|tspan".split(
    "|"
);

const styled = ((component: any, config: any) => {
    // eslint-disable-next-line no-param-reassign
    config = {
        shouldForwardProp: (prop: string) => {
            if (tags.includes(component)) {
                return isPropValid(prop) && !prop.startsWith("$");
            }
            return !prop.startsWith("$");
        },
        ...config,
    };
    return _styled(component, config);
}) as typeof _styled;

tags.forEach((tag: string) => {
    (styled as any)[tag] = styled(tag as any);
});

export default styled;
