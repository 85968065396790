import { MutableRefObject, useEffect, useRef } from "react";

/**
 * Use when you need to update a ref each time a value changes
 * Useful to use inside subscription
 *
 * @param value The value to update the ref with
 * @param deps The dependencies inside the useEffect which if changed will update the ref value
 */
export const useRefEffectValue = <TValue>(value: TValue, deps: unknown[] = []) => {
    const ref = useRef(value);

    useEffect(() => {
        ref.current = value;
    }, [value, ...deps]);

    return ref;
};

/**
 * Use when you need to call a function to update the ref value
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useRefEffectCallback = <TValue, TDeps extends any[]>(value: TValue, callback: (value: TValue) => TValue, ...deps: TDeps): MutableRefObject<TValue> => {
    const ref = useRef(value);

    useEffect(() => {
        ref.current = callback(ref.current);
    }, deps);

    return ref;
};
