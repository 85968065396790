/* eslint-disable camelcase */
export const schedule2 = {
    delete_schedule_version: {
        title: "Delete Discipline Schedule",
        message: "Deleting {{ scheduleVersionName }} will also delete it's events, do you wish to continue?",
    },
    show_schedule: "Show Schedule",
    delete_schedule: {
        title: "Delete Competition Schedule",
        message: "Deleting {{ scheduleVersionName }} will also delete it's discipline schedules and events, do you wish to continue?",
    },
    toasts: {
        scheduled_items_start_end_times_affected: "Some scheduled items start and end times may have been affected",
    },
    other: {
        no_items_based_on_selection: {
            Discipline: "No items to show based on discipline selection.",
            Day: "No items to show based on venue selection.",
        },
    },
    errorMessages: {
        scheduled_item_must_be_within_session: "Scheduled item must be within it's session's start and end times",
        must_end_after_start_time: "Event must end after start time",
    },
    session_form: {
        medal_header: "Medals and Ceremonies",
        medal_ceremony: "Medal ceremony",
        includes_medal_ceremony: "Includes medal ceremony",
    },
    validation: {
        startTimeValid: "The start time should have seconds, minutes and hours",
        startTimeWithinParent: "The start time should be after the parent start time",
        endTimeBeforeStartTime: "The start time should be before the end time",
        endTimeValid: "The end time should have seconds, minutes and hours",
        endTimeWithinParent: "The end time should be before the parent end time",
    },
    activity: {
        titleDeleteUnsaved: "Delete unsaved row",
        descriptionDeleteUnsaved: "Are you sure you wish to discard this new row?",
    },
    divisions: "Divisions",
    upload_event_blocks: {
        title: "Upload calendar contents",
        calendar_level: "Calendar level",
        delete_existing: "Delete existing items",
        delete_existing_confirmation_title: "Delete existing items",
        delete_existing_confirmation_description:
            "You have selected 'Delete existing items'. This will delete all events in the calendar under your selected schedule version, and replace them with your provided CSV values. This cannot be undone. Do you wish to continue?",
        csv_validation_errors_subtitle: "CSV validation errors",
    },
    exports: {
        title: "Export options",
        confirm: "Confirm",
        cancel: "Cancel",
        message: "Please choose an option to export",
        downloadTitle: "Download",
        downloadDescription: "ODF export will be downloaded to your computer",
        publishedDescription: "ODF export will be published to {{ exportDestinationName }}",
        notAvailable: "Not available",
    },
    key: {
        title: "Key to schedule block colours",
    },
    data_fields: {
        event_unit_description: "Event unit",
        event_name: "Event",
        competition_pahse: "Competition phase",
        gender: "Gender",
        medal_type: "Medal type",
        ticketing_code: "Ticketing code",
        ticketed: "Ticketed",
        discipline: "Discipline",
        session_code: "Session code",
        session_style: "Session style",
        session_type: "Session type",
        venue_code: "Venue code",
        Event: "Event",
        Phase: "Phase",
        "Event unit": "Event unit",
        Division: "Division",
        Owner: "Owner",
        multi_selector: {
            all: { Discipline: "All disciplines" },
            none: { Discipline: "No disciplines" },
            x_selected: { Discipline: "{{ count }} disciplines selected" },
        },
        view_by_day_label: {
            Discipline: "Day (Discipline)",
        },
    },
    scheduleDoesntExist: {
        noPermission: "A schedule for this project has not been set up. You will need to ask a user with administrator privileges to do this for you.",
        createTitle: "Configure the schedule for this project",
        createDescription:
            "A schedule has not been set up for this project. Configure the schedule using the settings below. If you are unsure how to configure the schedule, contact support:",
    },
    venueEdit: {
        parentVenue: "Parent Venue",
        venueLocationType: "Venue location type",
        topLevelVenueName: "Top level",
        tryDeleteParent: "Remove parent ID references to this row before deleting",
        tryEditParent: "{{ count }} venues reference this one as their parent. Remove these references before continuing.",
    },
    sidebar: {
        upload: "Upload event blocks",
        deleteListItemMessage: "Are you sure you want to delete all list items?",
        deleteListItemTitle: "Delete list items",
    },
} as const;
