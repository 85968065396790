import { styled } from "@iventis/styles";
import React from "react";

interface SkeletonTextProps {
    height: string;
    width: string;
    linesAmount?: number;
    gap?: string;
}

export const SkeletonLinesComponent = ({ width, height, linesAmount = 1, gap = "0px" }: SkeletonTextProps) => {
    const lines = new Array(linesAmount).fill(true).map((_, index) => index);
    return (
        <SkeltonLines width={width} height={height} gap={gap}>
            {lines.map((line) => (
                <div className="skeleton-line" key={line} />
            ))}
        </SkeltonLines>
    );
};

const SkeltonLines = styled.div<Omit<SkeletonTextProps, "linesAmount">>`
    display: flex;
    flex-direction: column;
    gap: ${({ gap }) => gap};
    overflow: hidden;

    .skeleton-line {
        background-color: ${({ theme }) => theme.shimmer.background};
        width: ${({ width }) => width};
        height: ${({ height }) => height};
    }
`;
