import { useState, useEffect } from "react";

export const getWindowSize = () => {
    const { innerWidth: screenWidth, innerHeight: screenHeight } = window;
    return { screenWidth, screenHeight };
};

export const useWindowSize = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [windowsSize, setWindowsSize] = useState(getWindowSize());
    useEffect(() => {
        const handleResize = () => {
            setWindowsSize(getWindowSize());
        };
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return windowsSize;
};
