import { useEffect, useRef, useState } from "react";

/** Checks if the element the returned ref is attached to is vertically scrollable */
export const useIsElementScrollable = () => {
    const [elementScrollable, setElementScrollable] = useState(false);
    const elementRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        let resizeObserver: ResizeObserver;
        if (elementRef.current != null) {
            resizeObserver = new ResizeObserver(() => {
                const elementHeight = elementRef.current?.clientHeight;
                const elementScrollingHeight = elementRef.current?.scrollHeight;
                if (elementScrollingHeight != null && elementHeight != null) {
                    setElementScrollable(elementScrollingHeight > elementHeight);
                }
            });
            resizeObserver.observe(elementRef.current);
        }
        return () => {
            resizeObserver?.disconnect();
        };
    }, [elementRef.current]);

    return { isElementScrollable: elementScrollable, elementRef };
};

/** Checks if the element the returned ref is attached to is vertically scrollable on render and does not check after element resize */
export const useIsElementScrollableOnRender = () => {
    const [elementScrollable, setElementScrollable] = useState(false);
    const onElementRender = (element: HTMLDivElement) => {
        if (element != null) {
            const elementHeight = element.clientHeight;
            const elementScrollingHeight = element.scrollHeight;
            setElementScrollable(elementScrollingHeight > elementHeight);
        } else {
            setElementScrollable(false);
        }
    };
    return { onElementRender, elementScrollable };
};
