/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { Content } from "./content/typed-content";
import i18n from "./i18n";
import t from "./index";

const { namespaces } = t;

const trans = (arr) => {
    const { t } = useTranslation([...arr]);
    return t;
};

/**
 * This is a function that will find the path (in dot notation as a string) to a given string value within a given object.
 *
 * @param {{contents: string}} obj - The object in which the desired value lies
 * @param {string} val - The desired value to translate
 * @param {string} currentPath? - Optional current path for recursion
 * @return {string} Path to value in dot notation
 *
 * @example
 *
 *     findPath({example: "Test"} "Test")
 */
export const findPath = (object: any, value: string, ignoreWarning = false): string => {
    const path = (obj: any, val: string, currentPath?: string): string => {
        let matchingPath = "";

        if (typeof obj !== "object") {
            return "";
        }

        Object.keys(obj).forEach((key) => {
            if (obj[key] === val) {
                matchingPath = currentPath ? `${currentPath}.${key}` : key;
            } else if (path(obj[key], val)) {
                matchingPath = currentPath ? path(obj[key], val, `${currentPath}.${key}`) : path(obj[key], val, key);
            }
        });

        return matchingPath;
    };
    if (path(object, value).length === 0 && !ignoreWarning) {
        console.warn("Value passed into findPath does not exist inside the passed object: ", value);
        return undefined;
    }
    return path(object, value);
};

/**
 * Get the cache key given the changable properties of the translation.
 *
 * @param {string} prefix - Lang of the translation
 * @param {string} value - The value of the translation
 * @Param {object} options - The additional options for the translation
 * @example see tests for examples
 */
export const getCacheKey = (prefix: string, value: string, options: object): string => {
    let cacheKey = prefix + value;
    if (options != null) {
        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of Object.entries(options)) {
            cacheKey += `${key}:${value}-`;
        }
    }
    return cacheKey;
};

/**
 * This is a hook that returns a function that will translate a given value using react-i18next.
 * Hint: Use typed object from "@iventis/translations/content/typed-content" to find translatable text.
 *
 * @param {string} value - The desired value to translate
 * @param {Object} options? - optional object containing variables to pass into value with {{curly braces}}
 * @param {boolean} ignoreWarning? - Ignores console warning if translation not found
 * @return {string} Translated text
 *
 * @example
 *
 *     translate("Test")
 *     translate("My name is {{name}} and I am {{age}}", {name: "John", age: 40})
 *     translate("Test", undefined, true)
 */
export const useIventisTranslate = () => {
    const t = trans(namespaces);
    const translate = (): UseIventisTranslate => {
        const { language } = i18n;
        const prefix = language ?? "";
        const cache = {};
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return useMemo(() => (value, options, ignoreWarning = false) => {
            const cacheKey = getCacheKey(prefix, value, options);
            if (cacheKey in cache) {
                return cache[cacheKey];
            }
            let result = value;
            const fullPath = findPath(Content, value, ignoreWarning);
            if (fullPath) {
                const splitPath = fullPath.split(".");
                const namespace = splitPath[0];
                splitPath.shift();
                const path = splitPath.join(".");

                // Get the translations. If the translation is the same as the path, then the translation has gone wrong, so return the value
                const tResult = t(`${namespace}:${path}`, options);
                if (tResult !== path) {
                    result = tResult as any;
                }
            }
            cache[cacheKey] = result;
            return result;
        }, [language]);
    };

    return translate();
};

type RemoveWhiteSpace<TString extends string> = TString extends `${" "}${infer Rest}` ? RemoveWhiteSpace<Rest> : TString extends `${infer Rest}${" "}` ? RemoveWhiteSpace<Rest> : TString

type ExtractTranslationParameters<TString extends string, TAccum> = TString extends `${string}${"{{"}${infer P}${"}}"}${infer Rest}` ? Rest extends "" ? TAccum | RemoveWhiteSpace<P> : ExtractTranslationParameters<Rest, TAccum> | RemoveWhiteSpace<P> : never

type IsAny<TVal> = TVal extends never ? true : never;

export type UseIventisTranslate = <TValue extends string>(
    ...params: IsAny<TValue> extends never ?
        ExtractTranslationParameters<TValue, never> extends never ?
        [TValue] | [TValue, undefined, boolean]
        : [TValue, Record<ExtractTranslationParameters<TValue, never>, string | number>]
        : [TValue] | [TValue, undefined, boolean]
) => string;

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
export const basicTranslator: UseIventisTranslate = (text, options?, _?) => {
    let newText = text;
    Object.entries(options ?? {}).forEach(([key, value]) => {
        newText = text.replace(`{{ ${key} }}`, value as string).replace(`{{${key}}}`, value as string) as typeof text;
    });
    return newText;
};
