/* eslint-disable camelcase */
export const common = {
    created_by: "Created by",
    created: "Created",
    filter: "Filter",
    example: {
        test: "My name is {{ name }} and I am {{ age }} years old",
    },
    buttons: {
        cancel: "Cancel",
        save: "Save",
        save_changes: "Save changes",
        next: "Next",
        continue: "Continue",
        close: "Close",
        selectValue: "Select {{ value }}",
        remove: "Remove",
        confirm: "Confirm",
        saveAndFinish: "Save and finish",
        saveAndClose: "Save and close",
        back: "Back",
        copyValue: "Copy value",
        pasteValue: "Paste value",
        delete: "Delete",
        deleting: "Deleting",
        deleteAll: "Delete all",
        undo: "Undo",
        redo: "Redo",
        import: "Import",
        export: "Export",
        add: "Add",
        share: "Share",
        key: "Key",
        create: "Create",
        expand: "Expand",
        collapse: "Collapse",
        select: "Select",
        select_all: "Select all",
        upload: "Upload",
        download: "Download",
        add_another_item: "Add another item",
        move: "Move",
        edit: "Edit",
        edit_name: "Edit name",
        removeImage: "Remove image",
        finish: "Finish",
        click_to_edit: "Click to edit {{ name }}",
        click_to_select: "Click to select {{ name }}",
        click_to_deselect: "Click to deselect {{ name }}",
        see_all: "See all",
        skip: "Skip",
        submit: "Submit",
        support: "Contact support",
        upgrade: "Upgrade",
        createLayer: "Create Layer",
    },
    toasts: {
        creating_resource: "Creating '{{ resource }}'. We'll tell you when it's ready",
        creation_completed: "Creation completed for {{ resource }}",
        resource_complete: "{{ resource }} complete",
    },
    selected: "Selected ({{ selectedNumber }})",
    no_children_available: "No {{ childType }} available",
    "loading...": "Loading...",
    saving: "Saving",
    pending_save: "Pending save",
    saved: "Saved",
    creating: "Creating",
    errorLoading: "Error loading {{ resourceName }}",
    yes: "Yes",
    no: "No",
    none: "None",
    selection: {
        none_selected: "None selected",
        all_selected: "All selected",
        selected: "Selected",
    },
    upload: {
        select_csv: "Select CSV file",
        change_csv: "Change CSV file",
        upload_errors_placeholder: "If there are any problems with your file, they will appear here",
        import_complete: "Import completed successfully",
    },
    sort: {
        most_relevant: "Most relevant",
    },
    settings: "Settings",
    no_results: "No results",
    name: "Name",
    in_development: "[In Development]",
    exports: {
        success_message: "{{ exportName }} has exported successfully. Click the link to download it",
        error_message: "{{ exportName }} has failed to export. Please try again later or contact Iventis support.",
        start_message: "{{ exportName }} export has started, we'll let you know when it's ready",
    },
    all: "All",
    copied_to_clipboard: "Copied to clipboard",
    click_for_details: {
        whole: "Click here for more details",
        click: "Click",
        here: "here",
        for_more: "for more details",
    },
    delete_confirmation_message: "Are you sure you want to delete {{ entity }}",
    unavailable: "Unavailable",
    link: "Link",
    powered_by: "Powered By",
    boolean: {
        true: "True",
        false: "False",
    },
    delete: "Delete",
    learnMore: "Learn more",
} as const;
