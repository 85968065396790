export const checkForKeyboardUser = (): void => {
    document.body.addEventListener("keydown", handleFirstTab);
};

const handleMouseDown = (): void => {
    document.body.classList.remove("user-is-tabbing");
    document.body.addEventListener("keydown", handleFirstTab);
    document.body.removeEventListener("mousedown", handleMouseDown);
};

const handleFirstTab = (e: KeyboardEvent): void => {
    if (e.key === "Tab") {
        document.body.classList.add("user-is-tabbing");
        document.body.removeEventListener("keydown", handleFirstTab);
        document.body.addEventListener("mousedown", handleMouseDown);
    }
};
