import { Theme } from "@emotion/react";
import React, { FunctionComponent, MouseEventHandler } from "react";
import { styled } from "@iventis/styles";
import { useTheme } from "@mui/material";

export const DisabledOverlay: FunctionComponent<{
    opacity?: number;
    backgroundColour?: string;
    className?: string;
    onMouseOver?: MouseEventHandler<HTMLDivElement>;
    onMouseOut?: MouseEventHandler<HTMLDivElement>;
    testId?: string;
}> = ({ opacity = 0.5, backgroundColour, className, onMouseOut, onMouseOver, testId = "disabled-overlay" }) => {
    const theme = useTheme<Theme>();

    return (
        <StyledDiv
            data-testid={testId}
            data-cy={testId}
            backgroundColour={backgroundColour ?? theme.shades.five}
            opacity={opacity}
            className={className}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
        />
    );
};

const StyledDiv = styled.div<{ backgroundColour: string; opacity: number }>`
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 500;

    background-color: ${({ backgroundColour }) => backgroundColour};
    opacity: ${({ opacity }) => opacity};
`;
