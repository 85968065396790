import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { styled } from "@iventis/styles";
import React from "react";

interface ProgressSpinnerProps {
    height: string;
}

export const ProgressSpinnerComponent = ({ height }: ProgressSpinnerProps) => (
    <ProgressSpinnerContainer height={height}>
        <FontAwesomeIcon icon={["fas", "spinner"]} spin className="progress-icon" color="pink" />
    </ProgressSpinnerContainer>
);

const ProgressSpinnerContainer = styled.div<{ height: string }>`
    height: ${({ height }) => height};
    width: ${({ height }) => height};
    display: flex;
    justify-content: center;
    align-items: center;
    .progress-icon {
        width: 100%;
        height: 100%;
        color: ${({ theme }) => theme.primaryColors.focus};
    }
`;
