import React, { FunctionComponent, useState } from "react";
import { styled } from "@iventis/styles";
import { useTheme } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import { InteractiveElement } from "@iventis/styles/src/components";
import { Theme } from "@emotion/react";

interface AppProps {
    selected?: boolean;
    iconName: IconName;
    iconColour?: string;
    hoverColour?: string;
    selectedColour?: string;
    iconWidth?: string;
    iconHeight?: string;
    text?: string;
    textColour?: string;
    textSize?: string;
    textWeight?: string;
    flexGap?: string;
    orientation?: "column" | "row";
    value?: string;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
}
/**
 * A button with a customisable icon and text that can have any orientation (row/column).
 * Responds to being hovered/being selected by changing type (from regular to solid) and colour.
 */
const AppButton: FunctionComponent<AppProps> = ({
    selected = false,
    iconName,
    iconColour,
    hoverColour,
    selectedColour,
    iconWidth,
    iconHeight,
    text = undefined,
    textSize = undefined,
    textColour,
    textWeight = undefined,
    flexGap,
    orientation,
    value = undefined,
    onClick,
}) => {
    const styledTheme = useTheme<Theme>();
    const [isSolid, setSolid] = useState(false);
    const changeIconTypeOnMouseEnter = () => {
        if (!isSolid) {
            setSolid(true);
        }
    };
    const changeIconTypeOnMouseLeave = () => {
        if (!selected) {
            setSolid(false);
        }
    };
    const setIconType = (isSolid: boolean, isSelected: boolean) => {
        if (isSolid || isSelected) {
            return "fas";
        }
        return "far";
    };
    return (
        <StyledAppButton
            className="app-button"
            onClick={onClick}
            onMouseEnter={changeIconTypeOnMouseEnter}
            onMouseLeave={changeIconTypeOnMouseLeave}
            $directionFlex={orientation || "column"}
            $flexGap={flexGap || "2px"}
            type="button"
            value={value}
            data-testid={`appbar-button-${value}`}
            disabled={iconName === "circle-notch"}
        >
            <StyledSolidFontAwesomeIcon
                selected={selected}
                className={`${"app-button"}-${text}`}
                // eslint-disable-next-line prettier/prettier
                icon={[setIconType(isSolid, selected), iconName as IconName]}
                spin={iconName === "circle-notch"}
                color={iconColour || styledTheme.secondaryColors.blank}
                $hoverColour={hoverColour || styledTheme.secondaryColors.blank}
                $selectedColour={selectedColour || styledTheme.primaryColors.focusAccent}
                // for height/width to take prevalence over other values applied to this type of icon
                // i.e svg-inline--fa, they need to be set inline else will be overriden
                style={{ width: iconWidth || "23px", height: iconHeight || "21px" }}
            />
            {text && (
                <StyledIconText textColour={textColour || iconColour || styledTheme.secondaryColors.blank} textSize={textSize ?? "0.625rem"} textWeight={textWeight ?? "500"}>
                    {text}
                </StyledIconText>
            )}
        </StyledAppButton>
    );
};
const StyledAppButton = styled(InteractiveElement)<{ $directionFlex: string; $flexGap: string }>`
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: ${(props) => props.$directionFlex};
    gap: ${(props) => props.$flexGap};
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
`;
const StyledIconText = styled.span<{ textColour: string; textSize: string; textWeight: string }>`
    display: block;
    margin-top: 2px;
    color: ${(props) => props.textColour};
    font-size: ${(props) => props.textSize};
    text-align: center;
    font-weight: ${(props) => props.textWeight};
`;
const StyledSolidFontAwesomeIcon = styled(FontAwesomeIcon)<{
    selected: boolean;
    color: string;
    $hoverColour: string;
    $selectedColour: string;
}>`
    color: ${(props) => (props.selected ? props.$selectedColour : props.color)};
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    &:hover {
        color: ${(props) => (props.selected ? props.$selectedColour : props.$hoverColour)};
    }
`;
export default AppButton;
