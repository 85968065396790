/**
 * Takes a time value and converts it to a date object
 * @param time Time value such as 10:30 (HH:MM) -> 1030 (HHMM)
 * @returns Date object representing the time
 */
export function parseTimeNumberToDate(time: number) {
    if (time == null) {
        return null;
    }

    // Get the mins and hours value
    const mins = time % 100;
    const hours = (time - mins) / 100;

    // Create a new date object and set the hours and mins
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(mins);
    return date;
}

/**
 * Takes a time value and converts it to a string. Eg 1030 -> "10:30:00"
 * @param time Time value such as 1030
 * @returns Date object representing the time
 */
export function parseTimeNumberToString(time: number | null) {
    if (time == null) {
        return null;
    }

    // Get the mins and hours value
    const mins = time % 100;
    const hours = (time - mins) / 100;
    return `${padTwoDigits(hours)}:${padTwoDigits(mins)}:00`;
}

function padTwoDigits(num: number) {
    return num.toString().padStart(2, "0");
}

/**
 * Takes a date object and returns the time value as a number
 * @param time - Date object to convert
 * @returns Number value, for example 16:45 (HH:MM) -> 1645 (HHMM)
 */
export function parseTimeDateToNumber(time: Date) {
    if (time == null) {
        return null;
    }
    // Get the hours and mins value
    const hours = time.getHours();
    const mins = time.getMinutes();

    // Create a time value
    return hours * 100 + mins;
}

/**
 * Takes a time string and converts it to a number
 *
 * Example: "10:30" -> 1030
 */
export function parseTimeStringToNumber(time?: string | null) {
    if (time == null) {
        return null;
    }
    const timeArray = time.split(":");
    const hours = Number.parseInt(timeArray[0], 10);
    const mins = Number.parseInt(timeArray[1], 10);

    if (Number.isNaN(hours) || Number.isNaN(mins)) {
        return null;
    }

    return hours * 100 + mins;
}
