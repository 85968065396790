/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable global-require */
/* eslint-disable @typescript-eslint/no-var-requires */
import React from "react";
import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import { inspect } from "@xstate/inspect";
import { xstateDevTools } from "@iventis/utilities";
import { App } from "./app";

import "@iventis/translations/i18n";

window.global ||= window;

if (window.location.hostname === "localhost") {
    import("@axe-core/react").then((axe) => {
        axe.default(React, ReactDOM, 1000, {});
    });
}

if (xstateDevTools) {
    inspect({ iframe: false });
}

const container = document.getElementById("root");
// This no null assertion is specified by react
// https://reactjs.org/blog/2022/03/08/react-18-upgrade-guide.html
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
