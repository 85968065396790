import { CircularProgress, CircularProgressProps } from "@mui/material";
import React, { FunctionComponent } from "react";
import { styled } from "@iventis/styles";

export const LoadingComponent: FunctionComponent<{ size?: CircularProgressProps["size"]; className?: string; sx?: CircularProgressProps["sx"] }> = ({
    size = 40,
    className,
    sx,
}) => (
    <StyledProgressContainer className={className} data-testid="loading-spinner">
        <CircularProgress size={size} sx={sx} />
    </StyledProgressContainer>
);

const StyledProgressContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;
