import { IventisWebSockets } from "@iventis/websockets";

import { connection, webSocketMessage, SocketStatus } from "./connection.slice";

const socketMiddleware = () => {
    const Websockets = new IventisWebSockets<any>({
        keepAlivePayload: {
            eventName: "COMMS_CONNECTION_KEPT_ALIVE",
        },
        keepAliveIntervalTime: 360000,
    });

    let store = null;

    Websockets.onOpen(() => {
        store.dispatch(connection.actions.updateWsConnection(SocketStatus.OPENED));
    });

    Websockets.onError(() => {
        store.dispatch(connection.actions.updateWsConnection(SocketStatus.ERRORED));
    });

    Websockets.onConnect(() => {
        store.dispatch(connection.actions.updateWsConnection(SocketStatus.CONNECTING));
    });

    Websockets.onMessage((message) => {
        webSocketMessage.next(message);
    });

    // the middleware part of this function
    return (_store) => (next) => (action) => {
        switch (action.type) {
            case "WS_CONNECT":
                store = _store;
                Websockets.connect(action.host);
                break;
            case "NEW_MESSAGE":
                Websockets.sendMessage(action.payload.message);
                break;
            case "WS_DISCONNECT":
                Websockets.disconnect();
                break;
            default:
                break;
        }
        return next(action);
    };
};

export default socketMiddleware();
