import { Asset } from "@iventis/domain-model/model/asset";
import { compareAsc, parseISO } from "date-fns";

export const getExpiryAsISOString = (authoritySignatureExpiry: number) => new Date(new Date().getTime() + authoritySignatureExpiry * 1000).toISOString();

export const hasAssetSignatureExpired = (asset: CachedAsset) => compareAsc(new Date().getTime(), parseISO(asset.signatureExpiry)) >= 0;

export async function getAssetToCachedAsset(id: string, getAsset: (id: string) => Promise<Asset>): Promise<CachedAsset> {
    const asset = await getAsset(id);
    if (asset == null) return undefined;
    const signature = asset.authoritySignature;
    return {
        url: asset.assetUrl,
        id: asset.id,
        name: asset.name,
        type: asset.type,
        signature,
        signatureExpiry: getExpiryAsISOString(asset.authoritySignatureExpiry),
        thumbnail: asset.thumbnailUrl,
        authorityUrl: asset.authorityUrl,
        metaData: asset.metaData,
        updatedAt: asset.updatedAt,
    };
}

export type CachedAsset = {
    type: string;
    id: string;
    name: string;
    url: string;
    signature: string;
    signatureExpiry: string;
    thumbnail: string;
    authorityUrl: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    metaData?: any;
    updatedAt: Date;
};

export class AssetCacheService {
    private cachedAssets: CachedAsset[] = [];

    /** Array of types that have been cached */
    private cachedAll: string[] = [];

    /** Caches an asset from a given URL and returns the file */
    private async cache(asset: CachedAsset): Promise<void> {
        const { type, id, name, url, signature, signatureExpiry, thumbnail, authorityUrl, metaData, updatedAt } = asset;
        try {
            this.cachedAssets = this.cachedAssets.filter((c) => c.type !== type || c.id !== id);
            this.cachedAssets.push({
                type,
                id,
                name,
                url,
                signature,
                signatureExpiry,
                thumbnail,
                authorityUrl,
                metaData,
                updatedAt,
            });
        } catch {
            throw new Error(`Failed to cache asset from ${url}`);
        }
    }

    private getAssetFromCache = (id: string) => this.cachedAssets.find((c) => c.id === id);

    /** Returns the cached asset or if not available, loads and caches the asset */
    public async get(id: string, getAsset: () => Promise<CachedAsset>): Promise<CachedAsset> {
        const asset = this.getAssetFromCache(id);
        // If the asset exists in the cache and the signature has not expired, returned the cached asset
        if (asset != null && !hasAssetSignatureExpired(asset)) {
            return asset;
        }
        try {
            const cachedAsset = await getAsset();
            this.cache(cachedAsset);
            return cachedAsset;
        } catch {
            throw new Error(`Failed to get URL of asset: ${id}`);
        }
    }

    public async getList(ids: string[], getAssets: (ids: string[]) => Promise<CachedAsset[]>) {
        let cachedAssets: CachedAsset[] = [];
        const notCached: string[] = [];
        ids.forEach((id) => {
            const asset = this.getAssetFromCache(id);
            if (asset != null && !hasAssetSignatureExpired(asset)) {
                cachedAssets.push(asset);
            } else {
                notCached.push(id);
            }
        });
        if (notCached.length > 0) {
            const res = await getAssets(notCached);
            cachedAssets = [...cachedAssets, ...(res || [])];
        }
        return cachedAssets;
    }

    /** Returns all cached assets of a particular type or if not available, loads and caches the assets */
    public async getAllByType(type: string, getAssets: () => Promise<CachedAsset[]>) {
        // If we haven't cached all the assets belonging to this project and type or if at least one of them has expired, re-fetch them and cache
        if (!this.cachedAll.includes(type) || this.cachedAssets.some((asset) => asset.type === type && hasAssetSignatureExpired(asset))) {
            const assets = await getAssets();
            assets.forEach((asset) => {
                this.cache(asset);
            });
            this.cachedAll.push(type);
        }
        return this.cachedAssets.filter((asset) => asset.type === type);
    }

    public bustCacheIds(ids: string[]) {
        this.cachedAssets = this.cachedAssets.filter((x) => !ids.includes(x.id));
    }
}
