import type { History } from "history";
import { peopleRoute } from "@iventis/people-app/src/constants";
import { projectCustomisationRoute, projectSettingsRoute } from "@iventis/project-settings/src/constants";

export const handleAppClicked = (
    clickedAppId: string,
    currentSelectedAppId: string,
    setSelectedApp: (selectedApp: string) => void,
    history: History<unknown>,
    mobileDrawerOpen: boolean,
    setMobileDrawerOpen: (mobileDrawerOpen: boolean) => void,
    libraryId?: string
) => {
    if (currentSelectedAppId !== clickedAppId) {
        setMobileDrawerOpen(true);
    } else {
        setMobileDrawerOpen(!mobileDrawerOpen);
    }

    switch (clickedAppId) {
        case "Dashboard":
            if (history.location.pathname !== "/") {
                history.push("/dashboard");
            }
            setSelectedApp("Dashboard");
            break;
        case "Maps":
            if (history.location.pathname.includes("spatial-planner")) {
                // to avoid unnecessary history.push() if we're already on the path
            } else if (history.location.pathname !== `/spatial-planner/${libraryId}`) {
                // temporary solution for dev, it will error out if one attempts to use the button
                // ON THE FIRST REMDER to navigate to the maps libraries without selecting a project
                // TODO: send user to the libraries of the selected project; if none was selected,
                // let the user know (tooltip/notif) to select a project first
                history.push(`/spatial-planner`);
                setSelectedApp("Maps");
            }
            break;
        case "Schedule":
            if (!history.location.pathname.includes("/schedule")) {
                history.push("/schedule");
                setSelectedApp("Schedule");
            }
            break;
        case "People":
            if (!history.location.pathname.includes(peopleRoute)) {
                history.push(peopleRoute);
                setSelectedApp("People");
            }
            break;
        case "Settings":
            if (!history.location.pathname.includes(projectSettingsRoute)) {
                history.push(projectCustomisationRoute);
                setSelectedApp("Settings");
            }
            break;
        default:
            break;
    }
    // prevent double clicking to have any effect
    if (currentSelectedAppId !== clickedAppId) {
        setSelectedApp(clickedAppId);
    }
};
