import mapboxgl, { MapboxOptions } from "mapbox-gl";
import { TextStyle } from "@iventis/domain-model/model/textStyle";
import { DataFieldListItem } from "@iventis/domain-model/model/dataFieldListItem";
import { EngineInterpreterOptions } from "../../types/internal";
import { PitchOptions } from "../../types/store-schema";

export interface MapboxEngineOptions extends EngineInterpreterOptions {
    mbxAccessToken: string;
    maxZoom: number;
    minZoom: number;
    bustTileCache?: TileCacheBuster;
    transformRequest?: mapboxgl.TransformRequestFunction;
    preserveDrawingBuffer?: boolean;
    fontStackUrl: string;
    pitchOptions?: PitchOptions;
    exportOptions?: ExportOptions;
    useMapObjectBounds?: boolean;
}

export type ExportOptions = { bearing: number; bounds: number[][]; scale?: number };

export const transparentColour = "rgba(0,0,0,0)";

export type LineEndString = "butt" | "round" | "square";
export type LineJoinString = "bevel" | "round" | "miter";

export type TileCacheBuster = (tileUrls: string[]) => string[];

export type AnySupportedMapboxLayer =
    | mapboxgl.LineLayer
    | mapboxgl.FillLayer
    | mapboxgl.CircleLayer
    | mapboxgl.SymbolLayer
    | mapboxgl.CustomLayerInterface
    | mapboxgl.FillExtrusionLayer;

export enum MapboxStyleProperty {
    PAINT = "paint",
    LAYOUT = "layout",
}

/* Font Stacks for mapbox text layers */
/** Used when the font is bold */
export const boldFontStack = ["Open Sans Bold", "Arial Unicode MS Bold"];
/** Used when the font is regular */
export const regularFontStack = ["Open Sans Regular", "Arial Unicode MS Regular"];
/** Text anchor positions for when the text position is automatic */
export const automaticTextAnchor: mapboxgl.Anchor[] = ["center", "bottom", "top", "right", "left"];

/** A key value pair, where the key is Iventis style property, value is mapbox equivalent style property */
export const iventisToMapboxTextStyleProperty: { [key in keyof TextStyle]: keyof mapboxgl.SymbolLayout | keyof mapboxgl.SymbolPaint | undefined } = {
    text: "text-field",
    textContent: "text-field",
    textColour: "text-color",
    textSize: "text-size",
    textOverlap: "text-allow-overlap",
    textBold: "text-font",
    textItalic: undefined,
    textUnderlined: undefined,
    textOutlineWidth: "text-halo-width",
    textOutlineColour: "text-halo-color",
    textOpacity: "text-opacity",
    textPosition: "text-variable-anchor",
    textOffset: "text-radial-offset",
    styleType: undefined,
    objectOrder: undefined,
} as const;

export const maxDefaultPitch = 85;
export const minDefaultPitch = 0;

export type MapboxInitialPosition = Pick<MapboxOptions, "pitch" | "bearing" | "zoom" | "center" | "bounds" | "fitBoundsOptions">;

export const locationHashString = "location";

export type ApplyListItemTextFunction = (expressionCallback: (listItems: DataFieldListItem[]) => mapboxgl.Expression) => Promise<void>;

export const googleMapBackgroundAssetTag = "Google Maps";
