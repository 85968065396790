import Axios, { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { AssetCacheService } from "@iventis/utilities";

export enum MicroService {
    PERMISSIONS = "permissions",
    LIBRARY = "library",
    MAPPING = "mapping",
    ASSETS = "assets",
    SCHEDULE = "schedule",
    EXPORTS = "exports",
    COMMENTS = "comments",
    NOTIFICATIONS = "notifications",
    USAGE = "usage",
}

export const localApiPortNumbers = {
    [MicroService.PERMISSIONS]: "5110",
    [MicroService.LIBRARY]: "5120",
    [MicroService.MAPPING]: "5130",
    [MicroService.ASSETS]: "5140",
    [MicroService.SCHEDULE]: "5160",
    [MicroService.EXPORTS]: "5170",
    [MicroService.COMMENTS]: "5190",
    [MicroService.NOTIFICATIONS]: "5200",
};

export const initialiseAxios = (baseUrlSetter: (microservice: MicroService) => string, config: { withCredentials?: boolean; headers?: AxiosRequestHeaders }) => {
    const createConfig = (microservice?: MicroService): AxiosRequestConfig => ({ baseURL: microservice ? baseUrlSetter(microservice) : null, ...config });

    const permissionsApi = Axios.create(createConfig(MicroService.PERMISSIONS));
    const libraryApi = Axios.create(createConfig(MicroService.LIBRARY));
    const mappingApi = Axios.create(createConfig(MicroService.MAPPING));
    const assetsApi = Axios.create(createConfig(MicroService.ASSETS));
    const scheduleApi = Axios.create(createConfig(MicroService.SCHEDULE));
    const exportsApi = Axios.create(createConfig(MicroService.EXPORTS));
    const commentsApi = Axios.create(createConfig(MicroService.COMMENTS));
    const notificationsApi = Axios.create(createConfig(MicroService.NOTIFICATIONS));
    const usageApi = Axios.create(createConfig(MicroService.USAGE));
    const iventisApi = Axios.create(config);
    const cdnAssets = Axios.create();

    const apis = {
        [MicroService.PERMISSIONS]: permissionsApi,
        [MicroService.LIBRARY]: libraryApi,
        [MicroService.MAPPING]: mappingApi,
        [MicroService.ASSETS]: assetsApi,
        [MicroService.SCHEDULE]: scheduleApi,
        [MicroService.EXPORTS]: exportsApi,
        [MicroService.COMMENTS]: commentsApi,
        [MicroService.NOTIFICATIONS]: notificationsApi,
        [MicroService.USAGE]: usageApi,
        cdnAssets,
        iventisApi,
    };

    const assetCacheService = new AssetCacheService();

    return { apis, assetCacheService };
};
