/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Button, ButtonProps } from "@mui/material";
import styled from "../transient-styled";

const StyledTextButton = styled(Button)`
    color: #1a1a1a;
`;

// Solid button with white text
export function SolidButton(props: ButtonProps) {
    return <Button variant="contained" size="large" color="primary" {...props} />;
}

// Clear button with black text
export function TextButton(props: ButtonProps) {
    return <StyledTextButton variant="text" size="large" {...props} />;
}
