export enum DataFieldType {
    List = "List",
    MultiSelect = "MultiSelect",
    Number = "Number",
    Tickbox = "Tickbox",
    Text = "Text",
    Date = "Date",
    Time = "Time",
    TimeRange = "TimeRange",
    What3Words = "What3Words",
    Image = "Image",
    Hyperlink = "Hyperlink",
    RepeatedTimeRanges = "RepeatedTimeRanges"
}