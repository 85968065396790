/** Use to download an image without navigating away from current tab */
export const downloadImage = async (imagePath: string, imageName: string) => {
    const image = await fetch(imagePath);
    const imageBlog = await image.blob();
    const imageURL = URL.createObjectURL(imageBlog);
    const link = document.createElement("a");
    // eslint-disable-next-line iventis/no-href-assignments
    link.href = imageURL;
    link.download = imageName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

/**
 * Gets and downloads a json file from a given url
 *
 * @param jsonFileUrl - url of the json file to be downloaded
 * @param fileName - name of the file to be downloaded
 * @returns a boolean indicating if the download was successful
 */
export async function downloadJson(jsonFileUrl: string, fileName: string): Promise<boolean> {
    // Get json file contents
    const response = await fetch(jsonFileUrl);

    // Check if response is successful
    if (response.status !== 200) {
        return false;
    }

    const data = await response.json();

    // Create a blob object from the json file and get url of blob object
    const blob = new Blob([JSON.stringify(data)], { type: "application/json" });
    const url = URL.createObjectURL(blob);

    // Create an anchor element and set its href and download attributes
    const link = document.createElement("a");
    // eslint-disable-next-line iventis/no-href-assignments
    link.href = url;
    link.download = fileName;

    // Append the anchor element to the document body and simulate a click
    document.body.appendChild(link);
    link.click();

    // Remove the anchor element from the document body, use settimeout to wait until after the click event has been triggered
    setTimeout(() => document.body.removeChild(link), 0);
    URL.revokeObjectURL(url);
    return true;
}

/** Use to download a file, don't use for images */
export const downloadFile = (filePath: string) => window.open(filePath);
