import { IVENTIS_LOGO } from "@iventis/utilities";
import React from "react";
import { styled } from "@iventis/styles";

export const CompanyThumbnail: React.FC<{ imageUrl?: string; className?: string }> = ({ imageUrl, className }) => (
    <StyledImage className={className} alt="Company logo" src={imageUrl || IVENTIS_LOGO} />
);

const StyledImage = styled.img`
    background-color: white;
    width: 100%;
    height: 100%;
    object-fit: contain;
`;
