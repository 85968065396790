import { IconButton } from "@mui/material";
import { Theme } from "@emotion/react";
import { hiddenTextCss, iconButtonSize } from "../atomic-rules";
import styled from "../transient-styled";

export const StyledIconButton = styled(IconButton)<{ opacity?: number }>`
    width: ${iconButtonSize};
    height: ${iconButtonSize};
    ${hiddenTextCss};
    color: ${({ theme }: { theme: Theme }) => theme.typographyColors.lessSubdued};
    opacity: ${(props) => props.opacity ?? 1};
`;
