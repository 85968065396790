import { combineReducers, configureStore } from "@reduxjs/toolkit";
import auth from "@iventis/permissions/src/auth.slice";
import connectionReducer from "@iventis/api/src/connection.slice";
import webSocketMiddleware from "@iventis/api/src/web-socket.middleware";
import { mapReducer } from "./map.slice";
import sidebarReducer from "./sidebar.slice";
import mapSliceMiddleware from "./map.slice.middleware";

export enum SliceNames {
    MAP = "mapReducer",
    SIDEBAR = "sidebarReducer",
}

enum GlobalActionType {
    RESET_SLICE = "RESET_SLICE",
}

export const resetSlice = (name: SliceNames) => ({ type: GlobalActionType.RESET_SLICE, payload: name });

const appReducer = combineReducers({
    auth,
    mapReducer,
    sidebarReducer,
    connectionReducer,
});

const rootReducer = (state: ReturnType<typeof appReducer>, action) => {
    // If the action is of RESET_SLICE type then assign the slice's state to undefined to revert the slice to its initial state
    switch (action.type) {
        case GlobalActionType.RESET_SLICE:
            if (Object.values(SliceNames).includes(action.payload)) {
                // eslint-disable-next-line no-param-reassign
                state = { ...state, [action.payload]: undefined };
            } else {
                throw new Error("Slice does not exist");
            }
            break;
        default:
            break;
    }
    return appReducer(state, action);
};

export const rootStore = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        // Make sure we use concatenation rather than array spreading here to preserve type information
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat([webSocketMiddleware, mapSliceMiddleware]),
});

export const rootState = () => rootStore.getState() as RootState;

export type Store = typeof rootStore;
export type RootState = ReturnType<typeof rootStore.getState>;
export type RootDispatch = typeof rootStore.dispatch;
export const { dispatch } = rootStore;
