export const permissions = {
    useInheritedPermissions: "Use inherited {{ resource }} permissions",
    permissions: "permissions",
    addUserOrGroup: "Add user or group",
    additionalPermissions: "Additional Permissions",
    inheritedPermissions: "Inherited Permissions",
    turnOffInheritance: "Turn off inheritance",
    turnOnInheritance: "Turn on inheritance",
    turnOnInheritanceDescription1: "By turning this on, you will reinstate the permissions inherited from the parent.",
    turnOnInheritanceDescription2: "Note that by turning this back on, any additional permissions on this item will be lost.",
    turnOffInheritanceDescription1: "By turning this off, you will be able to adjust the permissions of all users and user groups.",
    turnOffInheritanceDescription2: "Note that by breaking the inheritance chain, any future changes made to the permissions of the parent will not be passed on to this item.",
    inheritanceChangesSaved: "Inheritance changes are automaically saved.",
    authLevels: {
        admin: {
            title: "Full Access",
            descriptionPlan: "Edit access plus delete, rename and manage permissions",
            descriptionFolder: "Create, rename and delete plans and folders",
            descriptionLayer: "Edit access plus delete, rename and manage permissions",
        },
        write: {
            title: "Edit",
            descriptionPlan: "Create and edit objects and attributes",
            descriptionFolder: "Can add plans and sub-folders",
            descriptionLayer: "Create and edit objects and attributes",
        },
        read: {
            title: "View",
            descriptionPlan: "Can view the plan and add comments",
            descriptionFolder: "Can view folder contents",
            descriptionLayer: "Can view the objects belonging to this layer",
        },
        deny: {
            title: "No Access",
            descriptionPlan: "Cannot view the plan",
            descriptionFolder: "Cannot view folder contents",
            descriptionLayer: "Cannot view objects belonging to this layer",
        },
    },
    usersGroups: {
        searchLabel: "Enter a name or group",
        searchTitle: "Search for a colleague",
    },
    permissionsEditTitle: "Set {{ nodeType }} Editing Permissions",
    resourceLockDescription1: "The permissions are locked and no-one can make edits.",
    resourceLockDescription2: "Admins can unlock at any time.",
    unlockButtonText: "Unlock to allow editing",
    inheritedLockDescription1: "No one can make edits due to a cascading lock from a parent.",
    inheritedLockDescription2: "Admins can unlock by going to the parent settings.",
    lock: "Lock",
    inheritedPermissionsHelpText: "With this setting turned on, this object will use the permissions set by the parent object.",
    linkExpiredModal: {
        title: "Link expired",
        content: "This link has expired or is invalid.\n Contact the user who created the link if you require access.",
    },
    keepPermissions: "Keep permissions",
} as const;
