import { Content } from "@iventis/translations";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import React, { FunctionComponent } from "react";
import { useQuery } from "@tanstack/react-query";
import { ConfirmationDialogComponent } from "@iventis/components";

export const StripeRedirectModal: FunctionComponent<{
    projectId: string;
    open: boolean;
    onClose: () => void;
    getStripeUrl: () => Promise<string>;
    additionalQueryKeys?: string[];
    message?: string;
    title?: string;
    openNewTab?: boolean;
}> = ({ projectId, open, onClose, getStripeUrl, additionalQueryKeys = [], message, title, openNewTab = true }) => {
    const translate = useIventisTranslate();

    const { isLoading: isLoadingStripeURL, data: stripeURL } = useQuery(["billingSub", projectId, ...additionalQueryKeys], async () => getStripeUrl(), { enabled: open });

    return (
        <ConfirmationDialogComponent
            show={open}
            handleConfirm={() => {
                if (openNewTab) {
                    window.open(stripeURL);
                    onClose();
                } else {
                    // eslint-disable-next-line iventis/no-href-assignments
                    window.location.href = stripeURL;
                }
            }}
            handleCancel={onClose}
            isSubmitting={isLoadingStripeURL}
            title={title ?? translate(Content.settings2.billing.redirect_modal.title)}
            confirmText={translate(Content.common.buttons.continue)}
            cancelText={translate(Content.common.buttons.cancel)}
        >
            {message ?? <span>{translate(Content.settings2.billing.redirect_modal.content)}</span>}
        </ConfirmationDialogComponent>
    );
};
