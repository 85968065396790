export enum IventisFilterOperator {
    Gt = "Gt",
    Gte = "Gte",
    Lt = "Lt",
    Lte = "Lte",
    Eq = "Eq",
    Ct = "Ct",
    In = "In",
    NotIn = "NotIn",
    Not = "Not"
}