/* eslint-disable no-spaced-func */
/* eslint-disable func-call-spacing */
import { createAsyncThunk, unwrapResult } from "@reduxjs/toolkit";
import { assetUrlGetter, mappingApi } from "@iventis/api/src/api";
import { ResponseError } from "@iventis/api/src/axios.types";
import { Map } from "@iventis/domain-model/model/map";
import { AxiosError } from "axios";
import { getMapObjectBounds, getCommentBounds, multipleModelsGetter } from "@iventis/api-helpers-mapping";
import { ModelData, ModelLayerStyle } from "@iventis/map-engine";
import { LocalGeoJson, RouteWaypoint } from "@iventis/map-types";
import { convertMapObjectToLocalGeoJson, isModelLayer } from "@iventis/map-engine/src/utilities/state-helpers";
import { getStaticAndMappedValues, styleTypeToLayerProperties } from "@iventis/map-engine/src/utilities/style-helpers";
import { queryParams } from "@iventis/utilities";
import { IventisFilterOperator } from "@iventis/data-table";
import { StyleType } from "@iventis/domain-model/model/styleType";
import QueryString from "qs";
import { PagedResult } from "@iventis/domain-model/model/pagedResult";
import { MapObject } from "@iventis/domain-model/model/mapObject";
import { AssetType } from "@iventis/domain-model/model/assetType";
import { MapLayer } from "@iventis/domain-model/model/mapLayer";
import { incrementMonitoringCount } from "@iventis/observability-and-monitoring";
import { MetricName } from "@iventis/domain-model/model/metricName";
import { Polygon } from "geojson";
import { routeWaypointService } from "../services/route-waypoint-service";
import { getBackgroundMaps, getBasemap, getProjectDataFields, getSitemaps } from "./map.slice.thunks";
// eslint-disable-next-line iventis/no-root-store-dependencies
import { RootState, RootDispatch } from "./root.store";

/**
 * Calls all of the necessary thunks to load a map
 */
export async function loadMap(mapId: string, signal: AbortSignal, dispatch: RootDispatch) {
    dispatch(getProjectDataFields({ signal }));
    const { backgroundId, layers, savedMapViews, defaultSavedMapViewId } = unwrapResult(await dispatch(getMap({ mapId, signal })));
    // If query params has no level, then get from default saved map view
    const selectedLevel = queryParams.level ?? savedMapViews.find((view) => view.id === defaultSavedMapViewId)?.level?.toString();
    dispatch(getMapObjectsForMap({ mapId, pageNumber: 1, signal }));
    dispatch(getMapObjectAndCommentBounds({ mapId, level: selectedLevel, signal }));
    dispatch(getMapModels({ layers, signal }));
    dispatch(getBasemap({ id: backgroundId, type: AssetType.MapBackground, signal }));
    dispatch(getSitemaps({ signal }));
    dispatch(getBackgroundMaps({ signal }));
}

/**
 * Gets a basic map
 */
export const getMap = createAsyncThunk<Map, { mapId: string; signal: AbortSignal }, { state: RootState }>("Map/getMap", async ({ mapId, signal }, { rejectWithValue }) => {
    try {
        const { data: map } = await mappingApi.get<Map>(`/maps/${mapId}`, { signal });
        incrementMonitoringCount({ name: MetricName.PLAN_LOADED, resourceId: mapId });
        return map;
    } catch (err) {
        const error: AxiosError<ResponseError> = err;
        return rejectWithValue(error.response?.data);
    }
});

/**
 * Get all of the model's glb files needed given array of layers, requests to get the models will be done in the background
 */
export const getMapModels = createAsyncThunk<ModelData[], { layers: MapLayer[]; signal: AbortSignal }, { state: RootState }>(
    "Map/getMapModels",
    async ({ layers, signal }, { rejectWithValue }) => {
        try {
            // Get all of the models used in the layers of the map
            const modelsResponse = await multipleModelsGetter(
                layers.reduce(
                    (ids, layer) =>
                        isModelLayer(layer) ? [...ids, ...getStaticAndMappedValues((layer[styleTypeToLayerProperties[layer.styleType]] as ModelLayerStyle)?.model)] : ids,
                    []
                ),
                mappingApi,
                signal
            );

            // For each model which is being used start of getting the glb file now so less time to wait when the map has loaded
            const models = modelsResponse.map((model) => {
                const modelRequest = async () => {
                    const assetUrl = await assetUrlGetter(model.lods[0].files[0].assetId);
                    const response = await fetch(assetUrl);
                    const modelGlb = await response.arrayBuffer();
                    return modelGlb;
                };
                return {
                    ...model,
                    modelRequest: modelRequest(),
                };
            });

            return models;
        } catch (err) {
            const error: AxiosError<ResponseError> = err;
            return rejectWithValue(error.response?.data);
        }
    }
);

/**
 * Recursively gets all of the map objects for a given map, each time a page is fetched the map state is updated with them, then the next page is requested
 */
export const getMapObjectsForMap = createAsyncThunk<{ geoJSON: LocalGeoJson; finished: boolean }, { mapId: string; pageNumber: number; signal: AbortSignal }, { state: RootState }>(
    "Map/getMapObjectsForMap",
    async ({ mapId, pageNumber, signal }, { rejectWithValue, dispatch }) => {
        try {
            // Create a filter to get map objects for model and line model layers
            const filter = {
                params: {
                    filter: JSON.stringify([{ fieldName: "StyleType", operator: IventisFilterOperator.In, value: [StyleType.Model, StyleType.LineModel] }]),
                    mapId,
                    pageNumber,
                },
                paramsSerializer: (params) => QueryString.stringify(params, { arrayFormat: "repeat" }),
            };

            // Get the map objects for the current page
            const { data: pageOfMapObjects } = await mappingApi.get<PagedResult<MapObject[]>>(`/maps/${mapId}/map_objects/filter`, { ...filter, signal });

            // Get the route waypoints for the map objects
            const mapObjectWayPointsRequest = pageOfMapObjects.results.reduce<Promise<RouteWaypoint[]>[]>((requests, object) => {
                if (object.modeOfTransport != null || object.geoJsonFeature?.properties?.modeOfTransport != null) {
                    requests.push(routeWaypointService.getRouteWaypoints(object.id, mapId));
                }
                return requests;
            }, []);

            const waypoints = await Promise.all(mapObjectWayPointsRequest);

            // Convert the map objects to local geojson
            const parsedObjects = pageOfMapObjects.results
                .map((object, index) => convertMapObjectToLocalGeoJson(object, waypoints[index]))
                .reduce<LocalGeoJson>((acc, object) => {
                    const { layerid } = object.feature.properties;
                    const existingObjects = acc[layerid];
                    acc[layerid] = [...(existingObjects ?? []), object];
                    return acc;
                }, {});

            if (!pageOfMapObjects.lastPage) {
                // Get next page of map objects
                dispatch(getMapObjectsForMap({ mapId, pageNumber: pageNumber + 1, signal }));
            }

            return { geoJSON: parsedObjects, finished: pageOfMapObjects.lastPage };
        } catch (err) {
            const error: AxiosError<ResponseError> = err;
            return rejectWithValue(error.response?.data);
        }
    }
);

/**
 * Gets the bounds of the map objects and comments for a given map
 */
export const getMapObjectAndCommentBounds = createAsyncThunk<
    { mapObjectBounds: Polygon | ""; commentBounds: Polygon | "" },
    { mapId: string; level: string; signal: AbortSignal },
    { state: RootState }
>("Map/getMapObjectAndCommentBounds", async ({ mapId, level, signal }, { rejectWithValue }) => {
    try {
        const mapObjectBoundsRequest = getMapObjectBounds(mappingApi, mapId, level, signal);

        const commentBoundsRequest = getCommentBounds(mappingApi, mapId, level, signal);

        const [{ data: mapObjectBounds }, { data: commentBounds }] = await Promise.all([mapObjectBoundsRequest, commentBoundsRequest]);

        return { mapObjectBounds, commentBounds };
    } catch (err) {
        const error: AxiosError<ResponseError> = err;
        return rejectWithValue(error.response?.data);
    }
});
