import { TupleOfDotNotatedStringsToUnion, TupleToUnion } from "@iventis/types/useful.types";
import { StateValue } from "xstate";
import { EngineInterpreter } from "../bridge/engine-generic";
import { ValidationError } from "../errors";
import { InvocationEventType } from "../types/events-invocation";
import { MapModes } from "./map-machines.types";

/**
 * Creates a MapMode (a string) type from a given stateValue (an object) from the state machine to be stored in the map slice
 * @param {StateValue} stateValue - value which the state machine has produced
 * @returns {MapMode} - Mode has been created from the input stateValue
 */
export const machineModeOutputToString = <TStates extends readonly string[] = MapModes>(stateValue: StateValue): TupleToUnion<TStates> => {
    if (stateValue == null) {
        throw new Error("State value is undefined");
    }

    if (typeof stateValue === "string") {
        return stateValue as TupleToUnion<TStates>;
    }

    const stateArray = checkStateObject(stateValue);

    if (stateArray.length < 1) {
        throw new Error("State value could not be converted into a string");
    }

    return stateArray.join(".") as TupleToUnion<TStates>;
};

/**
 * Cycles through the object values if it is a string it is pushed to the string builder, if it is an StateValue object the function is called again with the value from the previous input
 * @param {StateValue | string} stateValue - Either a string or a state valueto be checked
 */
const checkStateObject = (stateValue: StateValue | string) => {
    const stringBuilder: string[] = [];

    const buildStateArray = (stateValue: StateValue | string, stringBuilder: string[]) => {
        Object.keys(stateValue).forEach((key) => {
            stringBuilder.push(key);
            const value = stateValue[key];
            if (typeof value === "string") {
                stringBuilder.push(value);
            } else {
                buildStateArray(value, stringBuilder);
            }
        });

        return stringBuilder;
    };

    return buildStateArray(stateValue, stringBuilder);
};

/**
 * Returns true if the mode to check mode is one of the map modes seperated by "."
 * @param {MapMode} currentMode - the current mode of the map
 * @param {string} modeToCheck - the mode to check for
 * @returns {boolean} - if the modeToCheck is present or not
 */
export const checkModeIsPresent = <TStates extends readonly string[] = MapModes>(
    currentMode: TupleToUnion<TStates>,
    modeToCheck: TupleOfDotNotatedStringsToUnion<TStates>
): boolean => {
    if (currentMode == null) {
        return false;
    }

    if (typeof currentMode !== "string") {
        throw new Error("Current mode needs to be in string a format");
    }

    return currentMode.includes(modeToCheck);
};

/**
 * Checks an array of modes to the MapMode
 * @param {MapMode} currentMode - the current mode of the map
 * @param {string[]} modesToCheck - the modes to check
 * @returns {boolean} - if the modeToCheck is present or not
 */
export const checkAnyModeIsPresent = <TStates extends readonly string[] = MapModes>(
    currentMode: TupleToUnion<TStates>,
    modesToCheck: TupleOfDotNotatedStringsToUnion<TStates>[]
): boolean => modesToCheck.some((modeToCheck) => checkModeIsPresent<TStates>(currentMode, modeToCheck));

export const generateCompositionError = (engine: EngineInterpreter) => {
    switch (engine.getCurrentComposition(true)?.geojson.geometry.type) {
        case "Polygon":
            engine.invocationEvents.next({
                type: InvocationEventType.VALIDATION_ERROR,
                body: {
                    message: ValidationError.POLYGON_FEW_NODES,
                },
            });
            break;
        case "LineString":
            engine.invocationEvents.next({
                type: InvocationEventType.VALIDATION_ERROR,
                body: {
                    message: ValidationError.LINESTRING_FEW_NODES,
                },
            });
            break;
        case "Point":
            engine.invocationEvents.next({
                type: InvocationEventType.VALIDATION_ERROR,
                body: {
                    message: ValidationError.UNKNOWN_ERROR,
                },
            });
            break;
        default:
            engine.invocationEvents.next({
                type: InvocationEventType.VALIDATION_ERROR,
                body: {
                    message: ValidationError.UNKNOWN_ERROR,
                },
            });
            break;
    }
};
