/**
 * Takes a epoch (seconds) date and converts it to a date object
 * @param dateNumber - The epoch (seconds) date
 * @returns Date object
 */
export function parseEpochSecondsToDate(dateNumber: number) {
    if (dateNumber == null) {
        return null;
    }

    return new Date(dateNumber * 1000);
}

/**
 * Takes a epoch (seconds) date and converts it to date only format (YYYY-MM-DD)
 * @param dateNumber- epoch (seconds) date
 * @returns string in format YYYY-MM-DD
 */
export function parseEpochSecondsToDateOnlyString(dateNumber: number | null) {
    if (dateNumber == null) {
        return null;
    }
    const date = new Date(dateNumber * 1000);
    return date.toISOString().split("T")[0];
}

/**
 * Takes a date object and returns the epoch (seconds) value
 * @param date - The date object to convert
 * @returns Epoch seconds value
 */
export function parseDateToEpochSeconds(date?: Date) {
    if (date == null) {
        return null;
    }
    // Ensure the date is set to the start of the day
    const newDate = new Date(0);
    const originalOffset = newDate.getTimezoneOffset();
    // Take given dates year, month and day and apply to new date
    newDate.setFullYear(date.getFullYear(), date.getMonth(), date.getDate());
    // Epoch seconds is always in UTC so we need to adjust for the timezone offset
    const offsetTime = newDate.getTime() + (originalOffset - newDate.getTimezoneOffset()) * 60000;

    return Math.floor(offsetTime / 1000);
}

/**
 * Takes a date string and converts it to a number
 *
 * Example "2024-06-13" -> 1718236800
 */
export function parseDayStringToEpochSeconds(date: string | null) {
    if (date == null) {
        return null;
    }
    const parsedDate = new Date(date);
    // Ensure the date is valid
    if (parsedDate.toString() === "Invalid Date") {
        return null;
    }

    return parseDateToEpochSeconds(parsedDate);
}
