import { MapLayer } from "@iventis/domain-model/model/mapLayer";
import { StyleType } from "@iventis/domain-model/model/styleType";

export function getLayerStyle<TLayer extends Pick<MapLayer, "styleType" | "areaStyle" | "lineStyle" | "iconStyle" | "pointStyle" | "modelStyle" | "lineModelStyle">>(
    layer: TLayer
) {
    if (layer == null) {
        return undefined;
    }

    switch (layer.styleType) {
        case StyleType.Area:
            return layer.areaStyle;
        case StyleType.Line:
            return layer.lineStyle;
        case StyleType.Point:
            return layer.pointStyle;
        case StyleType.Icon:
            return layer.iconStyle;
        case StyleType.Model:
            return layer.modelStyle;
        case StyleType.LineModel:
            return layer.lineModelStyle;
        default:
            throw new Error("Style could not be found");
    }
}
