import { MicroService } from "@iventis/api-helpers";
import { Asset } from "@iventis/domain-model/model/asset";
import { AssetType } from "@iventis/domain-model/model/assetType";
import { downloadImage, downloadFile, getAssetSignature } from "@iventis/utilities";
import { toast, ToastType } from "@iventis/toasts";
import { Content } from "@iventis/translations";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { SocketEvent } from "@iventis/types/loading.types";
import { useEffect } from "react";
import qs from "qs";
import { ResourceType } from "@iventis/domain-model/model/resourceType";
import { Export } from "@iventis/domain-model/model/export";
import { ExportStatus } from "@iventis/domain-model/model/exportStatus";
import { assetsApi, getApiBaseUrl } from "./api";
import { onSocketMessages } from "./connection.slice";

const scheduleResourceTypes = [ResourceType.Schedule, ResourceType.ScheduleVersion, ResourceType.EventBlock, ResourceType.VenueLocation];

export const exportResourceDownload = async (assetIds: string[], exportName: string) => {
    // Only one asset, aka not a zip file
    if (assetIds.length === 1) {
        const { data: asset } = await assetsApi.get<Asset>(`/assets/${assetIds[0]}`);
        if (asset.type === AssetType.Image) {
            await downloadImage(getAssetSignature(asset.assetUrl, asset.authoritySignature), exportName);
        } else {
            downloadFile(`${getApiBaseUrl(MicroService.ASSETS)}assets/download?assetIds=${assetIds.join("&assetIds=")}&name=${qs.stringify(exportName)}`);
        }
    } else {
        downloadFile(`${getApiBaseUrl(MicroService.ASSETS)}assets/download?assetIds=${assetIds.join("&assetIds=")}&name=${qs.stringify(exportName)}`);
    }
};

/** Export handler. Handles successful and unsuccessful exports that come through websockets, fetches the export urls from the exports service and returns themso we can use them within the component. In future this will likely be in react context to be available globally */
export const useExports = () => {
    const translate = useIventisTranslate();

    useEffect(() => {
        const messageStream = onSocketMessages(SocketEvent.EXPORT);
        const subscription = messageStream.subscribe((event) => {
            if (!scheduleResourceTypes.includes(event.message.resourceType)) {
                return;
            }
            switch (event.eventName) {
                case SocketEvent.EXPORT: {
                    if ((event.message as Export).status === ExportStatus.Completed) {
                        const download = () => {
                            if (event.message.exportUrlId == null) {
                                // Get all asset Ids
                                const assetIds = event.message.files.map(({ assetId }) => assetId);
                                exportResourceDownload(assetIds, event.message.name);
                            }
                        };
                        toast.success(
                            {
                                type: ToastType.BUTTON,
                                props: {
                                    icon: "circle-check",
                                    message: translate(Content.common.exports.success_message, { exportName: event.message.name }),
                                    onClick: download,
                                    buttonLabel: translate(Content.common.buttons.download),
                                },
                            },
                            { persist: true }
                        );
                    }
                    if ((event.message as Export).status === ExportStatus.Failed) {
                        toast.error({
                            type: ToastType.BASIC,
                            props: { message: translate(Content.common.exports.error_message, { exportName: event.message.name }), icon: "triangle-exclamation" },
                        });
                    }
                    break;
                }
                default:
                    break;
            }
        });
        return () => {
            subscription.unsubscribe();
        };
    }, []);
};
