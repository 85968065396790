import { Theme } from "@emotion/react";
import { fontSizes } from "@iventis/styles/src/atomic-rules";
import { SnackbarProvider } from "notistack";
import { styled } from "@iventis/styles";

export const ToastProvider = styled(SnackbarProvider)`
    .SnackbarItem-message {
        padding: 0px;
        width: 100%;
    }

    &.SnackbarItem-variantSuccess {
        background-color: ${({ theme }) => theme.toastColours.success};
        border: 2px solid ${(props) => `${props.theme.toastColours.successBorder}`};
        color: ${(props) => `${props.theme.typographyColors.core} `};
    }

    &.SnackbarItem-variantError {
        background-color: ${(props) => `${props.theme.toastColours.error}`};
        border: 2px solid ${(props) => `${props.theme.toastColours.errorBorder}`};
        color: ${(props) => `${props.theme.typographyColors.core}`};
    }

    &.SnackbarItem-variantInfo {
        background-color: ${(props) => `${props.theme.toastColours.info}`};
        border: 2px solid ${(props) => `${props.theme.toastColours.infoBorder}`};
        color: ${(props) => `${props.theme.typographyColors.core}`};
    }

    &.SnackbarItem-variantWarning {
        background-color: ${(props) => `${props.theme.toastColours.warning}`};
        border: 2px solid ${(props) => `${props.theme.toastColours.warningBorder}`};
        color: ${(props) => `${props.theme.typographyColors.core}`};
    }
`;

export const ToastButton = styled.div`
    justify-self: flex-end;
    height: 100%;
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    .toast-button {
        height: 100%;
        padding: 0 10px;
        margin-top: 1px;
        color: ${({ theme }: { theme: Theme }) => theme.typographyColors.core};
    }
`;

export const ToastContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: ${fontSizes.small};
    font-weight: 600;
    height: 35px;
    width: 100%;
`;

export const TitleToastContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    font-size: ${fontSizes.small};
    height: 50px;
    width: 100%;
    .toast-text {
        margin-left: 30px;
    }
    .icon-and-title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-weight: 600;
    }
`;
