/* eslint-disable react/jsx-props-no-spreading */
import { useHistory } from "@iventis/subscriptions";
import { queryParams, API_KEY_QUERY_PARAM_KEY } from "@iventis/utilities";
import React, { useEffect } from "react";
import { Route, useLocation } from "react-router-dom";

// A route only accessible if the user is authenticated
const ProjectRoute: React.FC<{
    isProjectSelected: boolean;
    render: (props) => React.ReactElement;
    path: string;
    exact?: boolean;
    onProjectPathChanged?: () => void;
}> = ({ isProjectSelected, render, onProjectPathChanged, ...rest }) => {
    const location = useLocation();
    const history = useHistory();
    useEffect(() => {
        onProjectPathChanged?.();
    }, [location.pathname]);

    if (!isProjectSelected && queryParams[API_KEY_QUERY_PARAM_KEY] == null) {
        history.push("/projects"); // Refresh to the projects page
        return <div />;
    }

    return <Route {...rest} render={render} />;
};

export default ProjectRoute;
