import { setUser } from "@sentry/react";
import { MetricName } from "@iventis/domain-model/model/metricName";
import { setMonitoringTag } from "../tags/set-monitoring-tag";
import { ReplayTag } from "../tags/tag-types";
import { setMonitoringUserScreenSize } from "./user-screen-size";
import { isIventisEmployee } from "./user-email";
import { incrementMonitoringCount } from "../metrics/set-monitoring-metric";

/** Sets monitoring data for a user for session replays and metrics, optional if they are signed into a project */
export function setMonitoringUser(user: { firstName: string; lastName: string; email: string; id: string; externalUser: boolean }, project?: { id: string; name: string }) {
    const { firstName, lastName, email, id, externalUser } = user;
    const userName = `${user.firstName} ${user.lastName}`;
    const isIventisStaff = isIventisEmployee(email);

    setUser({ email, name: `${firstName} ${lastName}`, id });
    // User name is not set by sentry so add it manually
    setMonitoringTag(ReplayTag.USER_NAME, userName);
    setMonitoringTag(ReplayTag.IVENTIS_EMPLOYEE, isIventisStaff);
    setMonitoringTag(ReplayTag.EXTERNAL_USER, externalUser);

    if (project != null) {
        const { id: projectId, name: projectName } = project;
        setMonitoringTag(ReplayTag.PROJECT_NAME, projectName);
        setMonitoringTag(ReplayTag.PROJECT_ID, projectId);

        incrementMonitoringCount({ name: MetricName.USER_SESSION, resourceId: projectId });
    }

    setMonitoringUserScreenSize();
}
