import React, { useState } from "react";
import { Body2, Body3, InteractiveElement, StyledInteractiveArea, borderRadius, fontSizes, styled } from "@iventis/styles";
import { ProgressSpinnerComponent } from "@iventis/components";
import { timeAgo } from "@iventis/utilities";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { Content } from "@iventis/translations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NotificationLayersImportData } from "@iventis/domain-model/model/notificationLayersImportData";
import { LayersImportNotification } from "./notification-types";
import { NotificationStatus, NotificationStatusComponent } from "./notification-status";
import { isNotificationInProgress } from "./notification-helpers";

interface LayersImportNotificationProps {
    notification: LayersImportNotification;
    onClick: () => void;
    gotToMap: (mapId: string) => void;
    isUserCurrentlyInMap: (mapId: string) => boolean;
}

/** Notification for a layers import */
export const LayersImportNotificationComponent = ({ notification, onClick, gotToMap, isUserCurrentlyInMap }: LayersImportNotificationProps) => {
    const translate = useIventisTranslate();
    const { lastUpdatedAt, resourceData, readAt } = notification;
    const { name, mapId, failed } = resourceData as NotificationLayersImportData;
    const [read, setRead] = useState(readAt != null);
    const inProgress = isNotificationInProgress(notification);
    const status: NotificationStatus = failed ? "failed" : inProgress ? "in-progress" : "completed";
    const title = translate(Content.notifications.layersImport[status].title);
    const description = translate(Content.notifications.layersImport[status].description, { name });

    const handleClick = () => {
        setRead(true);
        onClick();
    };

    return (
        <LayersImportNotificationContainer inProgress={inProgress} read={read} data-testid={`layers-import-notification-${notification.id}`}>
            {/* Click anywhere on the component to mark as read */}
            {!read && !inProgress && (
                <StyledInteractiveArea data-testid={read ? "" : `read-notification-button-${title}`} zIndex={100} onClick={handleClick}>
                    <span className="hidden">{translate(Content.notifications.markAsRead)}</span>
                </StyledInteractiveArea>
            )}
            <div className="profile-image-container">{inProgress ? <ProgressSpinnerComponent height="30px" /> : <FontAwesomeIcon size="lg" icon={["fas", "file-import"]} />}</div>
            <div className="notification-body">
                <div data-testid="notification-title-section" className="notification-section">
                    <div className="name-date no-text-overflow">
                        <Body2 className="notification-title no-text-overflow">{title}</Body2>
                    </div>
                    <NotificationStatusComponent status={status} notificationId={notification.id} />
                </div>
                <div className="notification-section">
                    <Body3 data-testid="notification-body" data-notificationid={notification.id}>
                        {description}
                    </Body3>
                </div>
                <div className="notification-footer">
                    <Body3 className="created-at no-text-overflow">{timeAgo(lastUpdatedAt, translate(Content.comments.justNow), true)}</Body3>
                    {/* If the user is not currently in the map, we wnat to navigate to it */}
                    {!isUserCurrentlyInMap(mapId) && status === "completed" && (
                        <StyledInteractiveElement data-testid={`notification-view-imported-layers=${name}`} className="load-new-layers-button" onClick={() => gotToMap(mapId)}>
                            {translate(Content.notifications.layersImport.goToMap)}
                        </StyledInteractiveElement>
                    )}
                </div>
            </div>
        </LayersImportNotificationContainer>
    );
};

export const LayersImportNotificationContainer = styled.div<{ read: boolean; inProgress: boolean }>`
    width: 270px;
    box-sizing: border-box;
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    background-color: ${({ read, inProgress, theme }) => (inProgress ? theme.secondaryColors.warm : read ? theme.secondaryColors.blank : theme.tertiaryColors.primaryBackground)};
    border-radius: ${borderRadius.standard};
    padding: 10px;

    .profile-image-container {
        width: 44px;
        height: 44px;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: ${borderRadius.circle};
        background-color: ${({ theme, inProgress }) => (inProgress ? theme.secondaryColors.blank : theme.primaryColors.subdued70)};
        color: ${({ theme }) => theme.secondaryColors.blank};

        &--skeleton {
            background-color: ${({ theme }) => theme.secondaryColors.blank};
        }
    }

    .notification-body {
        display: flex;
        // Profile image is 44px pixels wide
        width: calc(100% - 44px);
        flex-direction: column;
        gap: 5px;
    }

    .notification-section {
        display: flex;
        gap: 5px;
        align-items: center;
    }

    .name-date {
        display: flex;
        flex-grow: 1;
        gap: 10px;
    }

    .notification-title {
        font-weight: 500;
    }

    .created-at {
        color: ${({ theme }) => theme.typographyColors.subdued};
    }

    // Ensures that the name or date does not overflow
    .no-text-overflow {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .user-profile-image {
        height: 36px;
        width: 36px;
    }

    .load-new-layers-button {
        font-weight: 500;
        text-decoration: underline;
        font-size: ${fontSizes.xSmall};
        z-index: 200;
        :not([disabled]) {
            color: ${({ theme }) => theme.primaryColors.focus};
        }
    }

    .notification-footer {
        display: flex;
        justify-content: space-between;
    }
`;

const StyledInteractiveElement = styled(InteractiveElement)`
    :disabled {
        cursor: not-allowed;
    }
`;
