import { AuthenticatedUser } from "@iventis/domain-model/model/authenticatedUser";
import { RootDispatch, RootState } from "@iventis/plans/src/state/root.store";
import React, { FunctionComponent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "@emotion/styled";
import "@iventis/styles/src/fonts/index.css";
import { uploadUserIcon } from "@iventis/permissions/src/auth.slice";
import { SOURCE_SANS_PRO_BASE64 } from "./user-icon-generator-constants";

/** When requested, this will generate a user icon and upload it for the user */
export const UserIconGenerator = () => {
    const { running, user } = useSelector((s: RootState) => ({ running: s.auth.generatingUserIcon, user: s.auth.user }));
    return <>{running ? <UserIcon user={user} /> : null}</>;
};

const USER_ICON_IMAGE_SIZE = "44px";

/** Renders an svg image onto a canvas using the user's name or email and save it as a png */
const UserIcon: FunctionComponent<{ user: AuthenticatedUser }> = ({ user }) => {
    const dispatch = useDispatch<RootDispatch>();
    useEffect(() => {
        const svgElement = (document.getElementById("user-icon") as unknown) as SVGSVGElement;
        const { width, height } = svgElement.getBBox();
        const clonedSvgElement = svgElement.cloneNode(true) as SVGSVGElement;
        const { outerHTML } = clonedSvgElement;
        const blob = new Blob([outerHTML], { type: "image/svg+xml;charset=utf-8" });
        const url = (window.URL || window.webkitURL || window) as typeof window.webkitURL;
        const blobURL = url.createObjectURL(blob);
        const image = new Image();

        image.onload = () => {
            const canvas = document.createElement("canvas");
            canvas.width = width;
            canvas.height = height;
            canvas.style.backgroundColor = "transparent";
            const context = canvas.getContext("2d");
            context.drawImage(image, 0, 0, width, height);
            const dataUrl = canvas.toDataURL("image/png");
            dispatch(uploadUserIcon({ dataUrl, user }));
        };
        image.src = blobURL;
    }, []);

    return (
        <StyledSvg id="user-icon" width={USER_ICON_IMAGE_SIZE} height={USER_ICON_IMAGE_SIZE} xmlns="http://www.w3.org/2000/svg">
            <defs>
                <style type="text/css">{SOURCE_SANS_PRO_BASE64}</style>
            </defs>
            <circle cx="22" cy="22" r="22" fill="#26273F" />
            <text x="22" y="27" fontSize="18px" fontFamily="SourceSansPro" fontWeight="400" textAnchor="middle" fill="white">
                {(user.firstName == null ? user.emailAddress.charAt(0) : user.firstName?.charAt(0) + (user.lastName?.charAt(0) ?? ""))?.toUpperCase()}
            </text>
        </StyledSvg>
    );
};

const StyledSvg = styled.svg`
    position: absolute;
    left: -${USER_ICON_IMAGE_SIZE};
`;
