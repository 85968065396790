/* eslint-disable react/jsx-props-no-spreading */
import { LoadableComponent } from "@loadable/component";
import React from "react";
import { Route } from "react-router-dom";

// A route accessible by any user only if they are not authenticated
const PublicRoute: React.FC<{
    component: React.FC | LoadableComponent<unknown>;
    path: string;
    exact?: boolean;
}> = ({ component: Component, ...rest }) => <Route {...rest} component={(props) => <Component {...props} />} />;

export default PublicRoute;
