import { LocalGeoJson, LocalGeoJsonObject } from "@iventis/map-types";

/**
 * Merges two LocalGeoJson objects into a single combined object. Does not add any objects which are already in the existing geojson.
 */
export function mergeGeojson(existingLocalGeoJson: LocalGeoJson, newLocalGeoJson: LocalGeoJson) {
    // If there is no new geojson, return the existing geojson
    if (newLocalGeoJson == null) {
        return existingLocalGeoJson;
    }

    // Copy the existing geojson so we don't mutate the original
    const copiedGeoJSON = existingLocalGeoJson != null ? { ...existingLocalGeoJson } : {};

    Object.entries(newLocalGeoJson).forEach(([layerId, newObjects]) => {
        let updatedObjects: LocalGeoJsonObject[] = [];
        const layerGeoJSON = copiedGeoJSON[layerId];
        // If there is existing geoJSON for that layer already
        if (layerGeoJSON != null) {
            updatedObjects = [...layerGeoJSON];
        }

        newObjects.forEach((newObject) => {
            // Only add objects which are not already in the existing
            if (!updatedObjects.some((existingObject) => existingObject.objectId === newObject.objectId)) {
                updatedObjects.push(newObject);
            }
        });

        copiedGeoJSON[layerId] = updatedObjects;
    });

    return copiedGeoJSON;
}
