/* eslint-disable camelcase */

export const projects = {
    welcome_subtitle: "My live projects",
    welcome_title: "Welcome, {{ name }}",
    view_project: "View project",
    my_projects: "My Projects",
    create_project_form: {
        title: "New project",
        title_edit: "Edit project",
        project_name: "Project Name",
        project_expiry_date: "Project Expiry Date",
        project_apps: "Applications and Features",
        billing_provider: "Billing provider",
        subscription_plan: "Subscription plan",
        subscription_plan_price: "Price",
        billing_invalid: "Please select a billing method",
        plan_invalid: "Please select a plan",
        price_invalid: "Please select a price",
        name_invalid: "Please enter a name.",
        date_invalid: "Please enter a valid date after today.",
        max_users: "Max users",
        max_users_invalid: "Max users must be more than 0",
        unlimited_users: "Unlimited users",
        unlimited: "Unlimited",
    },
    billing_popover: {
        renew_button: "Renew Subscription Now",
        body: "Your subscription has expired. To regain access to your project, renew today.",
    },
    service_unavailable: {
        body: "This project has expired. Please contact the project admin to renew the subscription.",
        support: "For further assistance please",
    },
} as const;
