/* eslint-disable iventis/use-iventis-useHistory */
import { CustomDialog, FormWizardTemplate } from "@iventis/components";
import { Body1, unauthenticatedOverlayStyle } from "@iventis/styles";
import { Content } from "@iventis/translations";
import { useHistory } from "react-router-dom";
import React from "react";

export const LinkExpiredOverlay = () => {
    const history = useHistory();
    return (
        <CustomDialog open slotProps={{ backdrop: { style: unauthenticatedOverlayStyle } }}>
            <FormWizardTemplate
                title={Content.permissions.linkExpiredModal.title}
                currentStage={0}
                stages={[
                    {
                        isValid: true,
                        primaryButtonText: Content.common.buttons.close,
                        primaryButtonCallback: () => {
                            history.push("/login");
                        },
                        secondaryButtons: [],
                    },
                ]}
            >
                <Body1>{Content.permissions.linkExpiredModal.content}</Body1>
            </FormWizardTemplate>
        </CustomDialog>
    );
};
