import { Button } from "@mui/material";
import { SolidButton } from "@iventis/styles/src/components/button";
import React, { FunctionComponent, useMemo } from "react";
import { styled, formButton } from "@iventis/styles";
import { ButtonText } from "@iventis/styles/src/components/texts";

import { dataTestIds } from "@iventis/testing";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingComponent } from "./loading";

export interface FormButtonsProps {
    submitButtonText?: string | React.ReactNode;
    submittingText?: string | React.ReactNode;
    submittedText?: string | React.ReactNode;
    secondaryButtons?: {
        buttonText: string;
        onButtonPressed: () => void;
        buttonDisabled?: boolean | (() => boolean);
        buttonSpinning?: { is: boolean; text: string };
        dataCy?: string;
    }[];
    disableSubmit?: () => boolean;
    handleSubmit?: () => void;
    isSubmitting?: boolean;
    isSubmitted?: boolean;
    style?: { cancelButtonColour?: string; cancelButtonBackGroundColour?: string };
    dataCy?: string;
    className?: string;
}

/**
 * A component with two buttons of confirm and cancel
 * @param {string} submitButtonText - text for submit button
 * @param {() => boolean} disableSubmit - function to disable submit button
 * @param {() => void} handleSubmit - function to be called when the submit button is pressed
 * @param {FormButtonsProps["secondaryButtons"]} secondaryButtons - buttons such as cancel with a required text and callback function
 */
export const FormButtonsComponent: FunctionComponent<FormButtonsProps> = ({
    submitButtonText,
    submittingText,
    submittedText,
    disableSubmit,
    handleSubmit,
    secondaryButtons,
    isSubmitting,
    isSubmitted = false,
    style = {},
    dataCy,
    className,
}) => {
    const disabled = useMemo(() => {
        let disabled = false;

        if (isSubmitting === true) {
            disabled = true;
        } else if (disableSubmit != null) {
            disabled = disableSubmit();
        }

        return disabled;
    }, [isSubmitting, disableSubmit]);
    return (
        <StyledButtonContainer>
            {secondaryButtons?.map(({ buttonText, onButtonPressed, buttonDisabled, buttonSpinning, dataCy }) => {
                let disabled = false;
                switch (typeof buttonDisabled) {
                    case "boolean":
                        disabled = buttonDisabled;
                        break;
                    case "function":
                        disabled = buttonDisabled();
                        break;
                    default:
                        break;
                }
                return (
                    <StyledCancelButton
                        key={buttonText}
                        data-testid={dataTestIds.modals.cancelButton}
                        disabled={disabled}
                        colour={style.cancelButtonColour}
                        $backgroundColour={style.cancelButtonBackGroundColour}
                        onClick={onButtonPressed}
                        data-cy={dataCy}
                    >
                        {buttonSpinning?.is ? (
                            <ButtonText>
                                {buttonSpinning.text}{" "}
                                <span>
                                    <FontAwesomeIcon icon="circle-notch" spin />
                                </span>
                            </ButtonText>
                        ) : (
                            <span>{buttonText}</span>
                        )}
                    </StyledCancelButton>
                );
            })}
            {handleSubmit && (
                <SolidButton
                    type="submit"
                    data-cy={dataCy}
                    data-testid="submit-button"
                    variant="contained"
                    color={isSubmitting ? "inherit" : "primary"}
                    style={{ height: "40px", minWidth: "140px", whiteSpace: "nowrap" }}
                    disabled={disabled}
                    onClick={handleSubmit}
                    className={className}
                >
                    {isSubmitting ? (
                        submittingText ? (
                            <ButtonText>
                                {submittingText}{" "}
                                <span>
                                    <FontAwesomeIcon data-testid="submit-button-loading-spinner" icon="circle-notch" spin />
                                </span>
                            </ButtonText>
                        ) : (
                            <LoadingComponent size={30} />
                        )
                    ) : isSubmitted ? (
                        <ButtonText>{submittedText}</ButtonText>
                    ) : typeof submitButtonText === "string" ? (
                        <ButtonText>{submitButtonText}</ButtonText>
                    ) : (
                        <span>{submitButtonText}</span>
                    )}
                </SolidButton>
            )}
        </StyledButtonContainer>
    );
};

const StyledButtonContainer = styled.div`
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    align-items: flex-end;
`;

const StyledCancelButton = styled(Button)<{ colour: string; $backgroundColour: string }>`
    height: ${formButton.height};
    width: ${formButton.width};
    margin-right: 10px;
    color: ${({ colour, theme }) => colour || theme.typographyColors.core};
    :hover {
        background-color: ${({ $backgroundColour, theme }) => $backgroundColour || theme.shades.four};
    }
`;
