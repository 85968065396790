import { MapGlobalState } from "@iventis/map-engine/src/state/map.state.types";
import { ThunkMiddleware } from "@reduxjs/toolkit";
import { uploadGeometry } from "./map.slice.thunks";
import { UploadGeometryQueue } from "./upload-geometry-queue";

const mapMiddleware = (): ThunkMiddleware<{ mapReducer: MapGlobalState }> => {
    // INitiate the upload geometry queue class
    const uploadGeometryQueue = new UploadGeometryQueue();

    return (store) => (next) => (action) => {
        switch (action.type) {
            case "map/uploadGeometry/pending": {
                action.meta.arg.forEach((update) => {
                    // If the objects we want to update are still being created, add them to the queue
                    if (store.getState().mapReducer.creatingObjects.includes(update.objectId)) {
                        uploadGeometryQueue.queue(update);
                    }
                });
                break;
            }
            case "map/createObject/fulfilled": {
                // Once map objects have been created, check if any of their geometries are in the queue
                const objectsToUpdate = uploadGeometryQueue.getObjectsFromQueueAndClear(action.meta.arg);
                if (objectsToUpdate.length > 0) {
                    // Call the action first because we need the latest "creatingObjects" state, when we call uploadGeometry
                    const response = next(action);
                    // If there are any, upload them
                    store.dispatch(uploadGeometry(objectsToUpdate));
                    return response;
                }
                break;
            }
            case "map/createObject/rejected": {
                // If it is rejected, we still want to clear the objects from the queue
                uploadGeometryQueue.getObjectsFromQueueAndClear(action.meta.arg);
                break;
            }
            default:
                break;
        }
        return next(action);
    };
};

export default mapMiddleware();
