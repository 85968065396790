//  This router is resposible for routing once authenticated.
//  Always displays the navigation bar at the top of the page when in private routes.
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import React, { Suspense, useEffect, useMemo } from "react";
import { ApplicationName } from "@iventis/domain-model/model/applicationName";
import { UserScope } from "@iventis/domain-model/model/userScope";
import { Route, Switch, Redirect, BrowserRouter, useLocation } from "react-router-dom";
import { lazy } from "@loadable/component";
import { LoadingComponent } from "@iventis/components";
import { FunctionalityContext } from "@iventis/utilities";
import { getProjectApplications } from "@iventis/permissions/src/auth.slice";
import { MapSkeletonErrorBoundary } from "@iventis/plans/src/map-skeleton";
import { RootDispatch, RootState } from "@iventis/plans/src/state/root.store";
import { IventisRoute } from "@iventis/permissions/src/iventis-route";
import PageWithAppBar from "./page-with-appbar";
import { DashboardSkeletonErrorBoundary } from "./dashboard-skeleton";

// The project router includes all routes that require a project to have been selected.
// Application checks are in place to ensure the project has application access.
const ProjectRouter: React.FC = () => {
    const dispatch = useDispatch<RootDispatch>();
    const projectApplications = useSelector((s: RootState) => s.auth.projectApplications, shallowEqual);
    const user = useSelector((s: RootState) => s.auth.user, shallowEqual);
    const functionality = useSelector((s: RootState) => s.auth.subscriptionPlan?.functionality, shallowEqual);
    const allowMapApp = projectApplications?.find((app) => app.name === ApplicationName.Maps.toString()) !== undefined || false;
    const allowScheduleApp = projectApplications?.find((app) => app.name === ApplicationName.Schedule.toString()) !== undefined;
    const allowPeopleApp = projectApplications?.find((app) => app.name === ApplicationName.People.toString()) !== undefined;
    const allowProjectSettingsApp = projectApplications?.find((app) => app.name === ApplicationName.Settings.toString()) !== undefined;
    const userScope = useSelector((state: RootState) => state.auth.user.userScope);

    // Calculate which app we should redirect back to
    const redirectUrl = useMemo(() => {
        if (userScope === UserScope.External) return "/login";
        if (allowMapApp) return "/dashboard";
        if (allowScheduleApp) return "/schedule";
        if (allowPeopleApp) return "/people";
        if (allowProjectSettingsApp) return "/project-settings";
        return "";
    }, [allowMapApp, allowScheduleApp, allowPeopleApp, allowProjectSettingsApp]);

    const location = useLocation();

    // Lazy loading
    // Modules without default exports require it setting on .then
    const SchedulePage = lazy(() => import("./page-schedule"));
    const MapRootPage = lazy(async () => (await import("@iventis/plans/src/map-root")).MapRootPage);
    const PeoplePage = lazy(() => import("./page-people"));
    const ProjectSettingsPage = lazy(async () => import("./page-project-settings"));
    const SpatialHomeComponent = lazy(() => import("./page-spatial-home"));
    const SpatialDashboard = lazy(() => import("./dashboard"));

    useEffect(() => {
        const abortController = new AbortController();
        dispatch(getProjectApplications({ signal: abortController.signal }));
        return () => abortController.abort();
    }, []);

    if (!projectApplications || user.projectId === null) {
        return <LoadingComponent />;
    }

    if (projectApplications && projectApplications.length === 0) {
        return <p>This project has no applications. Please contact Iventis support.</p>;
    }

    return (
        <FunctionalityContext.Provider value={functionality}>
            <BrowserRouter>
                {/* Fallback to fake page while pages are lazy loaded */}
                <Suspense
                    fallback={
                        <PageWithAppBar>
                            <LoadingComponent />
                        </PageWithAppBar>
                    }
                >
                    <Switch>
                        {/* If you are adding an additional route that will utilize the upgrade plan modal, ensure you include children in your props otherwise the modal will not render */}
                        {allowMapApp && userScope === UserScope.Application && (
                            <IventisRoute
                                path="/dashboard"
                                component={() => (
                                    <DashboardSkeletonErrorBoundary>
                                        <SpatialDashboard />
                                    </DashboardSkeletonErrorBoundary>
                                )}
                            />
                        )}
                        {allowMapApp && userScope === UserScope.Application && (
                            <IventisRoute exact path="/spatial-planner/:libraryId?" render={({ match }) => <SpatialHomeComponent match={match} />} />
                        )}
                        {allowScheduleApp && <IventisRoute path="/schedule/:scheduleId?" render={() => <SchedulePage />} />}
                        {allowMapApp && (
                            <IventisRoute
                                path="/spatial-planner/map/:mapId"
                                component={({ match }) => (
                                    <MapSkeletonErrorBoundary>
                                        <MapRootPage match={match} />
                                    </MapSkeletonErrorBoundary>
                                )}
                            />
                        )}
                        {allowPeopleApp && userScope === UserScope.Application && <IventisRoute path="/people" component={PeoplePage} />}
                        {allowProjectSettingsApp && userScope === UserScope.Application && <IventisRoute path="/project-settings" render={() => <ProjectSettingsPage />} />}
                        {user.projectId != null && (
                            <Route exact path="/">
                                <Redirect to={{ pathname: redirectUrl, search: location.search }} />
                            </Route>
                        )}
                        {userScope === UserScope.External && (
                            <Route path="/">
                                <Redirect to="/login" />
                            </Route>
                        )}
                        <Route render={() => <div>404</div>} />
                    </Switch>
                </Suspense>
            </BrowserRouter>
        </FunctionalityContext.Provider>
    );
};

export default ProjectRouter;
