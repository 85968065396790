/* eslint-disable no-param-reassign */
import { MapLayer } from "@iventis/domain-model/model/mapLayer";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialState } from "./sidebar.slice.initial-values";
import { FullScreenSelector, MapInterfaceStyle, PopoverPayload, SidebarContent, SidebarState } from "./sidebar.slice.types";

// eslint-disable-next-line import/prefer-default-export
export const sidebar = createSlice({
    initialState,
    name: "Sidebar",
    reducers: {
        setInterfaceStyle(state: SidebarState, action: PayloadAction<MapInterfaceStyle>) {
            state.mapInterfaceStyle = action.payload;
        },
        setPopover(state: SidebarState, action: PayloadAction<PopoverPayload>) {
            state.popoverOpen = true;
            if (action.payload.translateY === undefined) {
                action.payload.translateY = "0px";
            }
            state.popover = action.payload;
        },
        closePopover(state: SidebarState) {
            state.popoverOpen = false;
            state.popover = initialState.popover;
        },
        setFullScreenSelector(state: SidebarState, action: PayloadAction<FullScreenSelector>) {
            state.fullScreenSelector = action.payload;
        },
        showImportLayer(state: SidebarState, action: PayloadAction<boolean>) {
            state.showImportLayer = action.payload;
        },
        updateParentFolderId(state: SidebarState, action: PayloadAction<string>) {
            state.parentFolderId = action.payload;
        },
        setSidebarContent(state: SidebarState, action: PayloadAction<SidebarContent>) {
            if (state.content === action.payload) {
                state.content = SidebarContent.LAYERS;
            } else {
                state.content = action.payload;
            }
        },
        setEditingItem(state: SidebarState, action: PayloadAction<boolean>) {
            state.editingNodeName = action.payload;
        },
        setModalLayer(state: SidebarState, action: PayloadAction<SidebarState["modalLayer"]>) {
            state.modalLayer = action.payload ?? {};
        },
        setAttributeEditingId(state: SidebarState, action: PayloadAction<{ attributeId: string; attributeLayerId: string }>) {
            const { attributeId, attributeLayerId } = action.payload;
            state.attributeId = attributeId;
            state.attributeLayerId = attributeLayerId;
        },
        setPreviousLibraryId(state: SidebarState, action: PayloadAction<string>) {
            state.previousLibraryId = action.payload;
        },
        updateLayerSnapshot(state: SidebarState, action: PayloadAction<MapLayer[]>) {
            state.layersUndertakingPreviewSnapshots = [...state.layersUndertakingPreviewSnapshots, ...action.payload];
        },
        snapshotComplete(state: SidebarState, action: PayloadAction<string>) {
            state.layersUndertakingPreviewSnapshots = state.layersUndertakingPreviewSnapshots.filter((l) => l.id !== action.payload);
        },
    },
});

export default sidebar.reducer;
