/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";
import { Button, Dialog, DialogActions as DialogActionsMUI, DialogContent as DialogContentMUI, DialogTitle as DialogTitleMUI } from "@mui/material";
import { styled, Body1, InteractiveElement, formButton, formPadding } from "@iventis/styles";

import { Theme } from "@emotion/react";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { Content } from "@iventis/translations";
import { ProjectSubscription } from "@iventis/domain-model/model/projectSubscription";
import { CancellationQuestions, ReasonCancelContent } from "./subscription-billing-cancel-reason";

export type ProjectSubscriptionCancellationUpdate = CancellationQuestions & Pick<ProjectSubscription, "cancelAtCommittedUntil">;

export const SubscriptionBillingCancelModalComponent = ({
    open,
    close,
    onSubmit,
}: {
    open: boolean;
    close: () => void;
    onSubmit: (update: ProjectSubscriptionCancellationUpdate) => void;
}) => {
    const [stage, setStage] = useState<"confirmation" | "reason">("confirmation");

    const handleConfirmationOfReasons = (answers?: CancellationQuestions) => {
        onSubmit({ cancelAtCommittedUntil: true, ...answers });
        close();
    };

    const onDialogClose = () => {
        setStage("confirmation");
        close();
    };

    return (
        <Dialog open={open} maxWidth={stage === "confirmation" ? "sm" : "md"} onClose={onDialogClose}>
            <>
                {stage === "confirmation" && open && <ConfirmCancelContent onConfirm={() => setStage("reason")} onCancel={close} />}
                {stage === "reason" && open && <ReasonCancelContent cancel={close} onConfirm={handleConfirmationOfReasons} />}
            </>
        </Dialog>
    );
};

const ConfirmCancelContent = ({ onConfirm, onCancel }: { onConfirm: () => void; onCancel: () => void }) => {
    const translate = useIventisTranslate();
    return (
        <>
            <DialogTitle>{translate(Content.subscriptionplan3.cancellation.confirmationTitle)}</DialogTitle>
            <DialogContent>
                <Body1>{translate(Content.subscriptionplan3.cancellation.confirmationBodyOne)}</Body1>
                <Body1>{translate(Content.subscriptionplan3.cancellation.confirmationBodyTwo)}</Body1>
            </DialogContent>
            <DialogActions justifycontent="flex-end">
                <InteractiveElement className="form-button" onClick={onCancel}>
                    <span>{translate(Content.common.buttons.cancel)}</span>
                </InteractiveElement>
                <Button className="form-button" variant="contained" onClick={onConfirm}>
                    {translate(Content.common.buttons.submit)}
                </Button>
            </DialogActions>
        </>
    );
};

const DialogTitle = styled(DialogTitleMUI)`
    border-bottom: ${({ theme }: { theme: Theme }) => `1px ${theme.shades.three} solid`};
`;

export const DialogContent = styled(DialogContentMUI)`
    // Overide .MuiDialogTitle-root setting padding-top to 0px
    padding: ${formPadding} !important;
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const DialogActions = styled(DialogActionsMUI)<{ justifycontent: string }>`
    display: flex;
    justify-content: ${({ justifycontent }) => justifycontent};
    gap: 10px;
    border-top: ${({ theme }: { theme: Theme }) => `1px ${theme.shades.three} solid`};
    padding: ${formPadding};

    .form-button {
        width: ${formButton.width};
        height: ${formButton.height};

        span {
            font-weight: 600;
        }
    }
`;
