/* eslint-disable @typescript-eslint/no-explicit-any */
export interface FluentValidationError {
    errors: Record<string, string[]>;
}

/** Checks if an error is a fluent validation error */
export function isFluentValidationError(error: any): error is FluentValidationError {
    // If the error is not an object it is not a fluent validation error
    if (error == null || typeof error !== "object") {
        return false;
    }

    // If the object doesn't have a property called errors and it is not an object, it is not a fluent validation error
    if (!("errors" in error && error.errors != null && typeof error.errors === "object")) {
        return false;
    }

    return true;
}

/**
 * Gets the error message out of a fluent validation error object
 * @param fluentValidationError - The fluent validation error object
 * @param fallbackMessage - The message to return if the error message is null
 * @returns - An error message
 */
export function getFluentValidationErrors(fluentValidationError: FluentValidationError, fallbackMessage: string): string | null {
    if (fluentValidationError == null || fluentValidationError.errors == null) {
        return fallbackMessage;
    }

    // Get the first error message from the response
    const fluentValidationErrorMessages = Object.values(fluentValidationError.errors).flatMap((error) => error);

    // Ensure there is an message to return
    if (fluentValidationErrorMessages == null || fluentValidationErrorMessages.length === 0) {
        return fallbackMessage;
    }

    const [possibleErrorMessage] = fluentValidationErrorMessages;
    // Validate if the error message is a string
    if (typeof possibleErrorMessage === "string") {
        return possibleErrorMessage;
    }
    return fallbackMessage;
}
