import { featureCollection, point } from "@turf/helpers";
import GeoJSON from "geojson";
import { createOrReplaceStyleElement } from "@iventis/styles";
import { SVG_NAMESPACE } from "@iventis/utilities";
import { AnySupportedGeometry } from "@iventis/map-types";
import { Listener, MoveEvent } from "../types/internal";
import { numbersEqualWithin, removeLoopClosingCoordinate } from "./geojson-helpers";
import { distanceAngle, getNormalAtVertex, nearest, pointToAngle, unitVector } from "./vectors";

export enum ContinueDrawingDirection {
    AHEAD = "AHEAD",
    BEHIND = "BEHIND",
}

export enum ContinueDrawingEvent {
    DIRECTION_SELECTED = "DIRECTION_SELECTED",
    MOUSE_MOVE_BUTTON = "MOUSE_MOVE_BUTTON",
}

interface ListenerPattern {
    [ContinueDrawingEvent.DIRECTION_SELECTED]: (direction: ContinueDrawingDirection) => void;
    [ContinueDrawingEvent.MOUSE_MOVE_BUTTON]: () => void;
}

export class ContinueDrawing {
    private continueDrawingClickListener: Listener;

    private onMouseMoveListener: Listener;

    private onMapMoveStartedListener: Listener;

    private onButtonMouseMove: Listener;

    private listeners: ListenerPattern = {
        [ContinueDrawingEvent.DIRECTION_SELECTED]: () => undefined,
        [ContinueDrawingEvent.MOUSE_MOVE_BUTTON]: () => undefined,
    };

    private directionHovering: ContinueDrawingDirection;

    private hoverLineElementClassName = "hover-selection-line";

    constructor(
        private coordinate: GeoJSON.Position,
        private feature: GeoJSON.Feature<AnySupportedGeometry>,
        private svgContainer: SVGElement,
        private operations: {
            project: (position: GeoJSON.Position) => [number, number];
            unproject: (screen: [number, number]) => GeoJSON.Position;
            onContinueDrawingClicked: (callback: () => void) => Listener;
            onContinueDrawingButtonMouseMove: (callback: () => void) => Listener;
            onMapMoveStarted: (callback: () => void) => Listener;
            onMouseMove: (callback: (event: MoveEvent) => void) => Listener;
            setGeometry: (position: GeoJSON.FeatureCollection<GeoJSON.Point>, rotation: number) => void;
            getMapRotation: () => number;
        }
    ) {
        this.continueDrawingClickListener = operations.onContinueDrawingClicked(this.continueDrawingClicked.bind(this));
        this.onMouseMoveListener = operations.onMouseMove(this.onMouseMove.bind(this));
        this.onMapMoveStartedListener = operations.onMapMoveStarted(this.onMapMoveStarted.bind(this));
        this.onButtonMouseMove = operations.onContinueDrawingButtonMouseMove(this.buttonMouseMove.bind(this));
        ContinueDrawing.insertHoverLineKeyframeStyle();
    }

    private static insertHoverLineKeyframeStyle() {
        createOrReplaceStyleElement(
            "style-hover-line-selection-continue-drawing",
            `
                @keyframes animate-dashed-line {
                    from {
                        stroke-dashoffset: 100;
                    }
                    to {
                        stroke-dashoffset: 0;
                    }
                }`
        );
    }

    private buttonMouseMove() {
        this.listeners[ContinueDrawingEvent.MOUSE_MOVE_BUTTON]();
    }

    private continueDrawingClicked() {
        this.listeners[ContinueDrawingEvent.DIRECTION_SELECTED](this.directionHovering);
    }

    private setHoverSelectionLinePosition(screenSourceXY: [number, number], screenDestinationXY: [number, number]) {
        let hoverSelectionLineElement = this.svgContainer.getElementsByClassName(this.hoverLineElementClassName).item(0) as SVGLineElement;
        if (!hoverSelectionLineElement) {
            hoverSelectionLineElement = document.createElementNS(SVG_NAMESPACE, "line");
            hoverSelectionLineElement.style.strokeDasharray = "6, 4";
            hoverSelectionLineElement.style.strokeWidth = "2";
            hoverSelectionLineElement.style.stroke = "white";
            hoverSelectionLineElement.style.animation = "animate-dashed-line 3s linear infinite";
            hoverSelectionLineElement.classList.add(this.hoverLineElementClassName);
            this.svgContainer.appendChild(hoverSelectionLineElement);
        }

        hoverSelectionLineElement.setAttributeNS(null, "x1", screenSourceXY[0].toString());
        hoverSelectionLineElement.setAttributeNS(null, "y1", screenSourceXY[1].toString());
        hoverSelectionLineElement.setAttributeNS(null, "x2", screenDestinationXY[0].toString());
        hoverSelectionLineElement.setAttributeNS(null, "y2", screenDestinationXY[1].toString());
    }

    private onMapMoveStarted() {
        this.removeHoverLineElement();
    }

    /**
     * On mouse move, we want to show the continue drawing button.
     * This button rotates about the provided coordinate, with its angle pointing directly away from that coordinate
     * The button will not deviate further than a fixed angle from the normal line
     * With polygons, the normal line is pointing away from the center of the shape, angularly central between the two closest neighbours
     * With line strings, the normal line is pointing directly away from the next neighbour. It only appears at the ends of line strings.
     */
    public onMouseMove(event: MoveEvent) {
        const [nodeX, nodeY] = this.operations.project(this.coordinate);
        const [mouseX, mouseY] = this.operations.project([event.lng, event.lat]);
        const mouseUnitVectorFromNode = unitVector([nodeX, nodeY], [mouseX, mouseY]) as [number, number];

        const geometryType = this.feature.geometry.type;

        let geometryStripped: GeoJSON.Position[];

        if (geometryType === "LineString") {
            geometryStripped = this.feature.geometry.coordinates;
        }

        if (geometryType === "Polygon") {
            geometryStripped = removeLoopClosingCoordinate(this.feature.geometry).coordinates;
        }

        // From here on, we are working in screen coordinates rather than lng/ lats
        const geometryScreen = geometryStripped.map((g) => this.operations.project(g));

        if (geometryType === "LineString") {
            const nodeIndex = geometryScreen.findIndex((point) => numbersEqualWithin(point, [nodeX, nodeY], 0.1));
            const neighbouringNode = nodeIndex === geometryScreen.length - 1 ? geometryScreen[nodeIndex - 1] : geometryScreen[1];

            // Find the unit vector in the exact opposite direction to the neighbouring node. This becomes our 'central' point for pivoting the button in LineStrings
            const normalNeighbouringNode = unitVector(neighbouringNode, [nodeX, nodeY]);
            this.showButton(normalNeighbouringNode, mouseUnitVectorFromNode, [nodeX, nodeY]);
            return;
        }

        // Get the normal from the inner direction of the shape and neighbouring nodes (along with their unit vectors)
        const { normal: normalUnitVector, neighbours } = getNormalAtVertex([nodeX, nodeY], geometryScreen, true, (a, b) => numbersEqualWithin(a, b, 0.1));

        const closestNeighbourUnitVector = nearest<[number, number]>([neighbours[0].unitVector, neighbours[1].unitVector], (a) => a, mouseUnitVectorFromNode);

        // Save the direction we are hovering so this state is available if a click happens
        if (closestNeighbourUnitVector === neighbours[0].unitVector) {
            this.directionHovering = ContinueDrawingDirection.AHEAD;
        } else {
            this.directionHovering = ContinueDrawingDirection.BEHIND;
        }

        this.showButton(normalUnitVector, mouseUnitVectorFromNode, [nodeX, nodeY], [neighbours[0].coordinate, neighbours[1].coordinate]);
    }

    /**
     * Show the button according to the given parameters. Neighbours are not provided from line strings, since the continue drawing preview line is ommitted
     */
    private showButton(normalUnitVector: [number, number], mouseUnitVectorFromNode: [number, number], coordinate: [number, number], neighbours?: [number, number][]) {
        const [nodeX, nodeY] = coordinate;
        // The maximum angle from the normal within which the button will follow the cursor
        const maxAngleFromNormalLine = 110;
        // How far the button is from the center of the node
        const buttonDistance = 17;

        const angleMouseFromNode = pointToAngle(mouseUnitVectorFromNode);
        const angleOfNormal = pointToAngle(normalUnitVector);

        // What is the difference in the angle between the mouse position and the provided normal line
        const angularDistanceOfMouseFromNormalLine = distanceAngle(angleMouseFromNode, angleOfNormal);

        let buttonAngle: number;
        let buttonPosition: [number, number];

        // If the cursor is within the clickable button area (does not deviate further from the normal line than our maximum angle)
        if (angularDistanceOfMouseFromNormalLine < maxAngleFromNormalLine) {
            buttonAngle = angleMouseFromNode;
            buttonPosition = [nodeX + mouseUnitVectorFromNode[0] * buttonDistance, nodeY + mouseUnitVectorFromNode[1] * buttonDistance];
            if (neighbours && this.directionHovering === ContinueDrawingDirection.AHEAD) {
                this.setHoverSelectionLinePosition([nodeX, nodeY], neighbours[0]);
            }
            if (neighbours && this.directionHovering === ContinueDrawingDirection.BEHIND) {
                this.setHoverSelectionLinePosition([nodeX, nodeY], neighbours[1]);
            }
        } else {
            // We are outside the clickable button area. Show the button without the hover selection line, at the position of the normal
            this.removeHoverLineElement();
            buttonAngle = angleOfNormal;
            buttonPosition = [nodeX + normalUnitVector[0] * buttonDistance, nodeY + normalUnitVector[1] * buttonDistance];
        }

        // Icon needs rotating 90 degrees since we're measuring angles relative to the positive x-axis. The icon however is pointing up the Y axis
        buttonAngle += 90;

        // Convert back to long/ lats, and return the data to the caller
        const displacedMapCoords = this.operations.unproject(buttonPosition as [number, number]);
        const fc = featureCollection([point(displacedMapCoords)]);
        this.operations.setGeometry(fc, buttonAngle);
    }

    public on<E extends ContinueDrawingEvent>(event: E, callback: ListenerPattern[E]) {
        this.listeners[event] = callback;
        return this;
    }

    private removeHoverLineElement() {
        const hoverLineElement = this.svgContainer.getElementsByClassName(this.hoverLineElementClassName).item(0);
        if (hoverLineElement) {
            this.svgContainer.removeChild(hoverLineElement);
        }
    }

    public destroy() {
        this.continueDrawingClickListener.remove();
        this.removeHoverLineElement();
        this.operations.setGeometry(featureCollection([]), 0);
        this.onMouseMoveListener.remove();
        this.onMapMoveStartedListener.remove();
        this.onButtonMouseMove.remove();
    }
}
