export const customMediaQueryMin = (minWidth: number) => `@media (min-width: ${minWidth}px)`;

export const customMediaQueryMax = (maxWidth: number) => `@media (max-width: ${maxWidth}px)`;

export const screenSizeBreakpoints = {
    extraExtraLarge: 1600,
    extraLarge: 1200,
    large: 992,
    medium: 768,
    small: 600,
    extraSmall: 599,
    extraExtraSmall: 406,
};

const { extraExtraLarge, extraLarge, large, medium, small, extraSmall, extraExtraSmall } = screenSizeBreakpoints;

export const media = {
    extraExtraLarge: customMediaQueryMin(extraExtraLarge), // 4k screens
    extraLarge: customMediaQueryMin(extraLarge), // large desktops and large laptops
    large: customMediaQueryMin(large), // laptops and desktops
    medium: customMediaQueryMin(medium), // tablets and phablets
    small: customMediaQueryMin(small), // large phones and small tablets
    extraSmall: customMediaQueryMax(extraSmall), // phones
    extraExtraSmall: customMediaQueryMax(extraExtraSmall), // small phones
    appAlongBase: customMediaQueryMax(medium - 1), // Breakpoint at which the app menu should show along the bottom of the screen
};
