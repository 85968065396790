import { DataField } from "@iventis/domain-model/model/dataField";
import { mappingApi } from "@iventis/api/src/api";

/** Sends a post request for a layer data field */
export const postLayerDataField = async (request: { layerId: string; dataField: DataField }) => {
    const response = await mappingApi.post<DataField>(`/layers/${request.layerId}/data-fields`, request.dataField);
    return response?.data;
};

/** Sends a patch request for a layer data field */
export const patchLayerDataField = async (request: { layerId: string; dataFieldPatch: Partial<DataField>; datafieldId: string }) => {
    const response = await mappingApi.patch<DataField>(`/layers/${request.layerId}/data-fields/${request.datafieldId}`, request.dataFieldPatch);
    return response?.data;
};

/** Sends a delete request of a layer data field */
export const deleteLayerDataField = async (request: { layerId: string; dataFieldId: string }) => {
    const response = await mappingApi.delete<string>(`/layers/${request.layerId}/data-fields/${request.dataFieldId}`);
    return response?.data;
};
