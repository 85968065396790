import { InputLabel } from "@mui/material";
import { labelMargin, fontSizes } from "../atomic-rules";
import styled from "../transient-styled";

export const StyledAutocompleteWrapper = styled.div`
    width: 100%;
    .MuiFormHelperText-contained {
        margin: 3px 0 0 3px;
    }
`;
export const StyledColumnFlex = styled.div<{ width?: string | number }>`
    display: flex;
    flex-direction: column;
    width: ${({ width }) => width || undefined};
`;
export const StyledRenderOption = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
`;
export const StyledFieldLabel = styled(InputLabel)`
    margin-bottom: ${labelMargin};
    font-size: ${fontSizes.small};
    font-weight: 410;
    color: ${({ theme }) => theme.primaryColors.subduedMono};
`;

export const StyledFieldLabelLight = styled(StyledFieldLabel)`
    color: ${({ theme }) => theme.typographyColors.blank};
`;
