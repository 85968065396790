import { css } from "@emotion/react";
import { inputMarginBottom, fontSizes, inputHeight } from "./atomic-rules";

// Set to 0 top and bottom because we instead use "margin: auto" and the parent handles the height
const inputPadding = "0 0.5rem";

export const muiInputFormsCSS = css`
    .MuiInputBase-input {
        height: ${inputHeight};
        box-sizing: border-box;
    }

    .MuiInputBase-input.MuiSelect-select {
        height: ${inputHeight};
    }

    .MuiOutlinedInput-input {
        font-size: ${fontSizes.small};
        &:focus,
        :invalid {
            outline: none;
            color: inherit;
        }
        padding: ${inputPadding};
        margin: auto;
    }

    .Mui-Datepicker .MuiInputBase-root {
        padding: 0 12px;
    }

    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        padding: ${inputPadding};
    }
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
        padding: 0;
    }
    .MuiFormHelperText-contained {
        margin: 3px 0 0 3px;
    }

    .input-margin-bottom {
        margin-bottom: ${inputMarginBottom};
    }
`;
