import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fontSizes, Header3, iconButtonSize, InteractiveElement, styled } from "@iventis/styles";
import { Theme } from "@emotion/react";
import { Content } from "@iventis/translations";
import { useIventisTranslate } from "@iventis/translations/use-iventis-translate";
import { Breakpoint } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React, { FunctionComponent, ReactNode } from "react";

import { CustomDialog } from "./custom-dialog";

export const UserAlertModal: FunctionComponent<{ maxWidth?: false | Breakpoint; open: boolean; children: ReactNode; close: () => void; TransitionProps?: TransitionProps }> = ({
    open,
    children,
    close,
    maxWidth,
    TransitionProps,
}) => {
    const translate = useIventisTranslate();
    return (
        <CustomDialog TransitionProps={TransitionProps} maxWidth={maxWidth} open={open} onClose={close}>
            <Container>
                <>
                    <StyledCloseButton type="button" onClick={close}>
                        <FontAwesomeIcon icon={["far", "xmark"]} />
                        <span className="hidden">{translate(Content.common.buttons.close)}</span>
                    </StyledCloseButton>
                    {children}
                </>
            </Container>
        </CustomDialog>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 40px;
    align-items: center;
    position: relative;
`;

const StyledCloseButton = styled(InteractiveElement)`
    position: absolute;
    top: 0;
    right: 0;
    width: ${iconButtonSize};
    height: ${iconButtonSize};
`;

export const UserAlertHeader = styled.h1`
    color: ${({ theme }: { theme: Theme }) => theme.typographyColors.primary};
    text-align: center;
    font-size: ${fontSizes.xxxLarge};
    font-style: normal;
    font-weight: 700;
    line-height: 44px; /* 105.882% */
`;

export const UserAlertTitle: FunctionComponent<{ title: ReactNode }> = ({ title }) => <Header3>{title}</Header3>;
