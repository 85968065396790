import { css } from "@emotion/react";

export const ellipsisAnimation = css`
    &:after {
        overflow: hidden;
        display: inline-block;
        vertical-align: bottom;
        animation: ellipsis steps(4, end) 2s infinite;
        content: "...";
        width: 0px;
        font-size: 20px;
        letter-spacing: 0;
        margin-right: 25px;
    }

    @keyframes ellipsis {
        to {
            width: 25px;
            margin-right: 0px;
        }
    }
`;
