import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { connection, ConnectionState } from "./connection.slice";

const useInternet = () => {
    const dispatch = useDispatch();
    const { isOnline } = useSelector((state: { connectionReducer: ConnectionState }) => state.connectionReducer.internet);

    // Registers event listeners to dispatch online/offline statuses to redux
    useEffect(() => {
        const handleOnline = () => {
            dispatch(connection.actions.updateInternetConnection(true));
        };

        const handleOffline = () => {
            dispatch(connection.actions.updateInternetConnection(false));
        };

        window.addEventListener("online", handleOnline);
        window.addEventListener("offline", handleOffline);

        return function cleanup() {
            window.removeEventListener("online", handleOnline);
            window.removeEventListener("offline", handleOffline);
        };
    }, [dispatch]);

    return {
        isOnline,
    };
};

export default useInternet;
