/* eslint-disable camelcase */
export const timeRules = {
    activity_start_time: "Activity start time",
    type: {
        type_of_start_time: "Type of start time",
        relative: "Relative to session/scheduled item",
        relative_description: "Example: 2 minutes after session start, or 5 minutes after last scheduled item",
        fixed: "Fixed",
        fixed_description: "Example: 09:00",
        relative_time: "Relative time",
        fixed_time: "Fixed time",
    },
    start_activity: "Start activity",
    preposition: {
        before: "before",
        after: "after",
    },
    preposition_lables: {
        before: "before {{ type }} start",
        after: "after {{ type }} start",
    },
    relative_to: {
        start_time: "start time of",
        end_time: "end time of",
        zero_time: "zero time of",
    },
    relationship: {
        every: "every",
        specific: "specific",
        first: "first",
        last: "last",
    },
    related_type: {
        Session: "session",
        "Scheduled item": "scheduled item",
    },
    repeat_activity: {
        repeat_activity: "Repeat activity",
        repeat_on_all: "Repeat on days (all)",
        none_selected: "None selected",
        all_days: "All days",
    },
    info: {
        can_not_delete: "Can't delete this schedule item as it is relied upon by another schedule item",
    },
} as const;
