import { DataFieldListItem } from "@iventis/domain-model/model/dataFieldListItem";
import { DataFieldType } from "@iventis/domain-model/model/dataFieldType";
import { DataFieldValue } from "@iventis/domain-model/model/dataFieldValue";
import { EventBlock } from "@iventis/domain-model/model/eventBlock";
import { ScheduleVersion } from "@iventis/domain-model/model/scheduleVersion";
import { VenueLocation } from "@iventis/domain-model/model/venueLocation";
import { DataFieldListItemRelationship } from "@iventis/domain-model/model/dataFieldListItemRelationship";
import { DataField } from "@iventis/domain-model/model/dataField";
import { StyleType } from "@iventis/domain-model/model/styleType";
import { SystemDataFieldName } from "@iventis/domain-model/model/systemDataFieldName";
import { DataFieldListItemCreateRequestDto } from "@iventis/domain-model/model/dataFieldListItemCreateRequestDto";
import { bothNull, hasObjectArrayChanged, createMapFromArray } from "@iventis/utilities";
import { FilterFormat } from "@iventis/types";
import { compareAsc } from "date-fns";
import { AxiosInstance } from "axios";
import qs from "qs";
import {
    parseRepeatedTimeRangeDataFieldValueToString,
    parseRepeatedTimeRangeDataFieldValueToNumber,
    isMapObjectRepeatedTimeRange,
    isDataFieldValueRepeatedTimeRange,
} from "./repeated-time-ranges-datafield-helpers";

export const getDataFieldListItems = async (api: AxiosInstance, dataFieldId: string, filter: FilterFormat[]) => {
    const listItems = await api.get<DataFieldListItem[]>(`/data-field-list-items/${dataFieldId}`, {
        params: { filter: JSON.stringify(filter) },
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "repeat" }),
    });
    return listItems.data;
};

export const getDataFieldValue = (item: { dataFieldValues: DataFieldValue[] }, name: string): DataFieldValue | undefined => {
    const values = item?.dataFieldValues;
    const value = values?.find((v) => v.dataField?.name === name);
    return value;
};

export const getDataFieldValueObject = (item: EventBlock | ScheduleVersion | VenueLocation, name: string) => getDataFieldValue(item, name)?.value;

export const getDataFieldValueAsTextFromEntity = (item: { dataFieldValues: DataFieldValue[] }, name: string) => {
    const value = getDataFieldValue(item, name);
    return getDataFieldValueAsText(value);
};

export const getDataFieldValueAsText = (dataFieldValue?: DataFieldValue) => {
    if (!dataFieldValue) {
        return null;
    }
    if (dataFieldValue.dataField.type === DataFieldType.List) {
        return dataFieldValue.value?.name;
    }
    return dataFieldValue.value;
};

export const getDataFieldValueFromMetaData = (item: EventBlock | ScheduleVersion | VenueLocation, name: string, metaDataProperty: string) => {
    const value = getDataFieldValue(item, name);
    if (!value || value.dataField.type !== DataFieldType.List) {
        return null;
    }
    return value.value?.metaData[metaDataProperty];
};

/** Returns true if data field values are different */
export const hasDataFieldValueChanged = <T extends DataFieldType>(oldValue, newValue, type: T) => {
    if (bothNull(oldValue, newValue)) {
        return false;
    }
    switch (type) {
        case DataFieldType.Number:
        case DataFieldType.Text:
        case DataFieldType.Tickbox:
            return oldValue !== newValue;
        case DataFieldType.Date:
            return compareAsc(oldValue, newValue) !== 0;
        case DataFieldType.List:
            return !areListItemsEqual(oldValue, newValue);
        case DataFieldType.MultiSelect:
            return hasObjectArrayChanged(oldValue, newValue, areListItemsEqual);
        default:
            return true;
    }
};

export const areListItemsEqual = (prev: DataFieldListItem, next: DataFieldListItem) => typeof prev === "object" && typeof next === "object" && prev?.id === next?.id;

export const getCustomerIdentifierFromMeta = (dataFieldValue: DataFieldValue) => {
    const codePropertyName = dataFieldValue.dataField.customerIdentifierName;
    return dataFieldValue.value.metaData?.[codePropertyName];
};

export const sortDatafieldsByOrder = (dataFields: DataField[] = []): DataField[] => [...dataFields].sort((a, b) => a.order - b.order);

/** Adjust the order of the data fields, given one of them is deleted. (Uses dataField.order property) */
export const sortDataFieldsOnDelete = (dataFields: DataField[], idOfDeleted: string): DataField[] => {
    // Order the layer data fields so the index matches the correct order
    const ordered = sortDatafieldsByOrder(dataFields);
    // Remove the deleted data field and set the resulting order on the others
    const newDataFields = ordered.filter(({ id }) => id !== idOfDeleted).map((df, index) => ({ ...df, order: index + 1 }));
    return newDataFields;
};

/** Sorts datafields in order of: SystemDataFields, ProjectDataFields and other DataFields */
export const sortDataFields = (projectDataFields: DataField[], dataFields: DataField[]) => {
    if (projectDataFields == null || dataFields == null) {
        return dataFields;
    }
    // Create project datafield map
    const projectDataFieldsMap = createMapFromArray(projectDataFields, "id");
    return [...dataFields].sort((a, b) => getDataFieldOrderValue(projectDataFieldsMap.get(a.id)) - getDataFieldOrderValue(projectDataFieldsMap.get(b.id)));
};

const getDataFieldOrderValue = (projectDataField?: DataField) => {
    switch (projectDataField?.systemDataFieldName) {
        case SystemDataFieldName.MapObjectName:
            return 0;
        case SystemDataFieldName.MapObjectArea:
        case SystemDataFieldName.MapObjectLength:
        case SystemDataFieldName.RouteTime:
        case SystemDataFieldName.Coordinates:
            return 1;
        default: {
            return projectDataField ? 2 : 3;
        }
    }
};

/** Compared two data fields at the shallow level (doesn't include list items) */
export const hasDataFieldChangedShallow = (leftDataField: DataField, rightDataField: DataField) => {
    const isDifferent = leftDataField.name !== rightDataField.name || leftDataField.type !== rightDataField.type || leftDataField.id !== rightDataField.id;
    return isDifferent;
};

export const isDataFieldList = (dataField: DataField) => [DataFieldType.List, DataFieldType.MultiSelect].includes(dataField.type);

export const listItemToCreateDto = (listItem: DataFieldListItem): DataFieldListItemCreateRequestDto => ({
    name: listItem.name,
    // Properties also have a DTO
    propertyValues:
        listItem.propertyValues?.map((p) => ({
            propertyId: p.propertyId,
            text: p.text,
            number: p.number,
        })) ?? [],
    relationshipValues:
        listItem.relationshipValues?.map((r) => ({
            relationshipId: r.relationshipId,
            relatedToDataFieldListItemId: r.relatedToDataFieldListItemId,
            dataFieldListItemId: r.dataFieldListItemId,
        })) ?? [],
    uniqueId: listItem.uniqueId,
    id: listItem.id,
    order: listItem.order,
});

/** Sorts data fields by their relationships (i.e. if a data field is related to another data field, it will appear after that data field) */
export const sortDataFieldsByRelationship = (dataFields: DataField[]) => {
    // Order the dataFields by their dependency relationships
    const dependencyGraph = createDependencyGraph(dataFields);
    const orderedDataFieldIds = topologicalSort(dependencyGraph);

    // Create an ordered array of DataField objects
    return orderedDataFieldIds.map((id) => dataFields.find((dataField) => dataField.id === id)).reverse();
};

interface DataFieldDependencyGraph {
    [dataFieldId: string]: string[]; // Map dataFieldId to its dependencies
}

// Function to perform topological sorting
function topologicalSort(graph: DataFieldDependencyGraph): string[] {
    const result: string[] = [];
    const visited: { [key: string]: boolean } = {};

    function visit(dataFieldId: string) {
        if (visited[dataFieldId]) return;
        visited[dataFieldId] = true;

        const dependencies = graph[dataFieldId] || [];
        dependencies.forEach(visit);

        result.unshift(dataFieldId);
    }

    Object.keys(graph).forEach(visit);

    return result;
}

// Create a dependency graph from the DataField objects
function createDependencyGraph(dataFields: DataField[]): DataFieldDependencyGraph {
    const graph: DataFieldDependencyGraph = {};

    dataFields.forEach((dataField) => {
        const dependencies: string[] = [];
        dataField.listItemRelationships?.forEach((relationship) => {
            dependencies.push(relationship.relatedToDataFieldId);
        });

        graph[dataField.id] = dependencies;
    });

    return graph;
}

/** Orders data fields by the order property */
export const orderUniqueDataFields = (dataFields: DataField[]) => {
    const sortedDatafields = dataFields.sort((a, b) => a.order - b.order);
    // Remove all duplicate datafields
    const uniqueDataFields = sortedDatafields.reduce<DataField[]>(
        (uniqueDataFields, dataField) => (uniqueDataFields.some(({ id }) => id === dataField.id) ? uniqueDataFields : [...uniqueDataFields, dataField]),
        []
    );
    return uniqueDataFields;
};

/**
 * Extracts all the relevant data based on the given datafields relationships
 * @param allDataFields - an array of all the datafields to be checked
 * @param dataField - the datafield that we want to get the revelant data for
 * @param getValuesForDataField - a function that extracts all the values for the given datafield
 * @returns an object containing all the relevant datafields and their values where the key is the datafield id.
 */
export const extractRelevantDataFieldRelationships = (
    allDataFields: DataField[],
    dataField: DataField,
    getValuesForDataField: (dataFieldId: string) => unknown[]
): Record<string, unknown[]> => {
    // Make sure the datafield has relationships before iterating
    if (dataField.listItemRelationships != null) {
        return (
            allDataFields
                // Store the values associated with the datafield in a key value pair
                .reduce<Record<string, unknown[]>>((cum, df) => {
                    // Filter all datafields by ones that are related to the current datafield
                    if (dataField.listItemRelationships.some((rel) => rel.relatedToDataFieldId === df.id)) {
                        return { ...cum, [df.id]: getValuesForDataField(df.id) };
                    }
                    return cum;
                }, {})
        );
    }
    return {};
};

/**
 * Filters all the datafield list items provided if the relevant data is correct for the datafield to show based on its relationships
 * @param listItems - all the list items we are filtering
 * @param dataField - the datafield that the list items are part of
 * @param relationshipValues - the relevant data based on the datafield relationships (generated by extractRelevantDataFieldRelationships)
 * @returns an array of datafield list items that have been filtered
 */
export const filterDataFieldListOptionsByRelationships = (
    listItems: DataFieldListItem[],
    dataField: DataField,
    relationshipValues: Record<string, unknown[]>
): DataFieldListItem[] =>
    listItems.filter((option) => {
        // If the datafield has no relationships then we can allow all options
        if (!(dataField.listItemRelationships?.length > 0)) {
            return true;
        }

        // Get all the relationships that matter to this particular list item option (This should be every relationship as they must all be set in order to be valid, we can filter by option.relationshipValues if we decide we want to support items with no relation).
        // Keep them in a record with its relationship id as the key for easier lookup
        const relationships = dataField.listItemRelationships.reduce<Record<string, DataFieldListItemRelationship>>((cum, rel) => ({ ...cum, [rel.id]: rel }), {});

        return (
            // Ensure that if we got this point (our datafield has relationships) that relationships are set on the datafield - we should not show it at all if the item doesnt have the same amount of relationships set up as the datafield.
            // The below line can be removed if we decide we want to support items with no relation
            option.relationshipValues.length === dataField.listItemRelationships.length &&
            // Make sure that every relationship for this option has the value that this option needs in order to show it
            option.relationshipValues.every((rel) => {
                const key = relationships[rel.relationshipId]?.relatedToDataFieldId;
                const currentValues = relationshipValues[key];
                // Make sure that every value that is selected is the one that we want
                // If the relationship value is not included in the key, then we can assume the relationship can be ignored because it does not exist on the resource (eg. layer).
                // This can happen when project data fields relate to another project data field but only one is added to the resource.
                return !Object.keys(relationshipValues).includes(key) || currentValues?.every((currentVal) => currentVal === rel.relatedToDataFieldListItemId);
            })
        );
    });

export const extractDataFieldValue = (value: DataFieldListItem | string, property: keyof DataFieldListItem = "id") => {
    if (value == null) {
        return null;
    }

    if (typeof value !== "object") {
        return value;
    }

    // When the value is a list item, it may have the object with additional data.
    // In this situation we have to extract thhe list item ID (which is the value).
    return value?.[property];
};

export const dataFieldToEmptyValueMapping = (dataFields: DataField[]) =>
    dataFields.reduce((cumulative, dataField: DataField) => ({ ...cumulative, [dataField.id]: getDataFieldDefaultValue(dataField) }), {});

const getDataFieldDefaultValue = (dataField: DataField) => {
    switch (dataField.type) {
        case DataFieldType.List:
            return null;
        case DataFieldType.Tickbox:
            return true;
        default:
            return "";
    }
};

export const dataFieldIdToValueMapping = (dataFieldValues: DataFieldValue[]) =>
    dataFieldValues.reduce((cumulative, dataFieldValue: DataFieldValue) => ({ ...cumulative, [dataFieldValue.dataField.id]: dataFieldValue.value }), {});

export const listItemToDataFieldValue = (listItem: DataFieldListItem, datafield: DataField): DataFieldValue => ({
    dataField: datafield,
    value: listItem,
});

/** Recursively resets all data field values for the gievn data field's children */
export const resetChildDataFieldValuesInHierarchy = (dataFieldId: string, values: { [key: string]: unknown }, allDataFields: DataField[]) => {
    const childDataField = allDataFields.find((df) => df.parentDataFieldId === dataFieldId);
    if (childDataField) {
        // eslint-disable-next-line no-param-reassign
        values[childDataField.id] = null;
        resetChildDataFieldValuesInHierarchy(childDataField.id, values, allDataFields);
    }
};

export const haveDataFieldValuesChanged = (oldDataFieldValues: DataFieldValue[], newDataFieldValues: DataFieldValue[]) =>
    hasObjectArrayChanged(
        // In case the order of the dfv's array changes but values themselves stay the same, we must check inside each value object if a dfv has changed
        oldDataFieldValues?.map((v) => ({ ...v.value, id: v.dataField.id, extractedValue: extractDataFieldValue(v.value) })),
        newDataFieldValues?.map((v) => ({ ...v.value, id: v.dataField.id, extractedValue: extractDataFieldValue(v.value) })),
        (val1, val2) => JSON.stringify(val1) !== JSON.stringify(val2)
    );

/** Converts all the valid ISO date strings in the data field values object and converts them to UTC */
export const convertDateDatafieldValuesToUTC = (dataFieldValues: { [id: string]: unknown }, layerDatafields: DataField[]) => {
    const convertedValues = {};
    Object.entries(dataFieldValues).forEach(([id, value]) => {
        const layerDataField = layerDatafields.find((layerDF) => layerDF.id === id);
        // Ensure that the date is being sent up in UTC
        if (value != null && layerDataField?.type === DataFieldType.RepeatedTimeRanges && isMapObjectRepeatedTimeRange(value)) {
            convertedValues[id] = parseRepeatedTimeRangeDataFieldValueToString(value);
        } else {
            convertedValues[id] = value;
        }
    });
    return convertedValues;
};

/**
 * Converts all date time ranges to a number format, dates are converted to epoch seconds and times are converted to hhmm format
 */
export const convertDateDatafieldValuesToLocal = (dataFieldValues: { [id: string]: unknown }, layerDatafields: DataField[]) => {
    const convertedValues = {};
    Object.entries(dataFieldValues).forEach(([id, value]) => {
        const layerDataField = layerDatafields.find((layerDF) => layerDF.id === id);
        if (value != null && layerDataField?.type === DataFieldType.RepeatedTimeRanges && isDataFieldValueRepeatedTimeRange(value)) {
            // When an datafield value is a repeated time range
            convertedValues[id] = parseRepeatedTimeRangeDataFieldValueToNumber(value);
        } else {
            convertedValues[id] = value;
        }
    });
    return convertedValues;
};

/** Given a style type, return the datafield types which can't be used for that layer */
export function getFilteredDataFieldTypesForLayer(styleType: StyleType) {
    switch (styleType) {
        case StyleType.Line:
        case StyleType.LineModel:
        case StyleType.Area:
            return [DataFieldType.What3Words];
        case StyleType.Point:
        case StyleType.Icon:
        case StyleType.Model:
            return [];
        default:
            throw new Error(`${styleType} is not handled`);
    }
}
