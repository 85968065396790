/* eslint-disable no-console */
import { StyleType } from "@iventis/domain-model/model/styleType";
import { MetricName } from "@iventis/domain-model/model/metricName";
import { MetricEvent } from "../metric-types";
import { incrementMonitoringCount } from "../set-monitoring-metric";

export function layerCreationMonitoringMetric(layerType: StyleType, event: Partial<MetricEvent> = {}) {
    switch (layerType) {
        case StyleType.Area:
            incrementMonitoringCount({ ...event, name: MetricName.AREA_LAYER_CREATED });
            break;
        case StyleType.Line:
            incrementMonitoringCount({ ...event, name: MetricName.LINE_LAYER_CREATED });
            break;
        case StyleType.Point:
            incrementMonitoringCount({ ...event, name: MetricName.POINT_LAYER_CREATED });
            break;
        case StyleType.Icon:
            incrementMonitoringCount({ ...event, name: MetricName.ICON_LAYER_CREATED });
            break;
        case StyleType.Model:
            incrementMonitoringCount({ ...event, name: MetricName.MODEL_LAYER_CREATED });
            break;
        case StyleType.LineModel:
            incrementMonitoringCount({ ...event, name: MetricName.LINE_MODEL_LAYER_CREATED });
            break;
        default:
            console.warn(`Layer type ${layerType} is not supported for monitoring`);
    }
}
